import React, { useEffect } from 'react';
import './Template2.css';
// import { formatMonthYear } from '../lib/utils.ts';

const Template2 = ({ formData }) => {
    useEffect(() => {
      const updateHeight = () => {
        const container = document.querySelector(".template-container");
        // const containerWidth = container.offsetWidth;
        // const containerHeight = containerWidth * 1.41421; // A4 paper width-to-height ratio
        // container.style.minHeight = `${containerHeight}px`;

      };

      const onClick =() =>{
        const container = document.querySelector(".template-container");
        container.scrollIntoView(".template-container", {behavior: 'smooth', block: 'start'});
  
      }
  

      // Call updateHeight initially and add event listener for window resize
      updateHeight();
      window.addEventListener("resize", updateHeight);
      
      // Cleanup function to remove event listener
      return () => {
        window.removeEventListener("resize", updateHeight);
      };
    }, []);

  return (
    <div className="template-container">
      <header className="mb-6">
        {formData?.Details && formData?.Details.map((detail, index) => (
          <div key={index} className="header-details">
            {detail.profileImage && (
              <img src={detail.profileImage} alt="Profile" className="w-24 h-24 mx-auto rounded-full" />
            )}
            <h1 className="text-3xl font-bold">{detail.fullName}</h1>
            <p className="text-lg">{detail.jobTitle}</p>
            <p className="text-sm">{detail.address}</p>
            <p className="text-sm">{detail.phone}</p>
            <p className="text-sm">{detail.email}</p>
            <p className="text-sm">{detail.website}</p>
            <p className="text-sm">{detail.linkedin}</p>
            <p className="text-sm">{detail.github}</p>
          </div>
        ))}
      </header>

      <section className="mb-6">
        {formData.Details && formData.Details.map((detail, index) => (
          <div key={index} className="text-sm summary" dangerouslySetInnerHTML={{ __html: detail.about }} />
        ))}
      </section>

      {formData.workFlag && (
        <section className="mb-6">
          <h2 className="mb-2 text-2xl font-bold">Professional Experience</h2>
          {formData?.experience && formData?.experience.map((exp, index) => (
            <div key={index} className="mb-4 job">
              <h3 className="text-xl font-semibold">{exp.jobRole}</h3>
              <p className="text-sm italic">{exp.companyName} {(exp.companyName && exp.companyLocation) ? ",":""} {exp.companyLocation}</p>
              <p className="text-sm italic">{
              exp?.fromDate  || ""
            } {(exp?.fromDate && exp?.toDate) ? "-":""} {
              exp?.toDate  || ""
            }</p>
              <p dangerouslySetInnerHTML={{ __html: exp.jobDescription }} />
            </div>
          ))}
        </section>
      )}

      {formData.eduFlag && (
        <section className="mb-6">
          <h2 className="mb-2 text-2xl font-bold">Education</h2>
          {formData?.education && formData?.education.map((edu, index) => (
            <div key={index} className="education-item">
              <p className="text-sm">{edu.degree}</p>
              <p className="text-sm italic">{edu?.fromDate || ""
            } {(edu?.fromDate && edu?.toDate) ? "-":""} {edu?.toDate  || ""
          }</p>
              <p className="text-sm">{edu.college} {(edu?.college && edu?.location) ? ",":""}{edu.location}</p>
            </div>
          ))}
        </section>
      )}

      {formData.skillFlag && (
        <section className="mb-6">
          <h2 className="mb-2 text-2xl font-bold">Key Skills</h2>
          <ul className="ml-5 text-sm list-disc">
            { formData?.skills && formData?.skills.map((skill, index) => (
              <li key={index}>{skill?.title} - {skill?.skillList}</li>
            ))}
          </ul>
        </section>
      )}

      {formData.proFlag && (
        <section className="mb-6">
          <h2 className="mb-2 text-2xl font-bold">Certifications</h2>
          { formData?.certifications && formData?.certifications.map((cert, index) => (
            <div key={index} className="certification-item">
              <p className="text-sm">{cert.title}</p>
              <p className="text-sm italic">{cert.date}</p>
              <p className="text-sm">{cert.organization}</p>
              {cert.link && (
                <a className="text-sm text-blue-600" href={cert.link}>
                  {cert.link}
                </a>
              )}
            </div>
          ))}
        </section>
      )}

        {formData.proFlag && (
        <section className="mb-4 certifications">
          <h3 className="mb-2 text-2xl font-bold">Projects Developed or Handled</h3>
          {formData.projects.map((prodit, index) => (
            <div key={index} className="mb-2">
              <p>{prodit.title} {(prodit?.title && prodit?.stack) ? "-":""}  {prodit.stack}</p>
              <div dangerouslySetInnerHTML={{ __html: prodit.desc }} />
            </div>
          ))}
        </section>
      )}

      {formData.achFlag && (
        <section className="mb-4 achievements">
          <h3 className="mb-2 text-2xl font-bold">Achievements & Awards</h3>
          {formData.achivawards.map((ach, index) => (
            <div key={index} className="mb-2">
              <p className="text-base text-gray-600">{ach.achivawards}</p>
            </div>
          ))}
        </section>
      )}

      {formData.addFlag && (
        <section className="mb-6">
          <h3 className="mb-2 text-2xl font-bold">Additional Information</h3>
          {formData.additionalInfo.map((addinfo, index) => (
            <div key={index} className="additional-info-item">
              <p className="text-sm">{addinfo.addInfo}</p>
            </div>
          ))}
        </section>
      )}

      {/* <p className="watermark">HIREBEEN PREVIEW</p> */}
    </div>
  );
};

export default Template2;
