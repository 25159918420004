import React, { useEffect, useState } from "react";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
import { Link, useNavigate } from "react-router-dom";
import {Helmet} from "react-helmet";
import dote1 from "../image/icons/Ellipse 163.svg";
import dote2 from "../image/icons/Ellipse 164.svg";
import dote3 from "../image/icons/Ellipse 165.svg";
import rarrow from "../image/icons/rarrow.svg";
import bagor from "../image/icons/bagor.svg";
import dowarrow from "../image/icons/dowarrow.svg";
import ts1 from "../image/icons/ts1.svg";
import ts2 from "../image/icons/ts2.svg";
import ts3 from "../image/icons/ts3.svg";
import dotes from "../image/icons/dotes.svg";
import contactUsImg from "../image/contact-us/contact-us.png";
import emailIcon from "../image/home/subscribe/email.png";
import plus from "../image/home/subscribe/plus.png";
import step1 from "../image/home/steps/step1.png";
import step2 from "../image/home/steps/step2.png";
import step3 from "../image/home/steps/step3.png";
import rightArrow1 from "../image/home/icons/right-arrow.png";
import rightDottedArrow from "../image/home/steps/right-arrow.png";
import userStore from "../store/user.zustand.js";

const AboutUs = () => {
  const [linker] = useState("");
  const navigate = useNavigate();
  const {isAuthenticated} = userStore();
  // const

  const [startBuilding, setStartBuilding] = useState({
    email: "",
    error: false,
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  return (
    <div className="flex flex-col min-h-screen bg-white">
    <>
      <Helmet>
          <meta charSet="utf-8" />
          <title>About Us - ResumeBeen | AI-Powered Resume Builder for Your Dream Career</title>
          <link rel="canonical" href="https://resumebeen.com/about-us" />
          <meta property="og:site_name" content="ResumeBeen"></meta>
          <meta property="og:url" content="https://resumebeen.com/about-us/"></meta>
          <meta property="og:locale" content="en_EN"></meta>

          <meta name="twitter:card" content="summary_large_image"></meta>
          <meta name="twitter:site" content="@resumebeen"></meta>
          <meta name="twitter:creator" content="@resumebeen"></meta>

          <meta property="og:type" content="website"></meta>

          <meta name="title" content="About Us - ResumeBeen | AI-Powered Resume Builder for Your Dream Career"></meta>
          <meta property="og:title" content="About Us - ResumeBeen | AI-Powered Resume Builder for Your Dream Career"></meta>


          <meta name="description" content="ResumeBeen is your go-to AI-powered resume builder, designed to create tailored, professional resumes and cover letters in minutes. With features like Matching Mode Analysis, seamless resume imports, and personalized templates, ResumeBeen helps you stand out and land your dream job faster."></meta>
          <meta property="og:description" content="ResumeBeen is your go-to AI-powered resume builder, designed to create tailored, professional resumes and cover letters in minutes. With features like Matching Mode Analysis, seamless resume imports, and personalized templates, ResumeBeen helps you stand out and land your dream job faster."></meta>

          <meta name="keywords" content="ResumeBeen, about ResumeBeen, AI resume builder, professional resume tools, resume customization, Matching Mode Analysis, personalized resumes, career growth tools, cover letter generator, resume imports, job application tools, resume templates, career success, resume building platform"></meta>
          <meta property="og:keywords" content="ResumeBeen, about ResumeBeen, AI resume builder, professional resume tools, resume customization, Matching Mode Analysis, personalized resumes, career growth tools, cover letter generator, resume imports, job application tools, resume templates, career success, resume building platform"></meta>

      </Helmet>      
    </>
      <Navbar />

      <div className="m-10">
        <section className="flex flex-col items-center justify-center w-full p-8 m-2 space-y-6">
          <h2 className="font-archivo font-normal text-[48px] md:text-[64px] px:4 md:px-12 leading-[55px]  md:leading-[69.63px] text-center text-[#1F6463]">
            Resume<span className="text-[#EE702E]">Been</span>:{" "}
            <span className="font-light font-archivo">
              Trusted advisor, advocate, and creator of personalized, standout
              resumes for you.
            </span>
          </h2>
          <div className="flex flex-col space-y-2">
            <p className="font-roboto font-normal text-base md:text-xl lead leading-6   md:leading-5 text-center text-[#535353]">
              Let AI build your resume and choose from our curated templates.
              Start now! Choose from our handpicked
            </p>
            <p className="font-roboto font-normal text-base md:text-xl leading-6  md:leading-5 text-center text-[#535353]">
              selection of templates to give your resume a professional edge.
              Success is just two steps away!
            </p>
          </div>

          <button
            className="flex w-[155px] md:w-[173px] h-[57px] rounded-[5px] p-[20px] gap-[20px] items-center justify-center py-4 px-2 md:px-4 text-base font-archivo font-normal leading-4 text-white bg-[#1F6463] cursor-pointer transition-colors duration-500 ease-in-out hover:bg-black"
            onClick={() => navigate("/dashboard")}
          >
            Create My Resume
          </button>
        </section>

        <section className="flex flex-col-reverse items-center justify-center w-full mt-4 space-x-0 md:space-x-0 md:items-center md:justify-between md:flex-row">
          
          <div className="flex flex-col items-center justify-center p-0 space-y-8 md:text-start md:w-1/2 md:p-0 md:items-center md:justify-between">
            <h3 className="font-archivo text-center md:text-left font-medium text-4xl leading-[39.17px] text-[#1F6463]">
              ResumeBeen helps you build resumes that are all about you
            </h3>

            <p className="font-archivo text-center md:text-left font-normal text-base md:text-xl leading-5 text-[#535353]">
              Resume writing is an art and science that centers on you. We
              combine expert insights and user feedback into our templates to
              help you confidently showcase your achievements and personality
              with the world’s best resume builder.
            </p>

            <button
              className="flex w-[100px] md:w-[114px] h-[57px] rounded-[5px] p-[20px] gap-[20px] items-center justify-center py-4 px-2 md:px-4 text-base font-archivo font-normal leading-4 text-white bg-[#1F6463] cursor-pointer transition-colors duration-500 ease-in-out hover:bg-black"
              onClick={() => navigate("/contact-us")}
            >
              Contact us
            </button>
          </div>

          <img src={contactUsImg} alt="contactu-us" className="w-auto h-auto" />
        </section>

        <div className="flex flex-col items-center mt-12 space-y-6 sm:px-24 sm:my-24 max-md:px-6 max-md:py-12 max-md:w-full">
          <h2 className="text-5xl font-light leading-[52px] text-center text-[#1C1C1C] font-archivo">
            Craft your success in{" "}
            <span className="text-[#EE702E] border-b-2 border-b-[#EE702E]">
              {" "}
              3 simple{" "}
            </span>{" "}
            steps
          </h2>
          <p className="text-base text-[#535353] md:text-xl font-normal leading-7 text-center font-archivo">
            Let AI build your resume and choose from our curated templates.{" "}
            <span className="text-[#1F6463]">Start now!</span> Choose from our
            handpicked <br />
            selection of templates to give your resume a professional edge.
            Success is just two steps away!
          </p>

          <div className="flex flex-col md:flex-row md:space-x-6">
          <div className="flex flex-col  relative items-center justify-center p-8 space-y-2 text-center min-w-[200px]">
            <img src={step1} alt="step1" className="w-16 h-16" />
            <img
              src={rightDottedArrow}
              className="absolute transform -translate-y-1/2 hidden md:block  w-28 left-[8rem] lg:left-[15.5rem] lg:w-56"
              style={{ top: "27%" }}
              alt="*------------>"
            />

            <h4 className="text-sm font-semibold text-center uppercase font-roboto">
              STEP-1
            </h4>
            <p className="font-roboto font-semibold leading-4 text-sm text-center text-[#535353]">
              Start:{" "}
              <span className="text-sm font-normal text-center">
                Ready to create a standout resume in minutes? Our AI Resume
                Builder is here to guide you through the process effortlessly.
              </span>
            </p>
          </div>
          <div className="flex flex-col  relative items-center justify-center p-8 space-y-2 text-center min-w-[200px]">
            <img src={step2} alt="step2" className="w-16 h-16" />
            <img
              src={rightDottedArrow}
              className="absolute transform -translate-y-1/2 hidden md:block left-[10rem] lg:left-[15.5rem] lg:w-56 w-28"
              style={{ top: "27%" }}
              alt="*------------>"
            />
            <h4 className="text-sm font-semibold text-center uppercase font-roboto">
              STEP-2
            </h4>
            <p className="font-roboto font-semibold leading-4 text-sm text-center text-[#535353]">
              Build:{" "}
              <span className="text-sm font-normal text-center">
                our AI-powered tool simplifies the resume-building process,
                ensuring you present your best self to potential employers.{" "}
              </span>
            </p>
          </div>
          <div className="flex flex-col relative items-center justify-center p-8 space-y-2 text-center min-w-[200px]">
            <img src={step3} alt="step3" className="w-16 h-16" />
            <h4 className="text-sm font-semibold text-center uppercase font-roboto">
              STEP-3
            </h4>
            <p className="font-roboto font-semibold leading-4 text-sm text-center text-[#535353]">
              Download:{" "}
              <span className="text-sm font-normal text-center">
                Download your AI-generated resume in your preferred format—PDF,
                Word, or text—and apply to your dream jobs with confidence{" "}
              </span>
            </p>
          </div>
        </div>


          <div
            className="flex rounded-[5px] p-[20px] gap-[15px] w-[230.43px] h-[57px] bg-[#1F6463] cursor-pointer transition-colors duration-500 ease-in-out hover:bg-black"
            onClick={() => navigate("/dashboard")}
          >
            <button className="text-base  font-medium font-archivo leading-[17.41px] text-[#ffffff] ">
              Create My Resume
            </button>
            <img
              src={rightArrow1}
              alt="right-arrow"
              className="w-[28.43px] h-[16px] text-[#ffffff]"
            />
          </div>
        </div>
        {/* Register Now */}
        <div className="flex flex-col lg:flex-row justify-between  p-6 border-[#929292] border-1 space-y-6 lg:space-y-0 lg:m-16 lg:p-10">
          <div className="flex flex-row items-start justify-center space-x-4 space-y-0 md:space-y-4 lg:flex-row lg:space-x-4 lg:space-y-0">
            <img
              src={plus}
              alt="plus"
              className=" text-[#EE702E] w-6 h-6 lg:w-9 lg:h-9"
            />

            <div className="w-full">
              <h2 className="font-archivo  flex items-center flex-wrap font-normal text-xl lg:text-3xl leading-6 lg:leading-8 text-[#1C1C1C]">
                Try Resumebeen's professional resume builder now.
              </h2>
              <h2 className="text-[#EE702E] font-archivo flex items-center flex-wrap font-medium text-xl lg:text-3xl leading-6 lg:leading-8">
                Start for free!
              </h2>
            </div>
          </div>

          <div className="flex items-center justify-center w-full">
            <div className="flex w-full sm:w-[440px] justify-between items-center bg-white border p-[20px] gap-[12px] sm:gap-[20px] shadow-[0px_2px_2px_0px_rgba(0,0,0,0.25)] rounded border-[#929292]">
              <img
                src={emailIcon}
                alt="email"
                className="w-[25.33px] h-[20px] text-center"
              />
              <div>
                <div>
                  <input
                    type="email"
                    placeholder="Your Email"
                    className={`font-archivo p-1 font-medium w-full sm:w-[229px] leading-4 text-[#929292] text-xs sm:text-sm border-1 rounded-md border-transparent focus:border-[#929292] focus:outline-none ${
                      startBuilding.email ? "border-[#929292]" : ""
                    }`}
                    value={startBuilding.email}
                    onChange={(e) =>
                      setStartBuilding((prev) => ({
                        error: false,
                        email: e.target.value,
                      }))
                    }
                  />
                  <p className="text-red-600">
                    {!emailRegex.test(startBuilding.email) &&
                    startBuilding.email
                      ? "Invalid email"
                      : ""}
                  </p>
                </div>
              </div>
              <button
                className="bg-[#EE702E] w-full sm:w-[110px] rounded-md text-white font-archivo sm:font-normal font-light text-xs sm:text-sm leading-4 p-2"
                onClick={() => {
                  if (emailRegex.test(startBuilding.email)) {
                    if (isAuthenticated) {
                      navigate(`/dashboard`);
                      return;
                    }
                    navigate(`/signup?email=${startBuilding.email}`);
                  } else {
                    setStartBuilding((prev) => ({ ...prev, error: true }));
                  }
                }}
              >
                Start Building
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
