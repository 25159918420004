import React, { useEffect, useState } from "react";
import { useSearchParams, useNavigate } from "react-router-dom";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
import { API_URL } from "../apiConfig";
import axios from "axios";
import CustomButton from "../Reusable/CustomButton.jsx";

const VerifyEmail = () => {
  const [searchParams] = useSearchParams();
  const [isVerified, setIsVerified] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");
  const token = searchParams.get("token");
  const [isVerifying, setIsVerifying] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const verifyEmail = async () => {
      if (isVerifying) return;
      setIsVerifying(true);

      try {
        setLoading(true);
        const response = await axios.put(
          `${API_URL}/api/v1/users/verify-email`,
          { token }
        );
        setLoading(false);

        if (response.data?.success) {
          setIsVerified(true);
          setMessage(response?.data?.message);
        } else {
          setIsVerified(false);
          setMessage(response?.data?.message);
        }
      } catch (error) {
        setIsVerified(false);
        setMessage("An error occurred during verification. Please try again.");
        setLoading(false);
      } finally {
        setLoading(false);
      }
    };

    verifyEmail();
  }, []);

  const handleLogin = () => {
    navigate("/login");
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Navbar />
      <main className="flex items-center justify-center h-auto my-10">
        <div className="flex flex-col justify-center items-center h-full w-full lg:min-w-[900px] px-4">
          <h1 className="my-4 text-4xl font-bold text-center">
            Email Verification
          </h1>
          <div className="my-2 text-4xl font-bold text-center">
            {loading ? "Verifying your email..." : ""}
          </div>
          <div className="flex flex-col items-center justify-center w-full mt-6 md:flex-row">
            {!loading && isVerified !== null && (
              <div className="flex flex-col items-center justify-center">
                <h3 className="text-2xl font-light text-center">{message}</h3>
                <div className="flex w-full">
                  <CustomButton
                    onClick={handleLogin}
                    className="py-1 mt-4"
                  >
                    Go to Login
                  </CustomButton>
                </div>
              </div>
            )}
          </div>
        </div>
      </main>
      <Footer />
    </div>
  );
};

export default VerifyEmail;
