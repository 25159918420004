import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from "../image/icons/delsolid.svg";
import { Tooltip } from 'react-tooltip'
import AlertComponent from './AlertComponent';
import { formatMonthYear } from '../lib/utils.ts';
const DynamicEducationInputs = ({ inputs, setInputs, setDataSaved }) => {

    // State to manage accordion behavior
    const [accordionIndex, setAccordionIndex] = useState(0);
    const [showAlert, setShowAlert] = useState(false);
    const [indexToRemove, setIndexToRemove] = useState(null);
  
  // Function to handle change of any input field within the education entries
  const handleChange = (index, event) => {
    const updatedInputs = [...inputs];
    if (event.target.name === 'currEdu') {
      // If the checkbox is checked, set the value of toDate to 'Present'
      updatedInputs[index]['toDate'] = event.target.checked ? 'Present' : '';
    } else if (event.target.name === 'fromDate' || event.target.name === "toDate"){
      updatedInputs[index][event.target.name] = event.target.value
    }
    else {
      updatedInputs[index][event.target.name] = event.target.value;
    }
    setInputs(updatedInputs);
    setDataSaved(false);
  };

  // Function to add a new education entry
  const handleAddEducation = () => {
    const newInput = { degree: '', college: '', location: '', fromDate: '', toDate: '' };
    setInputs([...inputs, newInput]);
    setAccordionIndex(inputs.length);
  };


  // Function to remove an education entry
  const handleRemoveEducation = (index) => {
    setIndexToRemove(index);
    setShowAlert(true);
  };

  const confirmRemoveEducation = () => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(indexToRemove, 1);
    setInputs(updatedInputs);
    setShowAlert(false);
    // If the removed accordion is the one that's open, update the accordionIndex
    if (indexToRemove === accordionIndex) {
      setAccordionIndex(updatedInputs.length > 0 ? 0 : null);
    }
  };

  const cancelRemoveEducation = () => {
    setShowAlert(false);
  }; 


  // Function to toggle accordion
  const toggleAccordion = (index) => {
    if (accordionIndex === index && inputs.length > 1) {
      setAccordionIndex(null);
    } else {
      setAccordionIndex(index);
    }
  };

  return (
    <div>
      {inputs.map((input, index) => (
        <div key={index} className="px-3 py-2 m-3 bg-white border-gray-300 rounded-lg cursor-pointer accordion-card border-1">
          <div onClick={() => toggleAccordion(index)} className='flex items-center justify-between text-lg text-gray-600'>
          {input.degree === "" ? (`Education ${index + 1}`) : (input.degree.length > 20 ? `${input.degree.slice(0, 20)}...` : input.degree)}
            {inputs.length > 1 && (
              <button onClick={() => handleRemoveEducation(index)} className="text-white ">
                <DeleteIcon  className='w-5 h-5 hover:fill-red-600' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-tooltip-place="right-end" />
              </button> 
            )}
            <Tooltip id="my-tooltip" className='p-2 text-xs font-light' />
          </div>
          <AlertComponent
            show={showAlert}
            onConfirm={confirmRemoveEducation}
            onCancel={cancelRemoveEducation} 
          />
          {accordionIndex === index && (
            <div className='text-sm text-black '>
              <div className='flex flex-wrap'>
                <div className='w-full mb-2 md:w-1/2 md:pr-2'>
                  {/* <label htmlFor="">Degree</label><br /> */}
                  <input
                    type="text"
                    name="degree"
                    value={input.degree}
                    onChange={(event) => handleChange(index, event)}
                    placeholder="Degree Name"
                    className="w-full p-2 border rounded-xl"
                    onClick={(event) => event.stopPropagation()}
                  />
                </div>
                <div className='w-full mb-2 md:w-1/2 md:pl-2' >
                  {/* <label htmlFor="">Institution name</label><br /> */}
                  <input
                    type="text"
                    name="college"
                    value={input.college}
                    onChange={(event) => handleChange(index, event)}
                    placeholder="College/Institute Name"
                    className="w-full p-2 border rounded-xl"
                    onClick={(event) => event.stopPropagation()}
                  />
                </div>
              </div>
              <div className='flex flex-wrap'>
                <div className="w-full mb-2 md:w-1/2 md:pr-2">
                  {/* <label htmlFor="">Location</label><br /> */}
                  <input
                    type="text"
                    name="location"
                    value={input.location}
                    onChange={(event) => handleChange(index, event)}
                    placeholder='location'
                    className='w-full p-2 border rounded-xl '
                    onClick={(event) => event.stopPropagation()}
                  />
                </div>
                <div className="w-full mb-2 md:w-1/2 md:pl-2">
                  <div className="flex flex-wrap justify-between">
                  {/* <label htmlFor="">Duration</label> */}
                  </div>
                  <div className='flex flex-col gap-1'>
                  <input
                    type="month"
                    name="fromDate"
                    value={
                      input.fromDate  || ""
                    }                    
                    onChange={(event) => handleChange(index, event)}
                    placeholder="From Year"
                    className="w-full p-2 border rounded-xl"
                    onClick={(event) => event.stopPropagation()}
                  />
                  {input.toDate === 'Present' ? (
                    <input
                      type="text"
                      name="toDate"
                      value="Present"
                      readOnly
                      className="w-full p-2 bg-gray-200 border rounded-xl"
                      onClick={(event) => event.stopPropagation()}
                    />
                  ) : (
                    <input
                      type="month"
                      name="toDate"
                      value={input?.toDate}
                      onChange={(event) => handleChange(index, event)}
                      placeholder="To Year"
                      className="w-full p-2 border rounded-xl"
                      onClick={(event) => event.stopPropagation()}
                    />
                  )}
                  
                  </div>
                  <label htmlFor="currEdu" className="ml-1"><input type="checkbox" name="currEdu" id="currEdu"  className="mr-2 " checked={input.toDate === 'Present'} onChange={(event) => handleChange(index, event)} />I currently study here</label><br />
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <div onClick={handleAddEducation} className="flex justify-center p-2 m-3 text-lg font-semibold text-white delay-700 bg-orange-500 cursor-pointer rounded-xl">
        + Add Education
      </div>
    </div>
  );
};

export default DynamicEducationInputs;
