import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import RoopTable from "../../common/customTableR/table";
import { API_URL } from "../../../apiConfig";

const Newsletter = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();

  const [counter, setCounter] = useState(0);

  const [newsletter, setNewsletter] = useState([]);

  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  const [page, setPage] = useState(1)


  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/newsletter/newsletters?page=${page}`,
          {
            withCredentials: true,
          }
        );
        let count =1;
        // Map the fetched payment details properly to the users' state
        setNewsletter(
          response?.data?.data?.map((el) => {
            return {
              count: count++,
              id: el?._id,
              email:el?.email,
              subscribed:`${el?.subscribed}`,
              updatedAt: formatDate(el?.updatedAt) ?? null,
              createdAt: formatDate(el?.createdAt) ?? null,
            };
          })
        );
        // setNewsletter([{}]);
      } catch (error) {
        console.error("Error while fetching token details", error);
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [counter, setLoading,page]);

  const headings = [
      { dataKey: "count", title: "Count" },
    { dataKey: "email", title: "Email" },
    { dataKey: "subscribed", title: "Subscribed" },
    { dataKey: "updatedAt", title: "Updated At" },
    { dataKey: "createdAt", title: "Created At" },
  ];
  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={isLoading}
        data={newsletter}
        itemsPerPage={5}
        headings={headings}
        hovered
        csvExport
        setPage={setPage}
      />

    </div>
  );
};

export default Newsletter;
