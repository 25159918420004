import React from "react";
import { useNavigate } from "react-router-dom"; // Use React Router for navigation
import useAuthStore from "../../store/auth";
import AdminLayout from "../navigation/layout";
import useGlobalStore from "../../store/global";
import { Toast } from './../common/toast/toast';

const AdminHome = () => {
  const { isAuthenticated } = useAuthStore();
  const { toastData } = useGlobalStore();
  const navigate = useNavigate();

  // useEffect(() => {
  //   const fetchAdminDetails = async () => {
  //     // const token = localStorage.getItem("adminAccessToken"); // Assuming you're using localStorage for tokens

  //     if (!isAuthenticated) {
  //       console.error("No authentication token found");
  //       navigate("/login"); // Redirect to login if no token
  //       return;
  //     }

  //     try {
  //       const response = await axios.get(`${API_URL}/api/v1/admin/me`, {
  //         withCredentials: true,
  //       });

  //       console.log({response:response?.data})

  //       if (response?.data?.success) {
  //         // Set admin data in store
  //         setAdmin(response.data.data);

  //         console.log("called 2.")
  //         // Set authenticated state
  //         setAuthenticated(true);
  //       } else {
  //         setAuthenticated(false);
  //         navigate("/admin/login");
  //       }
  //     } catch (error) {
  //       console.error("Failed to fetch admin details:", error);
  //       navigate("/login"); // Redirect to login on error
  //     }
  //   };

  //   fetchAdminDetails();
  // }, [navigate]);

  if (!isAuthenticated) {
    console.error("No authentication token found");
    navigate("/admin/login"); // Redirect to login if no token
    return;
  }

  return (
    <div className="bg-white">
      <AdminLayout />
      {toastData && <Toast message={toastData.message} type={toastData.type} />}
    </div>
  );
};

export default AdminHome;
