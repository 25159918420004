import React from "react";
import CardDataStats from "../../common/cardDataStat/cardDataStat";

const Dashboard = () => {
  return (
    <div className="w-full px-4 mx-auto">
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2 md:gap-6 xl:grid-cols-4 2xl:gap-7.5">
        <CardDataStats title="Total views" total="$3.456K" rate="0.43%" levelUp levelDown>
          <svg
            className="fill-white"
            width="22"
            height="16"
            viewBox="0 0 22 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11 15.1156C4.19376 15.1156 0.825012 8.61876 0.687512 8.34376C0.584387 8.13751 0.584387 7.86251 0.687512 7.65626C0.825012 7.38126 4.19376 0.918762 11 0.918762C17.8063 0.918762 21.175 7.38126 21.3125 7.65626C21.4156 7.86251 21.4156 8.13751 21.3125 8.34376C21.175 8.61876 17.8063 15.1156 11 15.1156ZM2.26876 8.00001C3.02501 9.27189 5.98126 13.5688 11 13.5688C16.0188 13.5688 18.975 9.27189 19.7313 8.00001C18.975 6.72814 16.0188 2.43126 11 2.43126C5.98126 2.43126 3.02501 6.72814 2.26876 8.00001Z"
              fill=""
            />
            <path
              d="M11 10.9219C9.38438 10.9219 8.07812 9.61562 8.07812 8C8.07812 6.38438 9.38438 5.07812 11 5.07812C12.6156 5.07812 13.9219 6.38438 13.9219 8C13.9219 9.61562 12.6156 10.9219 11 10.9219ZM11 6.625C10.2437 6.625 9.625 7.24375 9.625 8C9.625 8.75625 10.2437 9.375 11 9.375C11.7563 9.375 12.375 8.75625 12.375 8C12.375 7.24375 11.7563 6.625 11 6.625Z"
              fill=""
            />
          </svg>
        </CardDataStats>
        <CardDataStats title="Total Profit" total="$45,2K" rate="4.35%" levelUp levelDown>
          <svg
            className="fill-white "
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7531 16.4312C10.3781 16.4312 9.27808 17.5312 9.27808 18.9062C9.27808 20.2812 10.3781 21.3812 11.7531 21.3812C13.1281 21.3812 14.2281 20.2812 14.2281 18.9062C14.2281 17.5656 13.0937 16.4312 11.7531 16.4312ZM11.7531 19.8687C11.2375 19.8687 10.825 19.4562 10.825 18.9406C10.825 18.425 11.2375 18.0125 11.7531 18.0125C12.2687 18.0125 12.6812 18.425 12.6812 18.9406C12.6812 19.4219 12.2343 19.8687 11.7531 19.8687Z"
              fill=""
            />
            <path
              d="M5.22183 16.4312C3.84683 16.4312 2.74683 17.5312 2.74683 18.9062C2.74683 20.2812 3.84683 21.3812 5.22183 21.3812C6.59683 21.3812 7.69683 20.2812 7.69683 18.9062C7.69683 17.5656 6.56245 16.4312 5.22183 16.4312ZM5.22183 19.8687C4.7062 19.8687 4.2937 19.4562 4.2937 18.9406C4.2937 18.425 4.7062 18.0125 5.22183 18.0125C5.73745 18.0125 6.14995 18.425 6.14995 18.9406C6.14995 19.4219 5.73745 19.8687 5.22183 19.8687Z"
              fill=""
            />
            <path
              d="M19.0062 0.618744H17.15C16.325 0.618744 15.6031 1.23749 15.5 2.06249L14.95 6.01562H1.37185C1.0281 6.01562 0.684353 6.18749 0.443728 6.46249C0.237478 6.73749 0.134353 7.11562 0.237478 7.45937C0.237478 7.49374 0.237478 7.49374 0.237478 7.52812L2.36873 13.9562C2.50623 14.4375 2.9531 14.7812 3.46873 14.7812H12.9562C14.2281 14.7812 15.3281 13.8187 15.5 12.5469L16.9437 2.26874C16.9437 2.19999 17.0125 2.16562 17.0812 2.16562H18.9375C19.35 2.16562 19.7281 1.82187 19.7281 1.37499C19.7281 0.928119 19.4187 0.618744 19.0062 0.618744ZM14.0219 12.3062C13.9531 12.8219 13.5062 13.2 12.9906 13.2H3.7781L1.92185 7.56249H14.7094L14.0219 12.3062Z"
              fill=""
            />
          </svg>
        </CardDataStats>
        <CardDataStats
          title="Total Product"
          total="2.450"
          rate="-4.25%"
          levelDown
          levelUp
        >
          <svg
            className="fill-white "
            width="22"
            height="22"
            viewBox="0 0 22 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M21.1063 18.0469L19.0657 3.23125C18.9 2.0375 17.8125 1.1875 16.6063 1.1875H6.60941C5.40316 1.1875 4.31566 2.0375 4.15 3.23125L2.10941 18.0469C2.00628 18.9063 2.28128 19.7313 2.86566 20.3844C3.45 21.0375 4.24066 21.4 5.09691 21.4H18.1188C18.975 21.4 19.7657 21.0375 20.35 20.3844C20.9344 19.7313 21.2094 18.9063 21.1063 18.0469ZM3.95628 19.2344C3.63753 18.8469 3.52503 18.3469 3.58128 17.8313L5.30003 3.01563C5.38441 2.33126 5.90941 1.85626 6.60941 1.85626H17.4375C18.1219 1.85626 18.6469 2.33126 18.7313 3.01563L20.45 17.8313C20.5063 18.3469 20.3938 18.8469 20.075 19.2344C19.7563 19.6219 19.275 19.8563 18.7344 19.8563H3.84691C3.30628 19.8563 2.82441 19.6219 3.95628 19.2344Z"
              fill=""
            />
            <path
              d="M11 5.91875C10.4625 5.91875 10.0344 6.34687 10.0344 6.88437V15.1156C10.0344 15.6531 10.4625 16.0812 11 16.0812C11.5375 16.0812 11.9656 15.6531 11.9656 15.1156V6.88437C11.9656 6.34687 11.5375 5.91875 11 5.91875Z"
              fill=""
            />
            <path
              d="M7.10629 6.92501C7.00941 6.92501 6.89691 6.94376 6.80003 6.98126C6.33441 7.17188 6.10941 7.72814 6.30003 8.19376L8.24378 13.0813C8.42191 13.5313 8.89378 13.7625 9.33753 13.5813C9.78128 13.4031 10.0125 12.9313 9.83441 12.4875L7.89066 7.60001C7.75941 7.27814 7.44378 7.07188 7.10629 7.07188V6.92501Z"
              fill=""
            />
            <path
              d="M14.8843 6.98126C14.7874 6.94376 14.675 6.92501 14.5781 6.92501C14.2406 6.92501 13.925 7.13126 13.7937 7.45313L11.85 12.3406C11.6719 12.7906 11.9031 13.2625 12.3469 13.4406C12.7906 13.6188 13.2625 13.3875 13.4406 12.9438L15.3843 8.05626C15.575 7.59063 15.3501 7.03438 14.8843 6.84376V6.98126Z"
              fill=""
            />
          </svg>
        </CardDataStats>
        <CardDataStats title="Total Sales" total="20.8K" rate="2.30%" levelDown levelUp>
          <svg
            className="fill-white "
            width="20"
            height="22"
            viewBox="0 0 20 22"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.3344 7.15938C18.2375 7.15938 18.1406 7.14063 18.0438 7.12188L15.1688 6.56875L12.4 0.856253C12.2344 0.509378 11.8562 0.309378 11.4625 0.403128L6.13122 1.52813C5.76872 1.60313 5.54372 1.95313 5.61872 2.31563C5.69372 2.67813 6.04372 2.90313 6.40622 2.82813L11.0219 1.83125L13.5406 6.9375C13.675 7.19688 13.9281 7.35625 14.2125 7.39688L17.2438 7.97813C17.8688 8.09688 18.5094 8.025 19.0875 7.78438C19.2094 7.72813 19.2625 7.5875 19.2375 7.46563C19.2125 7.34375 19.1125 7.24688 18.9938 7.22188C18.775 7.18438 18.5562 7.15938 18.3344 7.15938Z"
              fill=""
            />
            <path
              d="M12.2406 8.65C12.0813 8.29063 11.6375 8.14375 11.2781 8.30313C10.9188 8.4625 10.7719 8.90625 10.9313 9.26563L12.9562 13.8688C13.1156 14.2281 13.5594 14.375 13.9188 14.2156C14.2781 14.0563 14.425 13.6125 14.2656 13.2531L12.2406 8.65Z"
              fill=""
            />
            <path
              d="M7.76559 8.30313C7.40622 8.14375 6.96247 8.29063 6.80309 8.65L4.77809 13.2531C4.61872 13.6125 4.76559 14.0563 5.125 14.2156C5.48437 14.375 5.92809 14.2281 6.08747 13.8688L8.11247 9.26563C8.27184 8.90625 8.12497 8.4625 7.76559 8.30313Z"
              fill=""
            />
            <path
              d="M13.8344 15.5969H6.16559C3.84372 15.5969 1.96559 17.475 1.96559 19.7969V20.8344C1.96559 21.2125 2.25309 21.5 2.63122 21.5H17.3687C17.7468 21.5 18.0343 21.2125 18.0343 20.8344V19.7969C18.0343 17.475 16.1562 15.5969 13.8344 15.5969ZM16.5718 20.1688H3.42809V19.7969C3.42809 18.2281 4.59684 17.0594 6.16559 17.0594H13.8344C15.4031 17.0594 16.5718 18.2281 16.5718 19.7969V20.1688Z"
              fill=""
            />
          </svg>
        </CardDataStats>
      </div>
    </div>
  );
};

export default Dashboard;
