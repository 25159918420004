import React from "react";
import Sidebar from "./sidebar";
import Dashboard from "../modules/dashboard";
import Heading from "../common/heading/Heading";
import useGlobalStore from "../../store/global";
import { modules } from "./common/accessConfig";

const AdminLayout= ({ children }) => {
  const { selectedModule } = useGlobalStore();
  const renderModule = () => {
    const selected = modules.find(
      (mod) =>
        mod.name === selectedModule
    );
    if (selected) {
      const Component = selected.component;
      return <Component />;
    } else {
      return <Dashboard />;
    }
  };

  return (
    <div className="flex min-h-screen">
      <Sidebar />
      <div className="flex-1 flex flex-col bg-[#F3F3F3] w-full h-screen overflow-x-hidden">
        <Heading title={selectedModule} />
        <div className="flex p-4 bg-[#F3F3F3] h-full">
          {children}
          {renderModule()}
        </div>
      </div>
    </div>
  );
};

export default AdminLayout;
