import { create } from "zustand";

const resumeIntialState = {
  resumeTitle: "",
  resumeImg: "",
  personalDetail: {
    fullName: "",
    email: "",
    phone: "",
    address: "",
    website: "",
    linkedin: "",
    github: "",
    about: "",
    updatedAt: null,
    createdAt: null,
  },
  educations: [
    {
      degree: "",
      college: "",
      location: "",
      fromDate: null,
      toDate: null,
      updatedAt: null,
      createdAt: null,
    },
  ],
  workExperiences: [
    {
      jobRole: "",
      companyName: "",
      companyLocation: "",
      jobDescription: "",
      fromDate: null,
      toDate: null,
      updatedAt: null,
      createdAt: null,
    },
  ],
  skills: [
    {
      title: "",
      skills: [],
      updatedAt: null,
      createdAt: null,
    },
  ],
  projects: [
    {
      title: "",
      stack: [],
      desc: "",
      updatedAt: null,
      createdAt: null,
    },
  ],
  socialLinks: [
    {
      handleName: "",
      handleLink: "",
      updatedAt: null,
      createdAt: null,
    },
  ],
  updatedAt: "",
  createdAt: "",
};

const resumeStore = create((set, get) => ({
  resume: null,
  resumes: null,
  setResume: (newResume) => set((state) => ({ ...state, resume: newResume })),
  setResumes: (newResumes) =>
    set((state) => ({ ...state, resumes: newResumes })),
  clearResume: () => set((state) => ({ ...state, resume: null })),
}));

export default resumeStore;
