import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { API_URL } from "../apiConfig";
import Employee from "../image/Employee.png";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
import axios from "axios";
import { toast } from "react-toastify";
// import userStore from "../store/user.zustand.js";
import { RiProfileLine } from "react-icons/ri";
import { CiEdit } from "react-icons/ci";
import userProfileStore from "../store/user-profile.zustand.js";
import { FaSpinner } from "react-icons/fa";
import { BsCloudCheckFill, BsExclamationTriangleFill } from "react-icons/bs";
import {Helmet} from "react-helmet";


const WORKMODE = [
  "full time",
  "part time",
  "freelance",
  "contract",
  "internship",
  "temporary",
  "volunteer",
  "hybrid",
  "remote",
  "none",
];

const NOTICEPERIOD = [
  "Immediate",
  "15 days",
  "1 month",
  "2 months",
  "3 months",
];

function Profile() {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const subscriptionId = searchParams.get("selectTab");
  // const status = searchParams.get("status");
  const [userProfile, setUserProfile] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    address: "",
    city: "",
    state: "",
    country: "",
    pincode: "",
    gender: "",
    dob: "",
    profileImage: "",
    planName: "",
    chargeAt: "",
    designation: "",
    orgnization: "",
    orgnizationCity: "",
    orgnizationWorkMode: "",
    totalExperience: "",
    noticePeriod: "",
  });

  const [selectTab, setSelectTab] = useState(
    subscriptionId === "edit-profile" ? "edit-profile" : "profile"
  );

  const [unsavedChanges, setUnsavedChanges] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const [saveMessage, setSaveMessage] = useState("");
  const [isEditing, setIsEditing] = useState(false);
  const [saveStatus, setSaveStatus] = useState("");

  // const zustandUserProfile = userProfileStore((state) => state.userProfile);

  const saveTimer = useRef(null);
  const handleChange = (e) => {
    const { name, value } = e.target;

    setUserProfile((prevData) => {
      const updatedProfile = {
        ...prevData,
        [name]: value,
      };

      setIsEditing(true);
      setSaveStatus("saving");

      // Clear any previous timer
      if (saveTimer.current) {
        clearTimeout(saveTimer.current);
      }

      saveTimer.current = setTimeout(() => {
        if (value && value?.length > 2) {
          saveProfile(updatedProfile);
        }
      }, 2000);

      return updatedProfile;
    });
  };

  const saveProfile = async (updatedProfile) => {
    setIsSaving(true);

    try {
      const currentUserProfile = updatedProfile; // The latest state
      // console.log({updatedProfile})
      setIsEditing(true);
      setSaveStatus("saving");
      const saveResp = await axios.post(
        `${API_URL}/api/v1/users/update-profile`,
        {
          firstName: currentUserProfile.firstName,
          lastName: currentUserProfile.lastName,
          phone: currentUserProfile.phone,
          address: currentUserProfile.address,
          city: currentUserProfile.city,
          state: currentUserProfile.state,
          country: currentUserProfile.country,
          pincode: currentUserProfile.pincode,
          gender: currentUserProfile.gender,
          dob: currentUserProfile.dob || "", // Use the latest dob
          designation: currentUserProfile.designation,
          orgnization: currentUserProfile.orgnization,
          orgnizationCity: currentUserProfile.orgnizationCity,
          orgnizationWorkMode: currentUserProfile.orgnizationWorkMode,
          totalExperience: currentUserProfile.totalExperience,
          noticePeriod: currentUserProfile.noticePeriod,
        },
        { withCredentials: true }
      );

      if (!saveResp?.data?.success) {
        setIsEditing(false);
        setSaveStatus("failed");
        toast.error(saveResp?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
      setSaveStatus("saved");

      // STORE THE DATA IN ZUSTAND
      userProfileStore.getState().setUserProfile({
        userProfile: {
          firstName: userProfile?.firstName,
          lastName: userProfile?.lastName,
          phone: userProfile?.phone,
          address: userProfile?.address,
          city: userProfile?.city,
          state: userProfile?.state,
          country: userProfile?.country,
          pincode: userProfile?.pincode,
          gender: userProfile?.gender,
          dob: userProfile?.dob || "",
          profilePic: userProfile?.profileImage,
          designation: userProfile?.designation,
          orgnization: userProfile?.orgnization,
          orgnizationCity: userProfile?.orgnizationCity,
          orgnizationWorkMode: userProfile?.orgnizationWorkMode,
          totalExperience: userProfile?.totalExperience,
          noticePeriod: userProfile?.noticePeriod,
        },
      });

      setIsSaving(false);
      setTimeout(
        () => {
          setSaveMessage("");
          // setSaveStatus("");
          setIsEditing(false);
        },

        3000
      );
    } catch (error) {
      setIsSaving(false);
      setSaveStatus("failed");
      console.error("Error:", error);
    }
  };

  const handleImageChange = async (e) => {
    const fileInput = e.target.files[0];
    if (fileInput) {
      const validTypes = ["image/png", "image/jpeg", "image/jpg"];
      if (!validTypes.includes(fileInput.type)) {
        toast.error("Please upload a valid image file (PNG, JPG, JPEG).", {
          autoClose: 2500,
        });
        return;
      }

      const formData = new FormData();
      formData.append("file", fileInput);

      try {
        setIsEditing(true);
        setSaveStatus("saving");
        const saveResp = await axios.post(
          `${API_URL}/api/v1/users/update-profile`,
          formData,
          {
            withCredentials: true,
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        );

        if (!saveResp?.data?.success) {
          setIsEditing(false);
          setSaveStatus("failed");
          toast.error(saveResp?.data?.message, {
            autoClose: 2500,
          });
          return;
        }
        setIsEditing(false);
        setSaveStatus("saved");

        const reader = new FileReader();
        reader.onloadend = () => {
          const base64Data = reader.result;
          setUserProfile((prevData) => ({
            ...prevData,
            profileImage: base64Data,
          }));
          // setUnsavedChanges(true);
        };
        reader.readAsDataURL(fileInput);

      } catch {
        setIsEditing(false);
        setSaveStatus("failed");
      }

      // await saveProfile();
    } else {
      console.error("No file selected or target files is undefined");
    }
  };

  // useEffect(() => {
  //   if (unsavedChanges) {
  //     if (saveTimeout) clearTimeout(saveTimeout);
  //     setSaveTimeout(setTimeout(saveProfile, 2000));
  //   }
  // }, [userProfile, userProfile?.profileImage]);

  const fetchUserProfile = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/get-profile`, {
        withCredentials: true,
      });

      if (!response?.data?.success) {
        toast.error(response?.data?.message, { autoClose: 2500 });
        return;
      }

      setUserProfile({
        firstName: response?.data?.data?.user?.firstName,
        lastName: response?.data?.data?.user?.lastName,
        phone: response?.data?.data?.user?.phone,
        address: response?.data?.data?.address,
        city: response?.data?.data?.city,
        state: response?.data?.data?.state,
        country: response?.data?.data?.country,
        pincode: response?.data?.data?.pincode,
        gender: response?.data?.data?.gender,
        dob: response?.data?.data?.dob || "",
        profileImage: response?.data?.data?.profilePic,
        designation: response?.data?.data?.designation,
        orgnization: response?.data?.data?.orgnization,
        orgnizationCity: response?.data?.data?.orgnizationCity,
        orgnizationWorkMode: response?.data?.data?.orgnizationWorkMode,
        totalExperience: response?.data?.data?.totalExperience,
        noticePeriod: response?.data?.data?.noticePeriod,
      });

      // STORE THE DATA IN ZUSTAND
      userProfileStore.getState().setUserProfile({
        userProfile: {
          ...response?.data?.data,
          firstName: response?.data?.data?.user?.firstName,
          lastName: response?.data?.data?.user?.lastName,
          email: response?.data?.data?.user?.email,
          phone: response?.data?.data?.user?.phone,
        },
      });
      return;
    } catch (error) {
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    fetchUserProfile();
  }, []);

  useEffect(() => {
    // Cleanup the timer if the component unmounts
    return () => {
      if (saveTimer.current) {
        clearTimeout(saveTimer.current);
      }
    };
  }, []);

  function formatDob(d) {
    if (d) {
      const timestamp = d;
      const date = new Date(timestamp);

      const day = String(date.getDate()).padStart(2, "0");
      const month = String(date.getMonth() + 1).padStart(2, "0");
      const year = date.getFullYear();

      const formattedDate = `${day}/${month}/${year}`;

      return formattedDate;
    } else {
      return "NA";
    }
  }

  function handlleUserProfileName() {
    if (userProfile?.firstName && userProfile?.lastName) {
      return `${userProfile?.firstName} ${userProfile?.lastName}`;
    } else if (userProfile?.firstName) {
      return `${userProfile?.firstName}`;
    } else if (userProfile?.lastName) {
      return `${userProfile?.lastName}`;
    } else {
      return `NA`;
    }
  }

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Profile - ResumeBeen | Manage Your Resume and Career Information</title>
            <link rel="canonical" href="https://resumebeen.com/profile" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/profile"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Profile - ResumeBeen | Manage Your Resume and Career Information"></meta>
            <meta property="og:title" content="Profile - ResumeBeen | Manage Your Resume and Career Information"></meta>


            <meta name="description" content="Manage your ResumeBeen profile to access personalized resume-building tools, track your progress, and update your career information. Customize your experience and build a professional resume that showcases your strengths."></meta>
            <meta property="og:description" content="Manage your ResumeBeen profile to access personalized resume-building tools, track your progress, and update your career information. Customize your experience and build a professional resume that showcases your strengths."></meta>

            <meta name="keywords" content="ResumeBeen profile, manage profile, career information, resume management, personalized resume tools, resume builder settings, profile customization, job seeker profile, resume progress tracking"></meta>
            <meta property="og:keywords" content="ResumeBeen profile, manage profile, career information, resume management, personalized resume tools, resume builder settings, profile customization, job seeker profile, resume progress tracking"></meta>

        </Helmet>      
      </>


      <Navbar />

      <div className="">
        <nav className="bg-[#EEEEEE5C]  top-16 pl-8 pr-8 w-full border-1 border-[#D8D8D8] mt-2">
          <div className="flex flex-col">
            <div className="flex p-3 space-x-2">
              <div>
                <button
                  type="submit"
                  className={`py-2 px-4 rounded-xl  flex justify-center items-center font-semibold  text-sm leading-normal font-raleway space-x-2 ${
                    selectTab === "profile"
                      ? "text-white bg-[#1d6362]"
                      : "text-[#343636] bg-[#D8D8D8]"
                  } `}
                  onClick={() => setSelectTab("profile")}
                >
                  <RiProfileLine size={18} className="" />
                  <p className="tracking-wide">Profile</p>
                </button>
              </div>
              <div>
                <button
                  type="submit"
                  className={`py-2 px-4 rounded-xl  flex justify-center items-center font-semibold  text-sm  font-raleway space-x-2 ${
                    selectTab === "edit-profile"
                      ? "text-white bg-[#1d6362]"
                      : "text-[#343636] bg-[#D8D8D8]"
                  } `}
                  onClick={() => setSelectTab("edit-profile")}
                >
                  <CiEdit size={18} className="" />
                  <p className="tracking-wide">Edit</p>
                </button>
              </div>
            </div>
          </div>
        </nav>

        <div className="p-5">
          <div className="mb-4">
            <h2 className="font-normal font-montserrat text-secondary">
              Update your <span className="font-bold">Profile</span> details{" "}
              <span className="font-bold text-tertiary">100%</span> to get
              faster response from employers
            </h2>
            {isSaving && <p>{saveMessage}</p>}
          </div>

          <div className="flex justify-center p-3 items-center bg-[#DDF2F2] rounded-xl border-2 border-[#C0DDDD]">
            <div className="flex items-center justify-center">
              {/* Right  */}
              {selectTab === "profile" && (
                <div className="grid grid-cols-1 grid-rows-3 p-2 sm:p-4 gap-y-4 md:grid-cols-6 md:grid-rows-2 lg:grid-cols-6 lg:grid-rows-2 gap-x-4">
                  {/* Image */}
                  <div className=" row-span-3 lg:row-span-1 lg:col-span-2 md:row-span-1 md:col-span-2 col-span-1 rounded-md co flex flex-col p-4 space-y-4 bg-[#FFFFFF] justify-center items-center border border-1 border-[#C0DDDD]">
                    <div className="flex items-center justify-center w-auto h-auto bg-white md:w-auto md:h-auto lg:w-40 lg:h-40 p-2 rounded-full border-2 border-[#D3D3D3]">
                      <img
                        src={userProfile?.profileImage || Employee}
                        alt="Profile"
                        className="object-cover rounded-full w-28 h-28"
                      />
                    </div>
                    <h2 className="text-xl font-normal font-montserrat">
                      {handlleUserProfileName()}
                    </h2>
                    <div className="flex flex-col items-start justify-center w-full space-y-4">
                      <label
                        htmlFor="imageUpload"
                        className="text-sm font-normal text-center font-montserrat"
                      >
                        Update your image
                      </label>
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        className="bg-white w-full text-center border border-1 border-[#D8D8D8]"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>

                  {/* details */}
                  <div className="flex flex-col row-span-2 space-y-4 md:col-span-4 md:row-span-2 lg:col-span-4 lg:row-span-2">
                    <div className="flex rounded-md flex-col space-y-4 border border-1 border-[#C0DDDD] bg-white p-4 ">
                      <h2 className="text-base font-medium leading-4 font-montserrat">
                        Candidate Details
                      </h2>

                      <div className="flex flex-col w-full space-y-4">
                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="firstName"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              First Name
                            </label>

                            <h2 className="p-1">
                              {userProfile?.firstName || "NA"}
                            </h2>
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="lastName"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Last Name
                            </label>

                            <h2 className="p-1">
                              {userProfile?.lastName || "NA"}
                            </h2>
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="phone"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Contact/Mobile
                            </label>

                            <h2 className="p-1">
                              {userProfile?.phone || "NA"}
                            </h2>
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="dob"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              DOB
                            </label>
                            <h2 className="p-1">
                              {/* {formatDob(userProfile?.dob)} */}
                              {userProfile?.dob || "NA"}
                            </h2>
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="orgnization"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Current Company
                            </label>
                            <h2 className="p-1">
                              {userProfile?.orgnization || "NA"}
                            </h2>
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="designation"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Current Designation
                            </label>

                            <h2 className="p-1">
                              {userProfile?.designation || "NA"}
                            </h2>
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="orgnizationWorkMode"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Work Mode
                            </label>
                            <h2 className="p-1">
                              {userProfile?.orgnizationWorkMode || "NA"}
                            </h2>
                          </div>
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="noticePeriod"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Notice Period
                            </label>
                            <h2 className="p-1">
                              {userProfile?.noticePeriod || "NA"}
                            </h2>
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="totalExperience"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Total Years of Experience
                            </label>
                            <h2 className="p-1">
                              {userProfile?.totalExperience || "NA"}
                            </h2>
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="orgnizationCity"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Company Location
                            </label>

                            <h2 className="p-1">
                              {userProfile?.orgnizationCity || "NA"}
                            </h2>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-md">
                      <div className="flex flex-col space-y-4 border border-1 border-[#C0DDDD] bg-white p-4 ">
                        <h2 className="text-base font-medium leading-4 font-montserrat">
                          Address Information
                        </h2>

                        <div className="flex flex-col space-y-4">
                          <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="address"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                Street
                              </label>
                              <h2 className="p-1">
                                {userProfile?.address || "NA"}
                              </h2>
                            </div>
                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="pincode"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                Zip/Postal Code
                              </label>
                              <h2 className="p-1">
                                {userProfile?.pincode || "NA"}
                              </h2>
                            </div>
                          </div>

                          <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="city"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                City
                              </label>
                              <h2 className="p-1">
                                {userProfile?.city || "NA"}
                              </h2>
                            </div>

                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="state"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                State
                              </label>
                              <h2 className="p-1">
                                {userProfile?.state || "NA"}
                              </h2>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {selectTab === "edit-profile" && (
                <div className="grid grid-cols-1 grid-rows-3 p-2 sm:p-4 gap-y-4 md:grid-cols-6 md:grid-rows-2 lg:grid-cols-6 lg:grid-rows-2 gap-x-4">
                  {/* Image */}
                  <div className="row-span-3 lg:row-span-1 lg:col-span-2 md:row-span-1 md:col-span-2 col-span-1 rounded-md co flex flex-col p-4 space-y-4 bg-[#FFFFFF] justify-center items-center border border-1 border-[#C0DDDD]">
                    <div className="flex items-center justify-center w-auto h-auto bg-white md:w-auto md:h-auto lg:w-40 lg:h-40 p-2 rounded-full border-2 border-[#D3D3D3]">
                      <img
                        src={userProfile?.profileImage || Employee}
                        alt="Profile"
                        className="object-cover rounded-full w-28 h-28"
                      />
                    </div>
                    <h2 className="text-xl font-normal font-montserrat">
                      {handlleUserProfileName()}
                    </h2>
                    <div className="flex flex-col items-start justify-center w-full space-y-4">
                      <label
                        htmlFor="imageUpload"
                        className="text-sm font-normal text-center font-montserrat"
                      >
                        Update your image
                      </label>
                      <input
                        type="file"
                        id="imageUpload"
                        accept=".png, .jpg, .jpeg"
                        className="bg-white w-full text-center border border-1 border-[#D8D8D8]"
                        onChange={handleImageChange}
                      />
                    </div>
                  </div>

                  {/* details */}
                  <div className="flex flex-col row-span-2 space-y-4 md:col-span-4 md:row-span-2 lg:col-span-4 lg:row-span-2">
                    <div className="flex rounded-md flex-col space-y-4 border border-1 border-[#C0DDDD] bg-white p-4 ">
                      <h2 className="text-base font-medium leading-4 font-montserrat">
                        Candidate Details
                      </h2>

                      <div className="flex flex-col w-full space-y-4">
                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="firstName"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              First Name
                            </label>
                            <input
                              type="text"
                              id="firstName"
                              name="firstName"
                              placeholder="Enter your first name"
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              onChange={handleChange}
                              value={userProfile?.firstName}
                            />
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="lastName"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Last Name
                            </label>
                            <input
                              type="text"
                              id="lastName"
                              name="lastName"
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              placeholder="Enter your last name"
                              onChange={handleChange}
                              value={userProfile?.lastName}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="phone"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Contact/Mobile
                            </label>
                            <input
                              type="number"
                              id="phone"
                              name="phone"
                              placeholder="Enter your contact/mobile"
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              onChange={handleChange}
                              value={userProfile?.phone}
                            />
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="dob"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              DOB
                            </label>
                            <input
                              type="date"
                              id="dob"
                              name="dob"
                              placeholder=""
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              onChange={handleChange}
                              value={userProfile?.dob}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="orgnization"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Current Company
                            </label>
                            <input
                              type="text"
                              id="orgnization"
                              name="orgnization"
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              placeholder="TCS"
                              onChange={handleChange}
                              value={userProfile?.orgnization}
                            />
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="designation"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Current Designation
                            </label>
                            <input
                              type="text"
                              id="designation"
                              name="designation"
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              placeholder="e.g. Software Developer"
                              onChange={handleChange}
                              value={userProfile?.designation}
                            />
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="orgnizationWorkMode"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Work Mode
                            </label>
                            <select
                              id="orgnizationWorkMode"
                              name="orgnizationWorkMode"
                              className="border-2 p-1 rounded-md border-[#D8D8D8] bg-white"
                              onChange={(e) => handleChange(e)}
                              value={userProfile?.orgnizationWorkMode}
                            >
                              <option className="w-full">Full time</option>
                              <option className="w-full">Part time</option>
                              <option className="w-full">Freelance</option>
                              <option className="w-full">Contract</option>
                              <option className="w-full">Internship</option>
                              <option className="w-full">Temporary</option>
                              <option className="w-full">Volunteer</option>
                              <option className="w-full">Hybrid</option>
                              <option className="w-full">Remote</option>
                              <option className="w-full">None</option>
                            </select>
                          </div>
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="noticePeriod"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Notice Period
                            </label>
                            <select
                              id="noticePeriod"
                              name="noticePeriod"
                              className="border-2 w-full p-1 rounded-md border-[#D8D8D8] bg-white"
                              onChange={(e) => handleChange(e)}
                              value={userProfile?.noticePeriod}
                            >
                              <option className="w-full">Immediate</option>
                              <option className="w-full">15 days</option>
                              <option className="w-full">1 month</option>
                              <option className="w-full">2 months</option>
                              <option className="w-full">3 months</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="totalExperience"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Total Years of Experience
                            </label>
                            <input
                              type="text"
                              id="totalExperience"
                              name="totalExperience"
                              placeholder="12years"
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              onChange={handleChange}
                              value={userProfile?.totalExperience}
                            />
                          </div>

                          <div className="flex flex-col w-full space-y-2">
                            <label
                              htmlFor="designation"
                              className="text-[#343636] text-sm font-raleway font-medium"
                            >
                              Company Location
                            </label>
                            <input
                              type="text"
                              id="orgnizationCity"
                              name="orgnizationCity"
                              className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                              placeholder=""
                              onChange={handleChange}
                              value={userProfile?.orgnizationCity}
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="rounded-md">
                      <div className="flex flex-col space-y-4 border border-1 border-[#C0DDDD] bg-white p-4 ">
                        <h2 className="text-base font-medium leading-4 font-montserrat">
                          Address Information
                        </h2>

                        <div className="flex flex-col space-y-4">
                          <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="address"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                Street
                              </label>
                              <input
                                type="text"
                                id="address"
                                name="address"
                                placeholder="Enter your street"
                                className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                                value={userProfile?.address}
                                onChange={handleChange}
                              />
                            </div>
                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="pincode"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                Zip/Postal Code
                              </label>
                              <input
                                type="number"
                                id="pincode"
                                name="pincode"
                                placeholder="Enter your street"
                                className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                                value={userProfile?.pincode}
                                onChange={handleChange}
                              />
                            </div>
                          </div>

                          <div className="flex flex-col items-start justify-start w-full space-y-2 sm:space-x-4 sm:flex-row sm:justify-start">
                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="city"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                City
                              </label>
                              <input
                                type="text"
                                id="city"
                                name="city"
                                placeholder="Enter your city"
                                className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                                value={userProfile?.city}
                                onChange={handleChange}
                              />
                            </div>

                            <div className="flex flex-col w-full space-y-2">
                              <label
                                htmlFor="state"
                                className="text-[#343636] text-sm font-raleway font-medium"
                              >
                                State
                              </label>
                              <input
                                type="text"
                                id="state"
                                name="state"
                                className="border-2 p-1 w-full rounded-md border-[#D8D8D8]"
                                placeholder="Enter your state"
                                value={userProfile?.state}
                                onChange={handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isEditing && (
                <div className="fixed p-2 rounded-lg shadow-lg bg-slate-500 bottom-4 right-12">
                  {saveStatus === "success" ? (
                    <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
                      <BsCloudCheckFill className="text-xl text-white icon" />
                      <span className="text-white">Saved</span>
                    </div>
                  ) : saveStatus === "failed" ? (
                    <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
                      <BsExclamationTriangleFill className="text-xl text-white icon" />
                      <span className="text-white">Failed AutoSave</span>
                    </div>
                  ) : saveStatus === "saving" ? (
                    <div className="flex flex-wrap items-center justify-center gap-1 text-sm">
                      <FaSpinner className="text-xl text-white icon animate-spin" />
                      <span className="text-white">Saving...</span>
                    </div>
                  ) : null}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      <Footer />
    </div>
  );
}

export default Profile;
