import React, { useState } from "react";
import { useNavigate } from "react-router-dom"; 
import logo1 from "../../../image/resumbeen.png"; 
import { useForm } from "react-hook-form"; 
import axios from "axios";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import useGlobalStore from "../../../store/global";
import CButton from "../../common/button/button";
import { API_URL } from "../../../apiConfig";

export default function AdminLogin() {
  const navigate = useNavigate();
  const { setToastData } = useGlobalStore();
  const [showPass, setShowPass] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = async (data) => {
    const { email, password } = data;

    try {
      setBtnLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/admin/login`, 
        { email, password },
        { withCredentials: true }
      );

      if (response?.data?.success) {
        setToastData({ message: "Login Successful", type: "success" });
        console.log("Login successful")
        navigate("/admin"); 
      }else if (!response?.data?.success){
        setToastData({ message: response?.data?.message, type: "error" });
      setBtnLoading(false)
        return
      }else {
        setToastData({ message: "An error occurred", type: "error" });
      setBtnLoading(false)
        return
      }
    } catch (error) {
      console.log(error?.response)
      const errorMessage = error.response?.data?.message || "An error occurred"; 
      setToastData({
        type: "error",
        message: errorMessage,
      });
      setBtnLoading(false)
    }
  };

  return (
    <section className="min-h-screen bg-neutral-100">
      <div className="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
        <div className="w-full bg-white rounded-lg md:mt-0 sm:max-w-md xl:p-0">
          <div className="p-6 space-y-4 md:space-y-6 sm:p-8">
            <div className="relative z-10 flex items-center justify-center gap-16">
              <img className="mb-4" src={logo1} alt="Logo" width={200} />
            </div>
            <h1 className="text-xl font-bold leading-tight tracking-tight text-black md:text-2xl">
              Sign in to your account
            </h1>
            <form
              className="space-y-4 md:space-y-6"
              onSubmit={handleSubmit(onSubmit)}
            >
              <div className="col-span-2">
                <label
                  htmlFor="email"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Email
                </label>
                <input
                  type="email"
                  {...register("email", {
                    required: "Email is required",
                    pattern: {
                      value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                      message: "Invalid email address",
                    },
                  })}
                  id="email"
                  className="admin-input input-primary"
                  placeholder="Enter your Email Address"
                />
                {errors.email && (
                  <p className="text-sm text-red-500">{errors.email.message}</p>
                )}
              </div>
              <div className="col-span-2">
                <label
                  htmlFor="password"
                  className="block mb-2 text-sm font-medium text-black"
                >
                  Password
                </label>
                <div className="relative">
                  <input
                    type={showPass ? "text" : "password"}
                    {...register("password", {
                      required: "Password is required",
                    })}
                    id="password"
                    className="relative pr-8 admin-input input-primary"
                    placeholder="Enter your Password"
                  />
                  <button
                    type="button"
                    className="absolute top-0 bottom-0 flex items-center justify-center h-full right-3"
                    onClick={() => setShowPass((prev) => !prev)}
                  >
                    {showPass ? (
                      <FaEye className="z-50 fill-neutral-400" />
                    ) : (
                      <FaEyeSlash className="z-50 fill-neutral-400" />
                    )}
                  </button>
                </div>
                {errors.password && (
                  <p className="text-sm text-red-500">{errors.password.message}</p>
                )}
              </div>
              <div className="flex justify-end mt-6">
                <CButton
                  loading={btnLoading}
                  variant="Primary" 
                  className="w-full"
                >
                  Sign In
                </CButton>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
}
