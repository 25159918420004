import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
// import facebook from "../image/social-media/facebook.svg";
// import instagram from "../image/social-media/instagram.svg";
// import twitter from "../image/social-media/twitter.svg";
// import linkedIn from "../image/social-media/linkedin.svg";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../apiConfig";
import { FaSpinner } from "react-icons/fa";
import bgHome from "../image/home/background/bg-home-01.png";
import emailIcon from "../image/home/subscribe/email.png";
import facebook from "../image/social-media/facebook01.svg";
import twitter from "../image/social-media/twitter01.svg";
import linkedin from "../image/social-media/linkedin01.svg";
import instgram from "../image/social-media/instagram01.svg";
import resumeBeen from "../image/resumebeen.svg";

const Footer = () => {
  const navigate = useNavigate();
  const [newsletter, setNewsLetter] = useState({
    email: "",
    loader: false,
  });
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  const handleSubscribe = async () => {
    try {
      if (!newsletter.email) {
        toast.error("Please enter your email address", { autoClose: 2500 });
        return;
      }

      if (!emailRegex.test(newsletter.email)) {
        toast.error("Please enter a valid email address", { autoClose: 2500 });
        return;
      }

      setNewsLetter((prev) => ({
        ...prev,
        loader: true,
      }));

      const response = await axios.post(
        `${API_URL}/api/v1/newsletter/subscribe`,
        {
          email: newsletter.email,
        }
      );

      if (!response?.data?.success) {
        setNewsLetter((prev) => ({
          ...prev,
          loader: false,
        }));
        toast.error(response?.data?.message, { autoClose: 2500 });
        return;
      }

      toast.success(response?.data?.message, { autoClose: 3000 });
      setNewsLetter({
        email: "",
        loader: false,
      });
      return;
    } catch (error) {
      setNewsLetter((prev) => ({
        ...prev,
        loader: false,
      }));
      console.log(error);
      toast.error(error.response?.data?.message, { autoClose: 2500 });
      return;
    }
  };

  return (
    <>
      <footer className="bg-[#F2F2F2] relative overflow-hidden">
        <img
          src={bgHome}
          alt="Background"
          className="absolute inset-0 object-contain top-[-152px] opacity-35 left-[577.98px]   object-top w-[970.33px] h-[714.29px]"
          style={{ transform: "rotate(764deg)" }}
        />
        <div className="relative z-10 flex flex-col justify-between w-full h-full space-y-8 overflow-hidden bg-right-top bg-no-repeat bg-auto lg:space-y-0 lg:flex-row p-14">
          <section className="z-10 lg:w-1/5 ">
            {/* <div className=""> */}
              <img
                src={resumeBeen}
                className="w-[200.27px] h-[45px]"
                alt="Logo"
              />
            {/* </div> */}
          </section>

          <section className="flex flex-col w-full h-full space-y-4 lg:w-3/5 lg:space-y-0 md:items-start md:justify-start lg:justify-between md:flex-row">
            <div className="flex flex-wrap justify-between w-full lg:justify-around">
              {/* <div className="flex flex-col justify-between space-y-10 h-ful md:space-y-8"> 
                <img
                  src={resumeBeen}
                  className="w-[167.27px] h-[40px]"
                  alt="Logo"
                />
              </div> */}

              <div className="flex flex-col h-full space-y-6">
                <h4 className="font-archivo font-semibold text-[22px] leading-6 text-[#1C1C1C]">
                  Products
                </h4>

                <ul className="pl-0 list-none">
                  <a
                    // href="https://hirebeen.com/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => navigate("/create-resume")}

                  >
                    <li className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer">
                      Create Resume With AI
                    </li>
                  </a>
                  <a
                    // href=
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => navigate("/dashboard")}
                  >
                    <li className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer">
                      Enhance Your Resume
                    </li>
                  </a>
                  <a
                    // href="https://hirebeen.com/"
                    target="_blank"
                    rel="noreferrer"
                    onClick={() => navigate("/dashboard")}
                  >
                    <li className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer">
                      Build Resume
                    </li>
                  </a>
                </ul>
              </div>

              <div className="z-10 flex flex-col justify-between h-full space-y-6">
                <h4 className="font-archivo font-semibold text-[22px] leading-6 text-[#1C1C1C]">
                  Company
                </h4>
                <ul className="pl-0 list-none">
                  <li
                    onClick={() => navigate("/about-us")}
                    className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer"
                  >
                    About us
                  </li>
                  <li
                    onClick={() => navigate("/contact-us")}
                    className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer"
                  >
                    Contact
                  </li>
                  <li
                    onClick={() => navigate("/contact-us")}
                    className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer"
                  >
                    Help
                  </li>
                  <li
                    onClick={() => navigate("/pricing")}
                    className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer"
                  >
                    Pricing
                  </li>
                </ul>
              </div>

              <div className="flex flex-col h-full space-y-6">
                <h4 className="font-archivo font-semibold text-[22px] leading-6 text-[#1C1C1C]">
                  Our Platform
                </h4>

                <ul className="pl-0 list-none">
                  <a
                    href="https://hirebeen.com/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer">
                      Hirebeen
                    </li>
                  </a>
                  <a
                    href="https://techbeen.co"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <li className="font-archivo font-normal text-sm leading-8 text-[#1C1C1C] hover:cursor-pointer">
                      Techbeen
                    </li>
                  </a>
                </ul>
              </div>
            </div>
          </section>

          <section className="z-10 flex flex-col items-start justify-start lg:w-2/5 w-u lg:items-center">
            <div className="flex flex-col h-full space-y-4 lg:space-y-0">
              <h4 className="text-[#1C1C1C] font-archivo font-semibold text-2xl leading-[26.11px] tracking--1">
                Subscribe to our newsletter
              </h4>

              <div className="flex items-center mt-4 bg-white rounded-md border p-2 gap-2 border-[#929292] shadow-news-letter">
                <img
                  src={emailIcon}
                  alt="email"
                  className="w-[25.33px] h-[20px] text-[#929292]"
                />
                <input
                  type="email"
                  placeholder="Your email"
                  className="text-[#929292] w-full focus:border-[#929292] border-1 rounded-md border-transparent focus:outline-none font-archivo font-normal leading-4 p-2"
                  onChange={(e) =>
                    setNewsLetter((prev) => ({
                      ...prev,
                      email: e.target.value,
                    }))
                  }
                  value={newsletter.email}
                />
                <button
                  className="text-white bg-[#EE702E] p-2 gap-2 font-archivo font-normal text-sm leading-4 rounded-md"
                  onClick={handleSubscribe}
                >
                  {newsletter.loader ? (
                    <FaSpinner className="text-white animate-spin" size="18" />
                  ) : (
                    "Submit"
                  )}
                </button>
              </div>
            </div>
          </section>
        </div>

        <div className="z-10 mx-12 mb-10">
          <hr className="mb-10 border-b-gray-700" />
          <div className="grid w-full grid-cols-1 gap gap-y-6 sm:grid-cols-2 md:grid-cols-3 ">
            <p className="font-archivo md:col-span-1 font-normal text-xs leading-3 text-[#535353]">
              Copyright © 2024. All rights reserved
            </p>
            <div className="z-10 flex justify-center space-x-4 md:col-span-1">
              <a
                href="https://www.facebook.com/hirebeen/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={facebook} alt="facebook" />
              </a>
              <a
                href="https://x.com/hirebeenai/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={twitter} alt="twitter" className="" />
              </a>
              <a
                href="https://www.linkedin.com/company/hirebeen/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={linkedin} alt="linkedin" />
              </a>
              <a
                href="https://www.instagram.com/hirebeenai/"
                target="_blank"
                rel="noreferrer"
              >
                <img src={instgram} alt="instgram" />
              </a>
            </div>
            <p className="font-archivo md:col-span-1 md:text-end font-normal text-xs leading-3 text-[#535353] z-10">
              <span
                onClick={() => navigate("/terms")}
                className="hover:cursor-pointer"
              >
                Terms & Conditions
              </span>{" "}
              |{" "}
              <span
                onClick={() => navigate("/privacy")}
                className="hover:cursor-pointer"
              >
                Privacy Policy
              </span>
            </p>
          </div>
        </div>
      </footer>
      {/* </div> */}
    </>
  );
};

export default Footer;
