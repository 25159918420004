import React, { useState, useEffect, useRef } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { BsX, BsRepeat, BsPlus } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import planExpire from "../image/plan_expired.png";
import spinner from "../image/spinner.svg";
import star from "../image/shines.png";
import { API_URL } from "../apiConfig";
import ReactTypingEffect from "react-typing-effect";
import axios from "axios";
import { toast } from "react-toastify";
import userStore from "../store/user.zustand.js";

const DynamicPersonalDetail = ({
  inputs,
  setInputs,
  setFormData,
  setDataSaved,
  aiToken,
  setAiToken,
  resser,
  resumeId,
}) => {
  const navigate = useNavigate();
  const {user, setUser,setAuthenticated} = userStore();
  const [loading, setLoading] = useState(false);
  const [tokenZero, setTokenZero] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showAssistBox, setShowAssistBox] = useState(false);

  const [selectedOption, setSelectedOption] = useState("generate");
  const [contextText, setContextText] = useState("");
  const [aiContentList, setaiContentList] = useState([]);
  const assistBoxRef = useRef(null);

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/me`, {
        withCredentials: true,
      });

      if (response?.data?.success) {
        setUser(response?.data?.data);
        setAuthenticated(true)
        // userStore
        //   .getState()
        //   .setUser({ user: response?.data?.data, isAuthenticated: true });
      } else {
        if (
          response?.data?.message === "Unauthorized" ||
          response?.data?.message === "User not found"
        ) {
          // redirect to login
          navigate("/login", { replace: true });
        }
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
      return;
    } catch (error) {
      setAuthenticated(false)
      // userStore.getState().setUser({ isAuthenticated: false });
      toast.error(error?.response?.data?.message, {
        autoClose: 2500,
      });
      navigate("/login", { replace: true });
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    if (user?.tokenDetails?.tokens <= 0) {
      setTokenZero(true);
    } else {
      setTokenZero(false);
    }
  }, [user?.tokenDetails?.tokens]);

  const handleChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index][event.target.name] = event.target.value;
    setInputs(updatedInputs);
    setDataSaved(false);
  };

  const handleCKEditorChange = (index, data) => {
    const updatedInputs = [...inputs];
    updatedInputs[index]["about"] = data;
    setInputs(updatedInputs);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
      ],
    },
  };

  const handleImageChange = (index, e) => {
    const fileInput = e.target.files[0];
    const acceptedTypes = ["image/png", "image/jpeg"];
    if (!acceptedTypes.includes(fileInput.type)) {
      toast.error("Invalid file type. Please upload a PNG or JPG image.", {
        autoClose: 2500,
      });
      return;
    }
    if (fileInput) {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64 = reader.result;
        const updatedInputs = [...inputs];
        updatedInputs[index]["profileImage"] = base64;
        console.log({
          updatedInputs
        })
        setInputs(updatedInputs);
      };
      reader.readAsDataURL(fileInput);
    } else {
      console.error("No file selected or target files is undefined");
    }
  };

  const handleAiassist = () => {
    setShowAssistBox(!showAssistBox);
    setaiContentList(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        assistBoxRef.current &&
        !assistBoxRef.current.contains(event.target)
      ) {
        setShowAssistBox(false);
        setaiContentList(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assistBoxRef]);

  const handleSubmitD = async (event, profileSummary) => {
    event.preventDefault();
    if (user?.tokenDetails?.tokens <= 0) {
      setTokenZero(true);
      return;
    }
    try {
      setButtonDisabled(true);
      setLoading(true);
      setaiContentList(null);
      //   if (selectedOption === "generate"){
      //     const response = await fetch(`${API_URL}/generate_summary`, {
      //       method: 'POST',
      //       headers: {
      //         'X-CSRFToken': CSRFToken(document.cookie),
      //         'Content-Type': 'application/json',
      //         'Authorization': `Bearer ${authToken}`
      //       },
      //       body: JSON.stringify({
      //         resumeId: resser,
      //         profile_summary: profileSummary,
      //         mode: selectedOption,
      //         section: "profile",
      //         context: contextText, // You need to implement this function to calculate the duration in months
      //       }),
      //     });
      //     if (response.status === 400) {
      //       setTokenZero(true);
      //     }
      //     else{
      //       const data = await response.json();
      //       setLoading(false)
      //       setaiContentList([data.output]);
      //       await fetchUserProfile()
      //     }
      // }
      // else if (selectedOption === "rewrite"){
      //   const response = await fetch(`${API_URL}/generate_summary`, {
      //     method: 'POST',
      //     headers: {
      //       'X-CSRFToken': CSRFToken(document.cookie),
      //       'Content-Type': 'application/json',
      //       'Authorization': `Bearer ${authToken}`
      //     },
      //     body: JSON.stringify({
      //         profile_summary: profileSummary,
      //         mode: selectedOption,
      //         section: "profile",
      //         context: contextText, // You need to implement this function to calculate the duration in months
      //     }),
      //   });
      //   if (response.status === 400) {
      //     setTokenZero(true);
      //   }
      //   else{
      //     const data = await response.json();
      //     setLoading(false)
      //     setaiContentList([data.output]);
      //     await fetchUserProfile()
      //   }
      // }

      const response = await axios.post(
        `${API_URL}/api/v1/generate-summary/profile`,
        {
          resumeId: resumeId,
          profileSummary: profileSummary,
          contexts: contextText,
          mode: selectedOption,
        },
        {
          withCredentials: true,
        }
      );
      if (!response?.data?.success) {
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      } else if (response?.status === 403) {
        setTokenZero(true);
      } else {
        setLoading(false);
        setaiContentList([response?.data?.data?.output]);
        await fetchUser();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:retry", error);
    }
    setButtonDisabled(false);
  };

  const handleCancel = () => {
    setShowAssistBox(!showAssistBox);
  };

  const handleUpgrade = () => {
    navigate("/pricing");
  };

  return (
    <div className="flex flex-wrap mt-3 rounded-lg">
      {inputs.map((input, index) => (
        <div key={index} className="w-full">
          <div className="flex flex-wrap mb-3">
            <input
              type="file"
              accept=".png, .jpg, .jpeg"
              className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
              onChange={(e) => handleImageChange(index, e)}
            />
            {/* <label className="w-full">Full Name</label> */}
            <input
              className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
              placeholder="Full Name"
              name="fullName"
              value={input.fullName}
              onChange={(e) => handleChange(index, e)}
            />
            <div className="w-full md:w-1/2 md:pr-2">
              {/* <label>Email</label> */}
              <input
                className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
                placeholder="Email Address"
                name="email"
                value={input.email}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              {/* <label>Phone number</label> */}
              <input
                type="tel"
                className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
                placeholder="Phone Number"
                name="phone"
                value={input.phone}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
            <div className="w-full md:w-1/2 md:pr-2">
              {/* <label>Current Location</label> */}
              <input
                className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
                placeholder="Current Location"
                name="address"
                value={input.address}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              {/* <label>Website</label> */}
              <input
                type="url"
                className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
                placeholder="Personal website"
                name="website"
                value={input.website}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
            <div className="w-full md:w-1/2 md:pr-2">
              {/* <label>Github</label> */}
              <input
                type="url"
                className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
                placeholder="Github"
                name="github"
                value={input.github}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
            <div className="w-full md:w-1/2 md:pl-2">
              {/* <label>Linkedin</label> */}
              <input
                type="url"
                className="block w-full p-2 mb-3 border border-gray-300 rounded-xl"
                name="linkedin"
                placeholder="linkedin"
                value={input.linkedin}
                onChange={(e) => handleChange(index, e)}
              />
            </div>
          </div>
          <CKEditor
            editor={ClassicEditor}
            config={editorConfiguration}
            data={input.about}
            value={input.about}
            name="about"
            onChange={(event, editor) =>
              handleCKEditorChange(index, editor.getData())
            }
          />
          <div className="flex relative justify-end w-full border-r-[1px] border-l-[1px] border-b-[1px] rounded-b-sm text-sm border-[var(--ck-color-base-border)]">
            <button
              className="flex flex-wrap items-center px-2 py-1 m-2 text-white bg-blue-500 rounded-md highlight_bg hover:bg-blue-700"
              onClick={handleAiassist}
            >
              <img src={star} className="w-4 mr-1" alt="AI" />
              AI Assist
            </button>
            {showAssistBox && (
              <div ref={assistBoxRef} className="assist_main_box">
                {!tokenZero ? (
                  <div>
                    <div className="flex mx-4 flex-wrap items-center mt-2 mb-1 h-fit text-gray-500 text-[15px]">
                      {selectedOption === "rewrite" && (
                        <label className="flex-1 h-full">
                          Please write something in summary to rewrite with AI.
                        </label>
                      )}
                      {selectedOption === "generate" && (
                        <label className="flex-1 h-fit">
                          Please give some context to generate with AI.
                        </label>
                      )}
                      <BsX
                        className="flex-none text-3xl font-semibold text-black h-fit"
                        onClick={handleAiassist}
                      />
                    </div>
                    <hr className="mx-4 text-gray-400" />
                    <form
                      onSubmit={(event) => handleSubmitD(event, input.about)}
                      className="ai_assist_form"
                    >
                      <div className="flex-none h-fit">
                        <select
                          id="options"
                          value={selectedOption}
                          onChange={(event) =>
                            setSelectedOption(event.target.value)
                          }
                        >
                          <option value="generate">Generate</option>
                          <option value="rewrite">Rewrite</option>
                        </select>
                      </div>
                      <div className="flex-1 mx-1 h-fit">
                        <input
                          type="text"
                          id="input"
                          className="w-full border-none"
                          placeholder=""
                          value={contextText}
                          onChange={(event) =>
                            setContextText(event.target.value)
                          }
                        />
                        {contextText === "" && (
                          <ReactTypingEffect
                            text={[
                              "Give Some Context",
                              "eg: I am a Web Developer",
                            ]}
                            speed={100}
                            eraseSpeed={50}
                            typingDelay={500}
                            eraseDelay={2000}
                            className="w-full text-gray-400 border-none"
                            cursorClassName="TypingCursor"
                            displayTextRenderer={(text, i) => (
                              <h1>
                                {text.split("").map((char, i) => (
                                  <span key={i}>{char}</span>
                                ))}
                              </h1>
                            )}
                          />
                        )}
                      </div>
                      <button
                        className="flex-none px-2 text-white rounded-md h-fit"
                        disabled={buttonDisabled}
                        type="submit"
                      >
                        <img
                          loading="lazy"
                          src="https://cdn.builder.io/api/v1/image/assets/TEMP/395eff768bf5008660a50c05ecbf846f9939e43ba67ae2e3117c057669e9f3dc?apiKey=e64c88474a2c4290b8b66f50b4a39c22&"
                          alt="Software Developer Badge Icon"
                          className="aspect-square w-[20px]"
                        />
                      </button>
                    </form>
                    <div className="flex flex-col flex-wrap mx-4">
                      {aiContentList ? (
                        aiContentList.map((option, index) => (
                          <div
                            key={index}
                            className="flex flex-col items-center mb-2"
                          >
                            <label className="ml-2">{option}</label>
                            <div className="flex gap-2 mt-2">
                              <button
                                className="flex flex-wrap items-center p-1 bg-gray-300 border-gray-600 rounded-md border-1"
                                onClick={(event) => {
                                  const updatedInputs = [...inputs];
                                  const currentInput = updatedInputs[index];
                                  let about = currentInput.about;
                                  about += option;
                                  updatedInputs[index] = {
                                    ...currentInput,
                                    about,
                                  };
                                  setInputs(updatedInputs);
                                }}
                              >
                                <BsPlus />
                                Add
                              </button>
                              <button
                                className="flex bg-gray-300 border-1 border-gray-600 rounded-md flex-wrap items-center p-1 gap-[2px]"
                                onClick={(event) => {
                                  const updatedInputs = [...inputs];
                                  const currentInput = updatedInputs[index];
                                  let about = currentInput.about;
                                  about = option;
                                  updatedInputs[index] = {
                                    ...currentInput,
                                    about,
                                  };
                                  setInputs(updatedInputs);
                                }}
                              >
                                <BsRepeat />
                                Replace
                              </button>
                            </div>
                          </div>
                        ))
                      ) : (
                        <div className="flex justify-center mt-6">
                          {loading ? (
                            <img
                              loading="lazy"
                              src={spinner}
                              alt="spinner"
                              className="w-8 h-8 opacity-50 animate-spin max-md:w-6 max-md:h-6"
                            />
                          ) : (
                            <span>No data available</span>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <div className="flex flex-col items-center justify-center mt-3">
                    <img src={planExpire} className="w-[20%]" alt="" />
                    <p className="text-2xl font-semibold">
                      Token Limit exceeded, No active plan.
                    </p>
                    <p className="mx-4 my-2 text-base text-center">
                      Activate a plan to use AI Assist.
                    </p>
                    <div className="flex w-full gap-2 px-8 mx-4 mt-4">
                      <button
                        className="flex-1 py-2 text-sm font-semibold border-black rounded-md h-fit border-1"
                        onClick={handleCancel}
                      >
                        Cancel
                      </button>
                      <button
                        className="flex-1 py-2 text-sm font-semibold text-white rounded-md h-fit highlight_bg border-1"
                        onClick={handleUpgrade}
                      >
                        Upgrade Plan
                      </button>
                    </div>
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      ))}
    </div>
  );
};

export default DynamicPersonalDetail;
