// store/globalLoaderStore.ts
import { create } from "zustand";


const useGlobalLoaderStore = create((set) => ({
  isLoading: false,
  setLoading: (loading) => set({ isLoading: loading }),
}));

export default useGlobalLoaderStore;
