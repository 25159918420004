import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as DeleteIcon } from "../image/icons/delsolid.svg";
// import { Tooltip } from "react-tooltip";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AlertComponent from "./AlertComponent";
// import CSRFToken from "../cookies.js";
import spinner from "../image/spinner.svg";
import star from "../image/shines.png";
import { API_URL } from "../apiConfig";
import { BsX, BsRepeat, BsPlus, BsCheckAll } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import planExpire from "../image/plan_expired.png";
import axios from "axios";
import { toast } from "react-toastify";
import userStore from "../store/user.zustand.js";

const DynamicProject = ({
  inputs,
  setInputs,
  setDataSaved,
  aiToken,
  setAiToken,
  resumeId,
}) => {
  const navigate = useNavigate();
  // const dispatch = useDispatch();
  const {user, setUser,setAuthenticated  } =  userStore()
  const [loading, setLoading] = useState(false);
  const [tokenZero, setTokenZero] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [contextText, setContextText] = useState("");
  const [showAlert, setShowAlert] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [showAssistBox, setShowAssistBox] = useState(false);

  const [selectedOption, setSelectedOption] = useState("generate");
  const [inputValue, setInputValue] = useState("");
  const [durationValue, setdurationValue] = useState("");
  const [aiContentList, setaiContentList] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const assistBoxRef = useRef(null);

  // State to manage accordion behavior
  const [accordionIndex, setAccordionIndex] = useState(0);

  const handleChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index][event.target.name] = event.target.value;
    setInputs(updatedInputs);
    setDataSaved(false);
  };

  useEffect(() => {
    if (user?.tokenDetails?.tokens <= 0) {
      setTokenZero(true);
    } else {
      setTokenZero(false);
    }
  }, [user?.tokenDetails?.tokens]);

  const handleAddProject = () => {
    const newInput = { title: "", stack: "", desc: "" };
    setInputs([...inputs, newInput]);
    setAccordionIndex(inputs.length);
  };

  const handleRemoveProject = (index) => {
    setIndexToRemove(index);
    setShowAlert(true);
  };

  const confirmHandleRemoveProject = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(indexToRemove, 1);
    setInputs(updatedInputs);
    setShowAlert(false);
    // If the removed accordion is the one that's open, update the accordionIndex
    if (indexToRemove === accordionIndex) {
      setAccordionIndex(updatedInputs.length > 0 ? 0 : null);
    }
  };

  const cancelHandleRemoveProject = () => {
    setShowAlert(false);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
      ],
    },
  };

  const handleCKEditorChange = (index, data) => {
    const updatedInputs = [...inputs];
    updatedInputs[index]["desc"] = data;
    setInputs(updatedInputs);
  };

  const renderDescription = (input, index) => {
    let DescriptionHTML;
    if (Array.isArray(input.desc)) {
      DescriptionHTML = input.desc
        .map((responsibility) => `<li>${responsibility}</li>`)
        .join("");
      DescriptionHTML = `<ul>${DescriptionHTML}</ul>`;
      return (
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={DescriptionHTML}
          onChange={(event, editor) =>
            handleCKEditorChange(index, editor.getData())
          }
        />
      );
    } else {
      return (
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={input.desc}
          onChange={(event, editor) =>
            handleCKEditorChange(index, editor.getData())
          }
        />
      );
    }
  };

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    if (accordionIndex === index && inputs.length > 1) {
      setAccordionIndex(null);
    } else {
      setAccordionIndex(index);
    }
  };

  // const fetchUserProfile = async () => {
  //   try {
  //     const authToken = localStorage.getItem("access");

  //     const response = await fetch(`${API_URL}/fetch-user-profile`, {
  //       method: "GET",
  //       headers: {
  //         "X-CSRFToken": CSRFToken(document.cookie),
  //         "Content-Type": "application/json",
  //         Authorization: `Bearer ${authToken}`,
  //       },
  //     });

  //     const data = await response.json();

  //     // dispatch({
  //     //   type: TYPE.GET_USER_SUCCESS,
  //     //   payload: {
  //     //     user: data,
  //     //   },
  //     // });
  //     return;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/me`, {
        withCredentials: true,
      });

      if (response?.data?.success) {
        setUser(response?.data?.data)
        setAuthenticated(true)
        // userStore.getState().setUser({ user: response?.data?.data,isAuthenticated:true });
      } else {
        if (
          response?.data?.message === "Unauthorized" ||
          response?.data?.message === "User not found"
        ) {
          // redirect to login
          navigate("/login", { replace: true });
        }
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
      return;
    } catch (error) {
      setAuthenticated(false)
      // userStore.getState().setUser({isAuthenticated:false });
      toast.error(error?.response?.data?.message, {
        autoClose: 2500,
      });
      navigate("/login", { replace: true });
      console.log(error);
      return;
    }
  };

  const handleAiassist = () => {
    setShowAssistBox(!showAssistBox);
    if (showAssistBox) {
      setCheckedItems([]); // Reset checked items when closing the AI Assist box
    }
    setaiContentList(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        assistBoxRef.current &&
        !assistBoxRef.current.contains(event.target)
      ) {
        setShowAssistBox(false);
        setCheckedItems([]);
        setaiContentList(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assistBoxRef]);

  const handleSubmitP = async (event, title, stack, desc) => {
    event.preventDefault();
    if (user?.tokenDetails?.tokens <= 0) {
      setTokenZero(true);
      return;
    }
    try {
      // const authToken = localStorage.getItem("access");
      setButtonDisabled(true);
      setLoading(true);
      setaiContentList(null);
      // if (selectedOption === "generate") {
      //   const response = await fetch(`${API_URL}/generate_summary`, {
      //     method: "POST",
      //     headers: {
      //       "X-CSRFToken": CSRFToken(document.cookie),
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${authToken}`,
      //     },
      //     body: JSON.stringify({
      //       title: title,
      //       mode: selectedOption,
      //       section: "project",
      //       stack: stack,
      //       contexts: contextText,
      //     }),
      //   });
      //   if (response.status === 400) {
      //     setTokenZero(true);
      //   } else {
      //     const data = await response.json();
      //     setLoading(false);
      //     setaiContentList(data.output);
      //     await fetchUserProfile();
      //   }
      // } else if (selectedOption === "rewrite") {
      //   const response = await fetch(`${API_URL}/generate_summary`, {
      //     method: "POST",
      //     headers: {
      //       "X-CSRFToken": CSRFToken(document.cookie),
      //       "Content-Type": "application/json",
      //       Authorization: `Bearer ${authToken}`,
      //     },
      //     body: JSON.stringify({
      //       title: title,
      //       mode: selectedOption,
      //       section: "project",
      //       stack: stack,
      //       contexts: contextText,
      //       desc: desc,
      //     }),
      //   });
      //   if (response.status === 400) {
      //     setTokenZero(true);
      //   } else {
      //     const data = await response.json();
      //     setLoading(false);
      //     setaiContentList(data.output);
      //     await fetchUserProfile();
      //   }
      // }

      const response = await axios.post(
        `${API_URL}/api/v1/generate-summary/project`,
        {
          resumeId: resumeId,
          projectName: title,
          projectStack: stack,
          projectDescription: desc,
          contexts: contextText,
          mode: selectedOption,
        },
        {
          withCredentials: true,
        }
      );
      if (!response?.data?.success) {
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      } else if (response?.status === 403) {
        setTokenZero(true);
      } else {
        setLoading(false);
        setaiContentList(response?.data?.data?.output);
        await fetchUser();
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:retry", error);
    }
    setButtonDisabled(false);
  };

  const handleSelectAll = () => {
    if (aiContentList) {
      setCheckedItems(aiContentList);
    }
  };

  const handleCancel = () => {
    setShowAssistBox(!showAssistBox);
  };

  const handleUpgrade = () => {
    navigate("/pricing");
  };

  const handleAddCheckedItems = (index) => {
    const updatedInputs = [...inputs];
    const currentDescription = updatedInputs[index].desc || "";
    const newDescription = `${currentDescription}<ul>${checkedItems
      .map((item) => `<li>${item}</li>`)
      .join("")}</ul>`;
    updatedInputs[index].desc = newDescription;
    setInputs(updatedInputs);
  };

  const handleReplaceCheckedItems = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs[index].desc = `<ul>${checkedItems
      .map((item) => `<li>${item}</li>`)
      .join("")}</ul>`;
    setInputs(updatedInputs);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, name]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== name));
    }
  };

  return (
    <div>
      {inputs.map((input, index) => (
        <div
          key={index}
          className="px-3 py-2 m-3 bg-gray-100 border-gray-300 rounded-lg cursor-pointer accordion-card border-1"
        >
          <div
            onClick={() => toggleAccordion(index)}
            className="flex items-center justify-between text-lg text-gray-600"
          >
            {input.title === ""
              ? `Project ${index + 1}`
              : input.title.length > 20
              ? `${input.title.slice(0, 20)}...`
              : input.title}
            {inputs.length > 1 && (
              <button
                onClick={() => handleRemoveProject(index)}
                className="text-white "
              >
                <DeleteIcon
                  className="w-5 h-5 hover:fill-red-600"
                  data-tooltip-id="my-tooltip"
                  data-tooltip-content="Delete"
                  data-tooltip-place="right-end"
                />
              </button>
            )}
          </div>
          <AlertComponent
            show={showAlert}
            onConfirm={confirmHandleRemoveProject}
            onCancel={cancelHandleRemoveProject}
          />
          {accordionIndex === index && (
            <div className="text-sm text-black">
              <div className="flex flex-wrap">
                <div className="w-full mb-2 md:w-1/2 md:pr-2">
                  <label>Project Title</label>
                  <br />
                  <input
                    type="text"
                    name="title"
                    value={input.title}
                    onChange={(event) => handleChange(index, event)}
                    placeholder="Project Name"
                    className="w-full p-2 border rounded-xl"
                  />
                </div>
                <div className="w-full mb-2 md:w-1/2 md:pl-2">
                  <label>Platforms</label>
                  <br />
                  <input
                    type="text"
                    name="stack"
                    value={input.stack}
                    onChange={(event) => handleChange(index, event)}
                    placeholder="Project Platform"
                    className="w-full p-2 border rounded-xl"
                  />
                </div>
              </div>
              <div>
                <label>Description</label>
                <br />
                <div className="bg-white rounded-sm">
                  {renderDescription(input, index)}
                  <div className="flex relative justify-end w-full border-r-[1px] border-l-[1px] border-b-[1px] rounded-b-sm text-sm border-[var(--ck-color-base-border)]">
                    <button
                      className="flex flex-wrap items-center px-2 py-1 m-2 text-white bg-blue-500 rounded-md highlight_bg hover:bg-blue-700"
                      onClick={handleAiassist}
                    >
                      <img src={star} className="w-4 mr-1" alt="" />
                      AI Assist
                    </button>
                    {showAssistBox && (
                      <div ref={assistBoxRef} className="assist_main_box">
                        {tokenZero ? (
                          <div className="flex flex-col items-center justify-center mt-3">
                            <img src={planExpire} className="w-[20%]" alt="" />
                            <p className="text-2xl font-semibold">
                              Token Limit exceeded, No active plan.
                            </p>
                            <p className="mx-4 my-2 text-base text-center">
                              Activate a plan to use AI Assist.
                            </p>
                            <div className="flex w-full gap-2 px-8 mx-4 mt-4">
                              <button
                                className="flex-1 py-2 text-sm font-semibold border-black rounded-md h-fit border-1"
                                onClick={handleCancel}
                              >
                                Cancel
                              </button>
                              <button
                                className="flex-1 py-2 text-sm font-semibold text-white rounded-md h-fit highlight_bg border-1"
                                onClick={handleUpgrade}
                              >
                                Upgrade Plan
                              </button>
                            </div>
                          </div>
                        ) : (
                          <>
                            <div className="flex mx-4 flex-wrap items-center mt-2 mb-1 h-fit text-gray-500 text-[15px]">
                              {selectedOption === "rewrite" && (
                                <label className="flex-1 h-full">
                                  Please write something in description to
                                  rewrite with AI.
                                </label>
                              )}
                              {selectedOption === "generate" && (
                                <label className="flex-1 h-fit">
                                  Please enter project name and stack to
                                  generate with AI.
                                </label>
                              )}
                              <BsX
                                className="flex-none text-3xl font-semibold text-black h-fit"
                                onClick={handleAiassist}
                              />
                            </div>
                            <hr className="mx-4 text-gray-400" />
                            <form
                              onSubmit={(event) =>
                                handleSubmitP(
                                  event,
                                  input.title,
                                  input.stack,
                                  input.desc
                                )
                              }
                              className="ai_assist_form"
                            >
                              <div className="flex-none h-fit">
                                <select
                                  id="options"
                                  value={selectedOption}
                                  onChange={(event) =>
                                    setSelectedOption(event.target.value)
                                  }
                                >
                                  <option value="generate">Generate</option>
                                  <option value="rewrite">Rewirte</option>
                                </select>
                              </div>
                              <div className="flex-1 mx-1 h-fit">
                                <input
                                  type="text"
                                  id="input"
                                  className="w-full border-none"
                                  value={contextText}
                                  onChange={(event) =>
                                    setContextText(event.target.value)
                                  }
                                />
                              </div>
                              <button
                                className="flex-none px-2 text-white rounded-md h-fit"
                                disabled={buttonDisabled}
                                type="submit"
                              >
                                <img
                                  loading="lazy"
                                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/395eff768bf5008660a50c05ecbf846f9939e43ba67ae2e3117c057669e9f3dc?apiKey=e64c88474a2c4290b8b66f50b4a39c22&"
                                  alt="Software Developer Badge Icon"
                                  className="aspect-square w-[20px]"
                                />
                              </button>
                            </form>
                            <div className="flex flex-col flex-wrap mx-4">
                              {aiContentList && (
                                <div className="flex gap-2">
                                  <button
                                    className="flex flex-wrap items-center p-1 bg-gray-200 border-gray-600 rounded-md border-1"
                                    onClick={handleSelectAll}
                                  >
                                    <BsCheckAll />
                                    Select all
                                  </button>
                                  <button
                                    className="flex flex-wrap items-center p-1 bg-gray-200 border-gray-600 rounded-md border-1"
                                    onClick={() => handleAddCheckedItems(index)}
                                  >
                                    <BsPlus />
                                    Add
                                  </button>
                                  <button
                                    className="flex bg-gray-200 border-1 border-gray-600 rounded-md flex-wrap items-center p-1 gap-[2px]"
                                    onClick={() =>
                                      handleReplaceCheckedItems(index)
                                    }
                                  >
                                    <BsRepeat />
                                    Replace
                                  </button>
                                </div>
                              )}
                              {aiContentList ? (
                                aiContentList.map((option, index) => (
                                  <div
                                    key={index}
                                    className="flex items-center mb-2"
                                  >
                                    <input
                                      type="checkbox"
                                      name={option}
                                      checked={checkedItems.includes(option)}
                                      onChange={handleCheckboxChange}
                                    />
                                    <label className="ml-2">{option}</label>
                                  </div>
                                ))
                              ) : (
                                <div className="flex justify-center mt-6">
                                  {loading ? (
                                    <img
                                      loading="lazy"
                                      src={spinner}
                                      alt="spinner"
                                      className="w-8 h-8 opacity-50 animate-spin max-md:w-6 max-md:h-6"
                                    />
                                  ) : (
                                    <span>No data available</span>
                                  )}
                                </div>
                              )}
                            </div>
                          </>
                        )}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      ))}
      <div
        onClick={handleAddProject}
        className="flex justify-center p-2 m-3 text-lg font-semibold text-white delay-700 bg-orange-500 cursor-pointer rounded-xl"
      >
        + Add Project
      </div>
    </div>
  );
};

export default DynamicProject;
