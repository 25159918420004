import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { FaEdit, FaShieldAlt, FaTrash } from "react-icons/fa";
import RoopTable from "../../common/customTableR/table";
import useAuthStore from "../../../store/auth";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import { API_URL } from "../../../apiConfig";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";

const PaymentDetails = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();
  const [paymentDetails, setPaymentDetails] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [fetch, setFetch] = useState(0)
  const [showModal, setShowModal] = useState({
    pause:false,
    resume:false,
    cancel:false
  });

  const [userData, setUserData] = useState({
    userName:"",
    subscriptionId:"",
    userId:""
  })
  const [page, setPage] = useState(1)


  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/v1/admin/payments?page=${page}`, {
          withCredentials: true,
        });

        let count = 1;
        // Map the fetched payment details properly to the users' state
        setPaymentDetails(
          response?.data?.data.map((el) => {
            return {
              count: count++,
              userId: el?.userId,
              userName: el?.userName ?? "",
              email: el?.email,
              phone: el?.phone ?? null,
              status: el?.status,
              productName: el?.productName,
              subscriptionId: el?.subscriptionId,
              totalCount: el?.totalCount,
              paidCount: el?.paidCount,
              razorpayDetails: {
                eventType: el?.razorpayDetails?.eventType,
                invoiceURL: el?.razorpayDetails?.invoiceURL,
                currentEnd: el?.razorpayDetails?.currentEnd,
                eventTimestamp: el?.razorpayDetails?.eventTimestamp,
                amount: el?.razorpayDetails?.amount,
                chargeAt: el?.razorpayDetails?.chargeAt,
              },
              updatedAt: formatDate(el?.updatedAt) ?? null,
              createdAt: formatDate(el?.createdAt) ?? null,
            };
          })
        );
      } catch (error) {
        console.error("Error fetching payment details:", error);
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [setLoading,fetch,page]);


  const handlePausePayment = async () => {
    try {

      if(!userData?.userId || !userData?.subscriptionId){
        setToastData({ type: "error", message: "User and subscription details are required." });
        return;
      }

      setBtnLoading(true)
      const response = await axios.post(`${API_URL}/api/v1/payment/pause`,{
        subscription_id:userData?.subscriptionId,
        userId:userData?.userId
      },{withCredentials:true});

      if(!response?.data?.success){
        setToastData({ type: "error", message: response?.data?.message });
        setBtnLoading(false);
        return;
      }

      setToastData({ type: "success", message: response?.data?.message });
      setBtnLoading(false);
      setShowModal({ pause: false });
      setFetch((prev) => prev + 1)

    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({ type: "error", message: errorMessage });
      setBtnLoading(false)
    }
  };

  const handleResumePayment = async () => {
    try {

      if(!userData?.userId || !userData?.subscriptionId){
        setToastData({ type: "error", message: "User and subscription details are required." });
        return;
      }

      setBtnLoading(true)
      const response = await axios.post(`${API_URL}/api/v1/payment/resume`,{
        subscription_id:userData?.subscriptionId,
        userId:userData?.userId
      },{withCredentials:true});

      if(!response?.data?.success){
        setToastData({ type: "error", message: response?.data?.message });
        setBtnLoading(false);
        return;
      }

      setToastData({ type: "success", message: response?.data?.message });
      setBtnLoading(false);
      setShowModal({ pause: false });
      setFetch((prev) => prev + 1)

    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({ type: "error", message: errorMessage });
      setBtnLoading(false)
    }
  };

  const handleCancelPayment = async () => {
    try {

      if(!userData?.userId || !userData?.subscriptionId){
        setToastData({ type: "error", message: "User and subscription details are required." });
        return;
      }

      setBtnLoading(true)
      const response = await axios.post(`${API_URL}/api/v1/payment/cancel`,{
        subscription_id:userData?.subscriptionId,
        userId:userData?.userId
      },{withCredentials:true});

      if(!response?.data?.success){
        setToastData({ type: "error", message: response?.data?.message });
        setBtnLoading(false);
        return;
      }

      setToastData({ type: "success", message: response?.data?.message });
      setBtnLoading(false);
      setShowModal({ pause: false })
      setFetch((prev) => prev + 1)

    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({ type: "error", message: errorMessage });
      setBtnLoading(false)
    }
  };

  const renderActions = (rowData) => (
    <div className="flex items-center justify-center space-x-3">
        <>
          {/* Pause Action */}
          <button
            className="px-4 py-1 text-white bg-yellow-500 rounded-full hover:bg-yellow-600"
            onClick={() => {
              setUserData({
                userName:rowData?.userName,
                userId:rowData?.userId,
                subscriptionId:rowData?.subscriptionId
              })
              setShowModal((prev) => ({
                ...prev,
                pause:true
              }))
            }}
          >
            Pause
          </button>

          {/* Resume Action */}
          <button
            className="px-4 py-1 text-white bg-green-500 rounded-full hover:bg-green-600"
            onClick={() => {
              setUserData({
                userName:rowData?.userName,
                userId:rowData?.userId,
                subscriptionId:rowData?.subscriptionId
              })
              setShowModal((prev) => ({
                ...prev,
                resume:true
              }))
            }}          >
            Resume
          </button>

          {/* Cancel Action */}
          <button
            className="px-4 py-1 text-white bg-red-500 rounded-full hover:bg-red-600"
            onClick={() => {
              setUserData({
                userName:rowData?.userName,
                userId:rowData?.userId,
                subscriptionId:rowData?.subscriptionId
              })
              setShowModal((prev) => ({
                ...prev,
                cancel:true
              }))
            }}          >
            Cancel
          </button>
        </>
    </div>
  );

  const headings = [
    { dataKey: "count", title: "Count" },
    { dataKey: "email", title: "Email Address" },
    // { dataKey: "userId", title: "User ID" },
    { dataKey: "userName", title: "User Name" },
    { dataKey: "phone", title: "Phone Number" },
    { dataKey: "status", title: "Subscription Status" },
    { dataKey: "productName", title: "Product Name" },
    { dataKey: "subscriptionId", title: "Subscription ID" },
    { dataKey: "totalCount", title: "Total Count" },
    { dataKey: "paidCount", title: "Paid Count" },
    // { dataKey: "razorpayDetails.eventType", title: "Event Type" },
    { dataKey: "razorpayDetails.invoiceURL", title: "Invoice URL" },
    { dataKey: "razorpayDetails.currentEnd", title: "Current End" },
    { dataKey: "razorpayDetails.eventTimestamp", title: "Event Timestamp" },
    { dataKey: "razorpayDetails.amount", title: "Amount" },
    { dataKey: "razorpayDetails.chargeAt", title: "Charge At" },
    { dataKey: "createdAt", title: "Created At" },
    { dataKey: "updatedAt", title: "Updated At" },
    { title: "Actions", dataKey: "id", render: renderActions },
  ];

  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={isLoading}
        data={paymentDetails}
        itemsPerPage={10}
        headings={headings}
        hovered
        setPage={setPage}
      />

      <ReusableModal
        isOpen={showModal?.pause}
        onClose={() => setShowModal((prev) => ({
          ...prev,
          pause: false,
        }))}
        title="Are you sure ?"
        comments={`Are you sure you want to pause ${userData?.userName}'s subscription.`}
      >
        <div className="flex justify-end space-x-4">
          <CButton
            loading={btnLoading}
            variant={ButtonType.Primary}
            onClick={handlePausePayment}
          >
            Yes
          </CButton>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={showModal?.resume}
        onClose={() => setShowModal((prev) => ({
          ...prev,
          resume: false,
        }))}
        title="Are you sure ?"
        comments={`Are you sure you want to resume ${userData?.userName}'s subscription.`}
      >
        <div className="flex justify-end space-x-4">
          <CButton
            loading={btnLoading}
            variant={ButtonType.Primary}
            onClick={handleResumePayment}
          >
            Yes
          </CButton>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={showModal?.cancel}
        onClose={() => setShowModal((prev) => ({
          ...prev,
          cancel: false,
        }))}
        title="Are you sure ?"
        comments={`Are you sure you want to cancel ${userData?.userName}'s subscription.`}
      >
        <div className="flex justify-end space-x-4">
          <CButton
            loading={btnLoading}
            variant={ButtonType.Primary}
            onClick={handleCancelPayment}
          >
            Yes
          </CButton>
        </div>
      </ReusableModal>


    </div>
  );
};

export default PaymentDetails;
