import React, { useState, useEffect, useRef } from "react";
import { ReactComponent as DeleteIcon } from "../image/icons/delsolid.svg";
import { API_URL } from "../apiConfig";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { BsX, BsRepeat, BsPlus, BsCheckAll } from "react-icons/bs";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import AlertComponent from "./AlertComponent";
import spinner from "../image/spinner.svg";
import star from "../image/shines.png";
import { useNavigate } from "react-router-dom";
import planExpire from "../image/plan_expired.png";
import axios from "axios";
import { toast } from "react-toastify";
import userStore from "../store/user.zustand.js";

const DynamicExperienceInputs = ({
  inputs,
  setInputs,
  setDataSaved,
  aiToken,
  setAiToken,
  resumeId,
}) => {
  const navigate = useNavigate();
  const {user,setUser, setAuthenticated} = userStore();
  // const {setAdmin,isAuthenticated,setAuthenticated} = useAuthStore();

  const [loading, setLoading] = useState(false);
  const [tokenZero, setTokenZero] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const [showAssistBox, setShowAssistBox] = useState(false);
  const [selectedOption, setSelectedOption] = useState("generate");
  const [inputValue, setInputValue] = useState("");
  const [durationValue, setdurationValue] = useState("");
  const [aiContentList, setaiContentList] = useState([]);
  const [checkedItems, setCheckedItems] = useState([]);
  const assistBoxRef = useRef(null);
  const [showAlert, setShowAlert] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(null);

  // State to manage accordion behavior
  const [accordionIndex, setAccordionIndex] = useState(0);

  const [aIAssistValidation, setAIAssistValidation] = useState({
    jobRole: "",
    fromDate: "",
    toDate: "",
  });

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/me`, {
        withCredentials: true,
      });

      if (response?.data?.success) {
        setUser(response?.data?.data)
        setAuthenticated(true)
        // userStore
        //   .getState()
        //   .setUser({ user: response?.data?.data, isAuthenticated: true });
      } else {
        if (
          response?.data?.message === "Unauthorized" ||
          response?.data?.message === "User not found"
        ) {
          // redirect to login
          navigate("/login", { replace: true });
        }
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
      return;
    } catch (error) {
      toast.error(error?.response?.data?.message, {
        autoClose: 2500,
      });
      navigate("/login", { replace: true });
      console.log(error);
      return;
    }
  };

  useEffect(() => {
    if (user?.tokenDetails?.tokens <= 0) {
      setTokenZero(true);
    } else {
      setTokenZero(false);
    }
  }, [user?.tokenDetails?.tokens]);

  const handleChange = (index, event) => {
    if (event?.target?.name === "jobRole") {
      setAIAssistValidation((prev) => ({
        ...prev,
        [event.target.name]: "",
      }));
    }
    const updatedInputs = [...inputs];

    if (event.target.name === "currExp") {
      // If the checkbox is checked, set the value of toDate to 'Present'
      updatedInputs[index]["toDate"] = event.target.checked ? "Present" : "";
    } else {
      // updatedInputs[index][event.target.name] = event.target.value;
      updatedInputs[index][event.target.name] = event.target.value;
    }
    setInputs(updatedInputs);
    setDataSaved(false);
  };

  const handleAddExperience = () => {
    const newInput = {
      jobRole: "",
      companyName: "",
      companyLocation: "",
      fromDate: "",
      toDate: "",
      jobDescription: "",
    };
    setInputs([...inputs, newInput]);
    setAccordionIndex(inputs.length);
  };

  const handleRemoveExperience = (index) => {
    setIndexToRemove(index);
    setShowAlert(true);
  };

  const confirmRemoveExperience = () => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(indexToRemove, 1);
    setInputs(updatedInputs);
    setShowAlert(false);

    // If the removed accordion is the one that's open, update the accordionIndex
    if (indexToRemove === accordionIndex) {
      setAccordionIndex(updatedInputs.length > 0 ? 0 : null);
    }
  };

  const cancelRemoveExperience = () => {
    setShowAlert(false);
  };

  const editorConfiguration = {
    toolbar: {
      items: [
        "bold",
        "italic",
        "underline",
        "strikethrough",
        "|",
        "link",
        "|",
        "alignment",
        "|",
        "bulletedList",
        "numberedList",
      ],
    },
  };

  const handleCKEditorChange = (index, data) => {
    const updatedInputs = [...inputs];
    updatedInputs[index]["jobDescription"] = data;
    // console.log({data})
    setInputs(updatedInputs);
  };

  const renderJobDescription = (input, index) => {
    let jobDescriptionHTML;
    if (Array.isArray(input.jobDescription)) {
      jobDescriptionHTML = input.jobDescription
        .map((responsibility) => <li>${responsibility}</li>)
        .join("");
      jobDescriptionHTML = <ul>${jobDescriptionHTML}</ul>;
      // console.log({jobDescriptionHTML})
      return (
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={jobDescriptionHTML}
          onChange={(event, editor) =>
            handleCKEditorChange(index, editor.getData())
          }
        />
      );
    } else {
      return (
        <CKEditor
          editor={ClassicEditor}
          config={editorConfiguration}
          data={input.jobDescription}
          onChange={(event, editor) =>
            handleCKEditorChange(index, editor.getData())
          }
        />
      );
    }
  };

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    if (accordionIndex === index && inputs.length > 1) {
      setAccordionIndex(null);
    } else {
      setAccordionIndex(index);
    }
  };

  const handleAiassist = (jobRole) => {
    console.log({ jobRole });
    if (!jobRole) {
      setAIAssistValidation((prev) => ({
        ...prev,
        jobRole: "Job Title is Required",
      }));
      return;
    }
    setShowAssistBox(true);
    if (showAssistBox) {
      setCheckedItems([]); // Reset checked items when closing the AI Assist box
    }
    setaiContentList(null);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (
        assistBoxRef.current &&
        !assistBoxRef.current.contains(event.target)
      ) {
        setShowAssistBox(false);
        setCheckedItems([]);
        setaiContentList(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [assistBoxRef]);

  // parse date
  const calculateDurationInMonths = (fromYear, toYear) => {
    // Get the current date
    const currentDate = new Date();

    // Parse 'fromYear' to get the year and month
    const [fromYearValue, fromMonthValue] = fromYear.split("-").map(Number);

    let toYearValue, toMonthValue;

    // If 'toDate' is set to 'Present', use the current date
    if (toYear === "Present") {
      toYearValue = currentDate.getFullYear();
      toMonthValue = currentDate.getMonth() + 1; // Months are 0-indexed
    } else {
      // Otherwise, parse 'toYear' to get the year and month
      [toYearValue, toMonthValue] = toYear.split("-").map(Number);
    }

    // Create Date objects for comparison
    const fromDate = new Date(fromYearValue, fromMonthValue - 1);
    const toDate = new Date(toYearValue, toMonthValue - 1);

    if (toDate < fromDate) {
      return "1 months";
    }

    // Calculate the total number of months
    const totalMonths =
      (toYearValue - fromYearValue) * 12 + (toMonthValue - fromMonthValue);

    // Calculate the years and remaining months
    const years = Math.floor(totalMonths / 12);
    const remainingMonths = totalMonths % 12;

    let durationString = "";

    if (years > 0) {
      durationString += `${years} year${years > 1 ? "s" : ""} `;
    }

    if (remainingMonths > 0) {
      durationString += `${remainingMonths} month${
        remainingMonths > 1 ? "s" : ""
      }`;
    }

    return durationString.trim();
  };

  // generate summary
  const handleSubmit = async (
    event,
    jobRole,
    fromDate,
    toDate,
    jobDescription
  ) => {
    event.preventDefault();
    if (user?.tokenDetails?.tokens <= 0) {
      setTokenZero(true);
      return;
    }
    try {
      const duration = calculateDurationInMonths(fromDate, toDate);
      setButtonDisabled(true);
      setLoading(true);
      setaiContentList(null);

      const response = await axios.post(
        `${API_URL}/api/v1/generate-summary/work-experience`,
        {
          resumeId: resumeId,
          mode: selectedOption,
          jobPosition: jobRole,
          duration: duration || "1 months",
          contexts: jobDescription,
        },
        {
          withCredentials: true,
        }
      );

      if (!response?.data?.success) {
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      } else if (response?.status === 403) {
        setTokenZero(true);
        return;
      } else {
        setLoading(false);
        setaiContentList(response?.data?.data?.output);
        await fetchUser();
      }
    } catch (error) {
      console.log(error);
      setLoading(false);
      console.error("Error:retry", error);
    }
    setButtonDisabled(false);
  };

  const handleSelectAll = () => {
    if (aiContentList) {
      setCheckedItems(aiContentList);
    }
  };

  const handleAddCheckedItems = (index) => {
    const updatedInputs = [...inputs];
    const currentInput = updatedInputs[accordionIndex];
    let jobDescription = currentInput.jobDescription;
    jobDescription += `\n<ul>${checkedItems
      .map((item) => `<li>${item}</li>`)
      .join("")}</ul>`;

    updatedInputs[accordionIndex] = {
      ...currentInput,
      jobDescription,
    };
    // console.log("called 1")
    setInputs(updatedInputs);
  };

  const handleReplaceCheckedItems = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs[accordionIndex].jobDescription = `<ul>${checkedItems
        .map((item) => `<li>${item}</li>`)
        .join("")}</ul>`;
    // console.log("called 2")
    setInputs(updatedInputs);
  };

  const handleCheckboxChange = (event) => {
    const { name, checked } = event.target;
    if (checked) {
      setCheckedItems([...checkedItems, name]);
    } else {
      setCheckedItems(checkedItems.filter((item) => item !== name));
    }
  };

  const handleCancel = () => {
    setShowAssistBox(false);
  };

  const handleUpgrade = () => {
    navigate("/pricing");
  };

  const handleInputChange = (event, index) => {
    const { value } = event.target;
    setInputs((prevInputs) => {
      const newExperience = [...prevInputs];
      newExperience[index] = {
        ...newExperience[index],
        jobRole: value,
      };
      return newExperience;
    });
  };
  return (
    <div>
      {inputs &&
        inputs.map((input, index) => (
          <div
            key={index}
            className="px-3 py-2 m-3 bg-gray-100 border-gray-300 rounded-lg cursor-pointer accordion-card border-1"
          >
            <div
              onClick={() => toggleAccordion(index)}
              className="flex items-center justify-between text-lg text-gray-600"
            >
              {input.jobRole === ""
                ? `Experience ${index + 1}`
                : input.jobRole.length > 20
                ? `${input.jobRole.slice(0, 20)}...`
                : input.jobRole}
              {inputs.length > 1 && (
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleRemoveExperience(index);
                  }}
                  className="text-white "
                >
                  <DeleteIcon
                    className="w-5 h-5 hover:fill-red-600"
                    data-tooltip-id="my-tooltip"
                    data-tooltip-content="Delete"
                    data-tooltip-place="right-end"
                  />
                </button>
              )}
            </div>
            <AlertComponent
              show={showAlert}
              onConfirm={confirmRemoveExperience}
              onCancel={cancelRemoveExperience}
            />
            {accordionIndex === index && (
              <div className="text-sm text-black">
                <div className="flex flex-wrap">
                  <div className="w-full mb-2 md:w-1/2 md:pr-2">
                    <label htmlFor="">Job Title</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Job role"
                      name="jobRole"
                      value={input.jobRole}
                      onChange={(event) => handleChange(index, event)}
                      className="w-full p-2 border rounded-xl"
                      onClick={(event) => event.stopPropagation()}
                    />
                    <p className="mt-2 text-xs text-red-500">
                      {aIAssistValidation?.jobRole && (
                        <span className="">{aIAssistValidation?.jobRole}</span>
                      )}
                    </p>
                  </div>
                  <div className="w-full mb-2 md:w-1/2 md:pl-2">
                    <label htmlFor="">Company Name</label>
                    <br />
                    <input
                      type="text"
                      placeholder="Company name"
                      name="companyName"
                      value={input.companyName}
                      onChange={(event) => handleChange(index, event)}
                      className="w-full p-2 border rounded-xl"
                      onClick={(event) => event.stopPropagation()}
                    />
                  </div>
                </div>
                <div className="flex flex-wrap">
                  <div className="w-full mb-2 md:w-1/2 md:pr-2">
                    <label htmlFor="">Company Location</label>
                    <br />
                    <input
                      type="text"
                      name="companyLocation"
                      value={input.companyLocation}
                      onChange={(event) => handleChange(index, event)}
                      placeholder="Job location"
                      className="w-full p-2 border rounded-xl"
                      onClick={(event) => event.stopPropagation()}
                    />
                  </div>
                  <div className="w-full mb-2 md:w-1/2 md:pl-2">
                    <label htmlFor="">Duration</label>
                    <br />
                    <div className="flex flex-col gap-1">
                      <input
                        type="month"
                        name="fromDate"
                        value={
                          input.fromDate  || ""
                        }
                        onChange={(event) => handleChange(index, event)}
                        placeholder="From Year"
                        className="w-full p-2 border rounded-xl"
                        onClick={(event) => event.stopPropagation()}
                      />
                      {input?.toDate === "Present" ? (
                        <input
                          type="text"
                          name="toDate"
                          value="Present"
                          readOnly
                          className="w-full p-2 bg-gray-200 border rounded-xl"
                          onClick={(event) => event.stopPropagation()}
                        />
                      ) : (
                        <input
                          type="month"
                          name="toDate"
                          value={input?.toDate || ""}
                          onChange={(event) => handleChange(index, event)}
                          placeholder="To Year"
                          className="w-full p-2 border rounded-xl"
                          onClick={(event) => event.stopPropagation()}
                        />
                      )}
                    </div>
                    <label htmlFor="currExp" className="ml-1">
                      <input
                        type="checkbox"
                        name="currExp"
                        id="currExp"
                        className="mr-2 "
                        checked={input?.toDate === "Present"}
                        onChange={(event) => handleChange(index, event)}
                      />
                      I currently work here
                    </label>
                    <br />
                  </div>
                </div>
                <div className="mt-[-10px]">
                  <label>Description</label>
                  <br />
                  <div className="bg-white rounded-sm">
                    {renderJobDescription(input, index)}
                    <div className="flex relative justify-end w-full border-r-[1px] border-l-[1px] border-b-[1px] rounded-b-sm text-sm border-[var(--ck-color-base-border)]">
                      <button
                        className="flex flex-wrap items-center px-2 py-1 m-2 text-white bg-blue-500 rounded-md highlight_bg hover:bg-blue-700"
                        onClick={() => handleAiassist(input?.jobRole)}
                      >
                        <img src={star} className="w-4 mr-1" alt="" />
                        AI Assist
                      </button>
                      {showAssistBox && (
                        <div ref={assistBoxRef} className="assist_main_box">
                          {tokenZero ? (
                            <div className="flex flex-col items-center justify-center mt-3">
                              <img
                                src={planExpire}
                                className="w-[20%]"
                                alt=""
                              />
                              <p className="text-2xl font-semibold">
                                Token Limit exceeded, No active plan.
                              </p>
                              <p className="mx-4 my-2 text-base text-center">
                                Activate a plan to use AI Assist.
                              </p>
                              <div className="flex w-full gap-2 px-8 mx-4 mt-4">
                                <button
                                  className="flex-1 py-2 text-sm font-semibold border-black rounded-md h-fit border-1"
                                  onClick={handleCancel}
                                >
                                  Cancel
                                </button>
                                <button
                                  className="flex-1 py-2 text-sm font-semibold text-white rounded-md h-fit highlight_bg border-1"
                                  onClick={handleUpgrade}
                                >
                                  Upgrade Plan
                                </button>
                              </div>
                            </div>
                          ) : (
                            <>
                              <div className="flex mx-4 flex-wrap items-center mt-2 mb-1 h-fit text-gray-500 text-[15px]">
                                {selectedOption === "rewrite" && (
                                  <label className="flex-1 h-full">
                                    Please write something in description to
                                    rewrite with AI.
                                  </label>
                                )}
                                {selectedOption === "generate" && (
                                  <label className="flex-1 h-fit">
                                    Please enter job title and duration to
                                    generate with AI.
                                  </label>
                                )}
                                <BsX
                                  className="flex-none text-3xl font-semibold text-black h-fit"
                                  onClick={() => handleAiassist(input?.jobRole)}
                                />
                              </div>
                              <hr className="mx-4 text-gray-400" />
                              <form
                                onSubmit={(event) =>
                                  handleSubmit(
                                    event,
                                    input.jobRole,
                                    input.fromDate,
                                    input.toDate,
                                    input.jobDescription
                                  )
                                }
                                className="ai_assist_form"
                              >
                                <div className="flex-none h-fit">
                                  <select
                                    id="options"
                                    value={selectedOption}
                                    onChange={(event) =>
                                      setSelectedOption(event.target.value)
                                    }
                                  >
                                    <option value="generate">Generate</option>
                                    <option value="rewrite">Rewirte</option>
                                  </select>
                                </div>
                                <div className="flex-1 mx-1 h-fit">
                                  <input
                                    type="text"
                                    id="input"
                                    className="w-full border-none"
                                    placeholder="call 2"
                                    value={input.jobRole}
                                    // value={inputValue}
                                    // onChange={(event) => {

                                    //   console.log(event)
                                    //   setInputValue(event?.target.value)
                                    // setInputValue(input.jobRole)
                                    // }
                                    // onChange={(event) => {
                                    //   const { value } = event.target;
                                    //   setInputs((prev) => {
                                    //     let newExperience = [...prev.experience];
                                    //     // const index = newExperience.findIndex((exp) => exp.jobRole === inputs.jobRole);

                                    //     newExperience.jobRole = value;
                                    //     return {
                                    //       ...prev,
                                    //       experience: {
                                    //         ...prev?.experience,
                                    //         jobRole:value
                                    //         },
                                    //     };
                                    //   });
                                    // }}
                                    onChange={(event) =>
                                      // handleInputChange(event, index)
                                      setInputValue(input.jobRole)
                                    }
                                    // setInputs((prev) => ({
                                    //   ...prev,
                                    //   experience: {
                                    //     ...prev?.experience,
                                    //     jobRole:event.target.value
                                    // }))

                                    // }
                                  />
                                  <input
                                    type="hidden"
                                    id="input"
                                    className="w-full border-none"
                                    value={`${input?.fromDate} - ${input?.toDate}`}
                                    onChange={(event) =>
                                      setdurationValue(
                                        `${input?.fromDate} - ${input?.toDate}`
                                      )
                                    }
                                  />
                                </div>
                                <button
                                  className="flex-none px-2 text-white rounded-md h-fit"
                                  disabled={buttonDisabled}
                                  type="submit"
                                >
                                  <img
                                    loading="lazy"
                                    src="https://cdn.builder.io/api/v1/image/assets/TEMP/395eff768bf5008660a50c05ecbf846f9939e43ba67ae2e3117c057669e9f3dc?apiKey=e64c88474a2c4290b8b66f50b4a39c22&"
                                    alt="Software Developer Badge Icon"
                                    className="aspect-square w-[20px]"
                                  />
                                </button>
                              </form>
                              <div className="flex flex-col flex-wrap mx-4">
                                {aiContentList && (
                                  <div className="flex gap-2">
                                    <button
                                      className="flex flex-wrap items-center p-1 bg-gray-200 border-gray-600 rounded-md border-1"
                                      onClick={handleSelectAll}
                                    >
                                      <BsCheckAll />
                                      Select all
                                    </button>
                                    <button
                                      className="flex flex-wrap items-center p-1 bg-gray-200 border-gray-600 rounded-md border-1"
                                      onClick={() =>
                                        handleAddCheckedItems(index)
                                      }
                                    >
                                      <BsPlus />
                                      Add
                                    </button>
                                    <button
                                      className="flex bg-gray-200 border-1 border-gray-600 rounded-md flex-wrap items-center p-1 gap-[2px]"
                                      onClick={() =>
                                        handleReplaceCheckedItems(index)
                                      }
                                    >
                                      <BsRepeat />
                                      Replace
                                    </button>
                                  </div>
                                )}
                                {aiContentList ? (
                                  aiContentList.map((option, index) => (
                                    <div
                                      key={index}
                                      className="flex items-center mb-2"
                                    >
                                      <input
                                        type="checkbox"
                                        name={option}
                                        checked={checkedItems.includes(option)}
                                        onChange={handleCheckboxChange}
                                      />
                                      <label className="ml-2">{option}</label>
                                    </div>
                                  ))
                                ) : (
                                  <div className="flex justify-center mt-6">
                                    {loading ? (
                                      <img
                                        loading="lazy"
                                        src={spinner}
                                        alt="spinner"
                                        className="w-8 h-8 opacity-50 animate-spin max-md:w-6 max-md:h-6"
                                      />
                                    ) : (
                                      <span>No data available</span>
                                    )}
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        ))}
      <div
        onClick={handleAddExperience}
        className="flex justify-center p-2 m-3 text-lg font-semibold text-white delay-700 bg-orange-500 cursor-pointer rounded-xl"
      >
        + Add Experience
      </div>
    </div>
  );
};

export default DynamicExperienceInputs;
