import React, { useEffect } from "react";
import "./Template4.css";
// import html2pdf from 'html2pdf.js';

const Template4 = ({ formData }) => {
  useEffect(() => {
    const updateHeight = () => {
      const container = document.querySelector(".template-container");
      const containerWidth = container.offsetWidth;
      const containerHeight = containerWidth * 1.41421; // A4 paper width-to-height ratio
      container.style.minHeight = `${containerHeight}px`;
    };

    const onClick =() =>{
      const container = document.querySelector(".template-container");
      container.scrollIntoView(".template-container", {behavior: 'smooth', block: 'start'});

    }

    // Call updateHeight initially and add event listener for window resize
    updateHeight();
    window.addEventListener("resize", updateHeight);
    window.addEventListener("onclick", onClick);

    // Cleanup function to remove event listener
    return () => {
      window.removeEventListener("resize", updateHeight);
      window.removeEventListener("onclick", onClick);
    };
  }, []);
  return (
    <div className="template-container">
      <div className="resume-container">
        {formData.Details &&
          formData?.Details.map((detail, index) => (
            <header key={index}>
              <div className="name-title">
                <h1>{detail.fullName}</h1>
                {/* </div>
                    <div key={index} className="contact-info"> */}
                <p>
                  Email: {detail.email} | Phone:{" "}
                  {detail.phone && ` | ${detail.phone}`} | {detail.website}
                  {detail.linkedin && ` | ${detail.linkedin}`}
                  {detail.github && ` | ${detail.github}`}
                </p>
              </div>
            </header>
          ))}
        {formData.Details &&
          formData?.Details.map((detail, index) => (
            <section className="summary" key={index}>
              <h2>Professional Summary</h2>
              <p dangerouslySetInnerHTML={{ __html: detail.about }} />
              <br></br>
            </section>
          ))}

        {formData.workFlag && (
          <section className="experience">
            <h2>Work Experience</h2>
            {formData?.experience &&
              formData?.experience.map((exp, index) => (
                <div key={index} className="job">
                  <h3>{exp.jobRole}</h3>
                  <h4>
                    {exp.companyName} | {exp.fromDate} - {exp.toDate} |{" "}
                    {exp.companyLocation}
                  </h4>
                  <p dangerouslySetInnerHTML={{ __html: exp.jobDescription }}/>
                  {/* <p>{exp.jobDescription}</p> */}
                </div>
              ))}
          </section>
        )}
        {formData.eduFlag && (
          <section className="education">
            <h2>Education</h2>
            {formData?.education &&
              formData?.education.map((edu, index) => (
                <div key={index} className="section-content">
                  <h3>{edu.degree}</h3>
                  <h4>
                    {edu.college} - {edu.location} | Graduated: {edu.fromDate} -{" "}
                    {edu.toDate}
                  </h4>
                </div>
              ))}
          </section>
        )}

        {formData.skillFlag && (
          <section className="skills">
            <h2>Skills</h2>
            {formData?.skills &&
              formData?.skills.map((skill, index) => (
                <ul key={index} className="section-content">
                  <li>
                    <span className="bold-text">{skill.title}</span> -{" "}
                    {skill.skillList}
                  </li>
                </ul>
              ))}
          </section>
        )}
        {formData.proFlag && (
          <section className="experience">
            {/* {formData.projects.length !== 0 ? ( */}
            <h2>Project Developed Or Handled</h2>
            {/* ) : null} */}
            {formData?.projects &&
              formData?.projects.map((prodit, index) => (
                <div key={index} className="section-content">
                  <h3 className="bold-text">
                    {prodit.title} {prodit?.title && prodit?.stack ? "-" : ""}{" "}
                    {prodit.stack}
                  </h3>
                  <p dangerouslySetInnerHTML={{ __html: prodit.desc }} />
                </div>
              ))}
          </section>
        )}
        {formData.achFlag && (
          <section className="experience">
            {/* {formData.achivawards.length !== 0 ? ( */}
            <h2>Achievements & Awards</h2>
            {/* ) : null} */}
            {formData?.achivawards &&
              formData?.achivawards.map((ach, index) => (
                <div key={index} className="section-content">
                  <p>{ach.achivawards}</p>
                </div>
              ))}
          </section>
        )}
        {formData.addFlag && (
          <section className="experience">
            <h2>Additional Information</h2>
            {formData?.additionalInfo &&
              formData?.additionalInfo.map((addinfo, index) => (
                <div key={index} className="section-content">
                  <p>{addinfo.addInfo}</p>
                </div>
              ))}
          </section>
        )}
      </div>
    </div>
  );
};

export default Template4;
