import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import RoopTable from "../../common/customTableR/table";
import { API_URL } from "../../../apiConfig";
import CustomSwitch from "../../common/customSwitch/customSwitch";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";
import { FaRegEdit } from "react-icons/fa";

export const PricingName = {
  ProMonthly: "PRO MONTHLY",
  Free: "FREE",
  ProQuarterly: "PRO QUARTERLY",
  ProYearly: "PRO YEARLY",
  Custom: "CUSTOM",
};

export const PlanSubText = {
  FREE: "FREE plan",
  MONTHLY: "monthly plan",
  QUARTERLY: "quaterly plan",
  YEARLY: "yearly plan",
};

export const PlanType = {
  Basic: "Basic",
  Pro: "Pro",
};

export const DurationSuffix = {
  Monthly: "monthly",
  Yearly: "yearly",
  Weekly: "weekly",
  Free: "Free",
};

const Plans = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectPlanId, setSelectedPlanId] = useState("");
  const [counter, setCounter] = useState(0);

  const [plans, setPlans] = useState([]);

  const [btnLoading, setBtnLoading] = useState(false);

  const [formData, setFormData] = useState({
    planName: "",
    subText: "",
    sale: 0,
    price: 0,
    durationSufix: DurationSuffix.Monthly,
    extraGoodies: [],
    intervals: 1,
    description: [],
    tokens: 0,
  });

  const [showCreatePlanModal, setShowCreatePlanModal] = useState(false);
  const [showUpdatePlanModal, setShowUpdatePlanModal] = useState(false);

  const [updatePlan, setUpdatePlan] = useState({
    planName: "",
    subText: "",
    sale: 0,
    extraGoodies: [],
    intervals: 1,
    description: [],
    tokens: 0,
  });

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    // onSubmit(formData);
    handleCratePlan();
  };

  const handleSubmitUpdatePlan = async () => {
    try {
      const response = await axios.put(
        `${API_URL}/api/v1/plan/update-plan`,
        {
          ...updatePlan,
          planId: selectPlanId,
        },
        { withCredentials: true }
      );
      if (response?.data?.success) {
        setToastData({
          type: "success",
          message: "Plan updated successfully",
        });
        setShowUpdatePlanModal(false);
        setCounter((prev) => prev + 1);
        setUpdatePlan({
          planName: "",
          subText: "",
          sale: 0,
          extraGoodies: [],
          intervals: 1,
          description: [],
          tokens: 0,
        });
        setSelectedPlanId("");
        return;
      } else {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        return;
      }
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  // Handle form submission
  const handleUpdatePlan = (e) => {
    e.preventDefault();
    // onSubmit(formData);
    // handleCratePlan();
    handleSubmitUpdatePlan();
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleUpdatePlanChange = (e) => {
    const { name, value, type, checked } = e.target;
    setUpdatePlan((prev) => ({
      ...prev,
      [name]: type === "checkbox" ? checked : value,
    }));
  };

  const handleArrayInput = (e, fieldName) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      setFormData((prev) => ({
        ...prev,
        [fieldName]: [...prev[fieldName], e.target.value.trim()],
      }));
      e.target.value = ""; // Clear input after adding
    }
  };

  const handleUpdatePlanArrayInput = (e, fieldName) => {
    if (e.key === "Enter" && e.target.value.trim() !== "") {
      e.preventDefault();
      setUpdatePlan((prev) => ({
        ...prev,
        [fieldName]: [...prev[fieldName], e.target.value.trim()],
      }));
      e.target.value = ""; // Clear input after adding
    }
  };

  // Handle removing items from array fields
  const removeItem = (fieldName, index) => {
    setFormData((prev) => ({
      ...prev,
      [fieldName]: prev[fieldName].filter((_, i) => i !== index),
    }));
  };

  const removeUpdatePlanItem = (fieldName, index) => {
    setUpdatePlan((prev) => ({
      ...prev,
      [fieldName]: prev[fieldName].filter((_, i) => i !== index),
    }));
  };

  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/v1/admin/plans`, {
          withCredentials: true,
        });
        let count = 1;
        // Map the fetched payment details properly to the users' state
        setPlans(
          response?.data?.data.map((el) => {
            return {
              count: count++,
              planId: el?.planId,
              planName: el?.planName ?? "",
              subText: el?.planSubtext ?? "",
              description: el?.planDescription?.join(", ") ?? "",
              extraGoodies: el?.extraGoodies.join(", ") ?? "",
              price: el?.planPrice ?? null,
              sale: el?.planSale ?? null,
              duration: el?.planDuration ?? null,
              active: el?.active,
              type: el?.planType ?? "",
              durationSufix: el?.durationSufix ?? "",
              tokens: el?.tokens ?? "",
              rzPlanId: el?.rpPlanId ?? "",
              intervals: el?.intervals ?? null,
              isSubscribed: `${el?.isSubscribed}`,
              updatedAt: formatDate(el?.updatedAt) ?? null,
              createdAt: formatDate(el?.createdAt) ?? null,
              createdBy: el?.createdBy || "",
              updatedBy: el?.updatedBy || "",
            };
          })
        );
      } catch (error) {
        console.error("Error fetching payment details:", error);
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [counter, setLoading]);

  const handleToggleSwitch = (rowData) => {
    setShowConfirmationModal(true);
    setSelectedPlanId(rowData?.planId);
  };

  const handleCratePlan = async () => {
    try {
      // input validation check
      if (!formData.planName || !formData.subText) {
        setToastData({
          type: "error",
          message: "All fields are required",
        });
        return;
      }

      if (formData?.price < 0 || formData?.sale < 0) {
        setToastData({
          type: "error",
          message: "Price or sale price should be greater than zero",
        });
        return;
      }

      if ((formData?.description ?? []).length === 0) {
        setToastData({
          type: "error",
          message: "Description field is required",
        });
        return;
      }

      if (formData?.tokens < 0) {
        setToastData({
          type: "error",
          message: "Tokens cannot be negative",
        });
        return;
      }

      setLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/plan/create-plan`,
        formData,
        {
          withCredentials: true,
        }
      );

      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        setLoading(false);
        return;
      }

      setLoading(false);
      setToastData({
        type: "success",
        message: response?.data?.message,
      });
      setShowCreatePlanModal(false);
      setCounter((prev) => prev + 1);
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  const handleEditPlan = async (id) => {
    try {
      if (!id) {
        setToastData({
          type: "error",
          message: "Plan ID is required",
        });
        return;
      }

      // check plan present or not
      if (!plans?.find((el) => el?.planId === id)) {
        setToastData({
          type: "error",
          message: "Plan not found",
        });
        return;
      }
      setSelectedPlanId(id);
      const filterPlan = plans?.filter((el) => el?.planId === id)[0];
      console.log(filterPlan)
      setUpdatePlan((el) => ({
        ...el,
        planName: filterPlan?.planName ?? "",
        subText: filterPlan?.subText ?? "",
        sale: filterPlan?.sale ?? 0,
        extraGoodies: filterPlan?.extraGoodies ? filterPlan?.extraGoodies.split(","):[],
        intervals: filterPlan?.intervals ?? 1,
        description: filterPlan?.description ? filterPlan?.description.split(",") : [],
        tokens: filterPlan?.tokens ?? 0,
      }));

      // show update plan data
      setShowUpdatePlanModal(true)
    } catch (error) {
      console.log(error);
    }
  };

  const renderSwitch = (rowData) => (
    <div>
      <CustomSwitch
        checked={rowData.active}
        onChange={() => handleToggleSwitch(rowData)}
        label={`Toggle switch for ${rowData?.planId}`}
      />
    </div>
  );

  const renderActions = (rowData) => (
    <div className="flex items-center justify-center space-x-3">
      <FaRegEdit
        className="text-red-500 cursor-pointer"
        onClick={() => {
          handleEditPlan(rowData?.planId);
        }}
      />
    </div>
  );

  const headings = [
    { dataKey: "count", title: "Count" },
    { dataKey: "planName", title: "Plan Name" },
    { dataKey: "type", title: "Plan Type" },
    { dataKey: "subText", title: "Sub Text" },
    { title: "Toggle Status", dataKey: "status", render: renderSwitch },
    { dataKey: "sale", title: "Sale Price" },
    { dataKey: "price", title: "Price" },
    { dataKey: "duration", title: "Duration" },
    { dataKey: "durationSufix", title: "Duration Suffix" },
    { dataKey: "extraGoodies", title: "Extra Goodies" },
    { dataKey: "intervals", title: "Intervals" },
    { dataKey: "rzPlanId", title: "Razorpay Plan ID" },
    { dataKey: "description", title: "Description" },
    { dataKey: "tokens", title: "Tokens" },
    { dataKey: "createdAt", title: "Created At" },
    { dataKey: "updatedAt", title: "Updated At" },
    { dataKey: "createdBy", title: "Created By" },
    { dataKey: "updatedBy", title: "Updated By" },
    { title: "Actions", dataKey: "id", render: renderActions },
  ];

  const handleConfirmation = async () => {
    setShowConfirmationModal(false);
    try {
      setBtnLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/plan/change-status`,
        { planId: selectPlanId },
        { withCredentials: true }
      );
      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        return;
      }
      setCounter((prev) => prev + 1);
      setToastData({
        type: "success",
        message: "Status changed successfully",
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setSelectedPlanId("");
  };

  const mainActions = [
    {
      label: "Create Plan",
      onClick: () => setShowCreatePlanModal(true),
    },
  ];

  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={isLoading}
        data={plans}
        itemsPerPage={8}
        headings={headings}
        hovered
        mainActions={mainActions}
      />
      {/* STATUS BLOCK/UNBLOCK */}
      <ReusableModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        title="Are you sure ?"
        comments="By clicking yes the status of selected plan will change."
      >
        <div className="flex justify-end space-x-4">
          <CButton
            loading={btnLoading}
            variant={ButtonType.Primary}
            onClick={handleConfirmation}
          >
            Yes
          </CButton>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={showCreatePlanModal}
        onClose={() => {
          setShowCreatePlanModal(false);
        }}
        title="Add New Plan"
      >
        <div className="max-h-[80vh] overflow-y-auto">
          {" "}
          {/* Set a max height and enable scrolling */}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Duration Suffix */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Duration Suffix
              </label>
              <select
                name="durationSufix"
                value={formData.durationSufix}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              >
                {Object.values(DurationSuffix).map((suffix) => (
                  <option key={suffix} value={suffix}>
                    {suffix}
                  </option>
                ))}
              </select>
            </div>

            {/* Plan Name */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Plan Name
              </label>
              <input
                type="text"
                name="planName"
                value={formData?.planName}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Enter your plan name"
              />
              {/* {formData?.planName === PricingName.Custom ? (
                <>
                  <input
                    type="text"
                    name="planName"
                    value={formData.planName}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                  />
                </>
              ) : (
                <>
                  <select
                    name="planName"
                    value={formData.planName}
                    onChange={handleChange}
                    className="w-full p-2 border rounded"
                  >
                    {Object.values(PricingName).map((name) => (
                      <option key={name} value={name}>
                        {name}
                      </option>
                    ))}
                  </select>
                </>
              )} */}
            </div>

            {/* Plan Type */}
            {/* <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Plan Type
              </label>
              <select
                name="planType"
                value={formData.planType}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              >
                {Object.values(PlanType).map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div> */}

            {/* Sub Text */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Plan Sub Text
              </label>
              <input
                type="text"
                name="subText"
                value={formData?.subText}
                onChange={handleChange}
                className="w-full p-2 border rounded"
                placeholder="Assign Sub Text"
              />
              {/* <select
                name="subText"
                value={formData.subText}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              >
                {Object.values(PlanSubText).map((text) => (
                  <option key={text} value={text}>
                    {text}
                  </option>
                ))}
              </select> */}
            </div>

            {/* Sale */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Sale
              </label>
              <input
                type="number"
                name="sale"
                value={formData.sale}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Intervals */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                intervals
              </label>
              <input
                type="number"
                name="intervals"
                value={formData.intervals}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Price */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Price
              </label>
              <input
                type="number"
                name="price"
                value={formData.price}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Duration */}
            {/* <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Duration
              </label>
              <input
                type="number"
                name="duration"
                value={formData.duration}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div> */}

            {/* Extra Goodies */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Extra Goodies
              </label>
              <input
                type="text"
                onKeyDown={(e) => handleArrayInput(e, "extraGoodies")}
                className="w-full p-2 border rounded"
                placeholder="Press enter to add a goodie"
              />
              <div className="flex flex-wrap gap-2 mt-2 overflow-x-auto">
                {formData.extraGoodies.map((goodie, index) => (
                  <span
                    key={index}
                    className="flex items-center px-3 py-1 space-x-2 text-white bg-blue-500 rounded-full"
                  >
                    {goodie}
                    <button
                      onClick={() => removeItem("extraGoodies", index)}
                      className="px-2 text-xs bg-red-500 rounded-full"
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>

            {/* Description */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                type="text"
                onKeyDown={(e) => handleArrayInput(e, "description")}
                className="w-full p-2 border rounded"
                placeholder="Press enter to add a description"
              />
              <div className="flex flex-wrap gap-2 mt-2 overflow-x-auto">
                {formData.description.map((desc, index) => (
                  <span
                    key={index}
                    className="flex items-center px-3 py-1 space-x-2 text-white bg-blue-500 rounded-full"
                  >
                    {desc}
                    <button
                      onClick={() => removeItem("description", index)}
                      className="px-2 text-xs bg-red-500 rounded-full"
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>
            {/* Tokens */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Tokens
              </label>
              <input
                type="number"
                name="tokens"
                value={formData.tokens}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Active */}
            {/* <div className="flex items-center space-x-2">
              <input
                type="checkbox"
                name="active"
                checked={formData.active}
                onChange={handleChange}
                className="w-4 h-4"
              />
              <label className="text-sm font-medium text-gray-700">
                Active
              </label>
            </div> */}

            {/* Submit Button */}
            <div className="flex justify-end">
              <CButton variant={ButtonType.Primary} type="submit">
                Submit
              </CButton>
            </div>
          </form>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={showUpdatePlanModal}
        onClose={() => {
          setShowUpdatePlanModal(false);
          setUpdatePlan({
            planName: "",
            subText: "",
            sale: 0,
            extraGoodies: [],
            intervals: 1,
            description: [],
            tokens: 0,
          });
          setSelectedPlanId("");
        }}
        title="Update Plan"
      >
        <div className="max-h-[80vh] overflow-y-auto">
          {" "}
          {/* Set a max height and enable scrolling */}
          <form onSubmit={handleUpdatePlan} className="space-y-4">
            {/* Plan Name */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Plan Name
              </label>
              <input
                type="text"
                name="planName"
                value={updatePlan?.planName}
                onChange={handleUpdatePlanChange}
                className="w-full p-2 border rounded"
                placeholder="Enter your plan name"
              />
            </div>

            {/* Sub Text */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Plan Sub Text
              </label>
              <input
                type="text"
                name="subText"
                value={updatePlan?.subText}
                onChange={handleUpdatePlanChange}
                className="w-full p-2 border rounded"
                placeholder="Assign Sub Text"
              />
            </div>

            {/* Sale */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Sale
              </label>
              <input
                type="number"
                name="sale"
                value={updatePlan?.sale}
                onChange={handleUpdatePlanChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Intervals */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                intervals
              </label>
              <input
                type="number"
                name="intervals"
                value={updatePlan?.intervals}
                onChange={handleUpdatePlanChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Extra Goodies */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Extra Goodies
              </label>
              <input
                type="text"
                onKeyDown={(e) => handleUpdatePlanArrayInput(e, "extraGoodies")}
                className="w-full p-2 border rounded"
                placeholder="Press enter to add a goodie"
              />
              <div className="flex flex-wrap gap-2 mt-2 overflow-x-auto">
                {(updatePlan?.extraGoodies ?? []).length > 0 && updatePlan?.extraGoodies.map((goodie, index) => (
                  <span
                    key={index}
                    className="flex items-center px-3 py-1 space-x-2 text-white bg-blue-500 rounded-full"
                  >
                    {goodie}
                    <button
                      onClick={() =>
                        removeUpdatePlanItem("extraGoodies", index)
                      }
                      className="px-2 text-xs bg-red-500 rounded-full"
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>

            {/* Description */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Description
              </label>
              <input
                type="text"
                onKeyDown={(e) => handleUpdatePlanArrayInput(e, "description")}
                className="w-full p-2 border rounded"
                placeholder="Press enter to add a description"
              />
              <div className="flex flex-wrap gap-2 mt-2 overflow-x-auto">
                {(updatePlan?.description ?? []).length > 0 && updatePlan?.description.map((desc, index) => (
                  <span
                    key={index}
                    className="flex items-center px-3 py-1 space-x-2 text-white bg-blue-500 rounded-full"
                  >
                    {desc}
                    <button
                      onClick={() => removeUpdatePlanItem("description", index)}
                      className="px-2 text-xs bg-red-500 rounded-full"
                    >
                      &times;
                    </button>
                  </span>
                ))}
              </div>
            </div>
            {/* Tokens */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Tokens
              </label>
              <input
                type="number"
                name="tokens"
                value={updatePlan?.tokens}
                onChange={handleUpdatePlanChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <CButton variant={ButtonType.Primary} type="submit">
                Submit
              </CButton>
            </div>
          </form>
        </div>
      </ReusableModal>
    </div>
  );
};

export default Plans;
