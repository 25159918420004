// export const API_URL = 'https://resumeapi.hirebeen.com';

export const CALLBACK_URL = 'https://resume.hirebeen.com';
export const GOOGLE_CLIENT_ID = "593011355655-ohrg2pet7t0tngvih4ph4dcja76i9q8d.apps.googleusercontent.com"
// export const API_URL = 'http://localhost:8080';

// export const API_URL = 'http://ec2-54-157-11-237.compute-1.amazonaws.com:8000';
// export const API_URL = 'https://api.resumebeen.com';
export const API_URL = 'https://prodapi.resumebeen.com';

export const  DEFAULT_CURRENCY = 'USD';
//export const  DEFAULT_CURRENCY = 'INR';
//export const  CURRENCY_CONVERSION = '85';
export const  CURRENCY_CONVERSION = '1';
