import React, { useEffect, useState } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { API_URL } from './../apiConfig';
import useAuthStore from '../store/auth';

const AdminProtectedRoute = ({ element }) => {
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();


  const {setAdmin,isAuthenticated,setAuthenticated} = useAuthStore();

  useEffect(() => {
    const fetchAdmin = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/admin/me`, {
          withCredentials: true,
        });

        if (response?.data?.success) {
          // Set admin data in store
          setAdmin(response.data.data); 
          
          // Set authenticated state
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
          navigate("/admin/login");
        }
      } catch (error) {
        console.error('Error fetching admin:', error);
        setAuthenticated(false);
        navigate("/admin/login");
      } finally {
        setLoading(false);
      }
    };

    fetchAdmin();
  }, []);

  if (loading) {
    // Show a loading spinner or placeholder when loading
    return <div>Loading...</div>;
  }

  if (!isAuthenticated) {
    // Redirect to the login page if not authenticated
    return <Navigate to="/admin/login" replace />;
  }

  // If authenticated, render the provided element
  return element;
};

export default AdminProtectedRoute;
