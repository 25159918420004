import { create } from "zustand";

const tokensConfig = {
    id:"",
    createAIResume: 0,
    profile: 0,
    project: 0,
    workExperience: 0,
};

const useTokenConfigStore = create((set) => ({
  tokenConfig: tokensConfig,
  setTokenConfig: (tokenD) => set(() => ({ tokenConfig: tokenD })),
}));

export default useTokenConfigStore;
