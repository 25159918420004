import React from 'react';
import { FaSpinner } from "react-icons/fa";

const CustomButton = ({ disabled, onClick, children, className = '', loading, ...props }) => {
    return (
        <button
            className={`${className} w-full h-9 px-4 py-1 font-semibold text-center text-white bg-orange-500 rounded-md outline-none 
                hover:bg-orange-300 hover:text-white ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'} ${
        loading ? "flex items-center justify-center" : ""
      } ${loading ? "opacity-50 cursor-not-allowed" : ""} `}
            type="button"
            disabled={disabled}
            onClick={onClick}
            {...props}
        >
            <div className="inline-flex items-center justify-center min-w-[100px]">
                {
                    loading ? (
                        <FaSpinner className="w-5 h-5 text-white animate-spin" />
                    ) : (
                        children
                    )
                }
            </div>
        </button>
    );
};

export default CustomButton;
