import React, { useState } from "react";
import hide from "../image/icons/hide.png";
import view from "../image/icons/view.png";

function Toggle({ visible, onClick }) {
    return (
        <div onClick={onClick} className="ml-auto transition duration-50">
            {visible ? <img src={view} alt="View" className="w-7 h-7"/>  : <img src={hide} alt="Hide" className="w-7 h-7"/>}
        </div>
    );
}

export default Toggle;
