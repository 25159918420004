import React from "react";
import { useNavigate } from 'react-router-dom';
import axios from "axios";
import { extractErrorMessage } from "../../../utils/utils";
import useGlobalStore from "../../../store/global";
import { API_URL } from "../../../apiConfig";


const Heading = ({ title }) => {
  const { setToastData } = useGlobalStore();
  const navigate = useNavigate();
  const handleLogout = async () => {
    try {

      const response = await axios.post(`${API_URL}/api/v1/admin/logout`,{},{withCredentials:true})

      if(!response?.data?.success){
        setToastData({ message: "Failed to logout.", type: "error" });
        return
      }
      navigate("/admin/login");
      setToastData({
        message: "Sucessfully Logged Out",
        type: "success",
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  return (
    <div className="flex items-center justify-between flex-1 px-6 py-6 bg-white">
      <h1 className="text-xl font-semibold text-black md:text-xl sm:text-xl lg:text-2xl">
        {title && <>{title} </>}
      </h1>
      <button className="admin-btn btn-primary" onClick={handleLogout}>
        Log Out
      </button>
    </div>
  );
};

export default Heading;
