import {create} from 'zustand';


const userIntialState  = {
      _id: "",
      firstName: "",
      lastName: null,
      email: "",
      profilePic: null,
      phone: null,
      userStatus: "",
      password: "",
      tokenDetails: {
        tokens: 0,
        createResume: 0,
        profile: 0,
        project: 0,
        workExperience: 0,
      },
      lastLoggedIn: null,
      lastLoggedOut: null,
      updatedAt: "",
      createdAt: ""
  };

const userStore = create((set,get) =>({
    user: userIntialState,
    setUser: (newUser) => set(() => ({ user: newUser })),
    isAuthenticated:false,
    setAuthenticated: (userAuth) => set(() => ({ isAuthenticated: userAuth })),
    clearUser: () => set(() => ({ user: userIntialState })),
}))

export default userStore;