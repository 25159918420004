import React, { useEffect, useState } from "react";
import google from "../image/google.png";
import hirebeen from "../image/hirebeenLogo.png";
import hide from "../image/icons/hide.png";
import view from "../image/icons/view.png";
import H from "../image/H.png";
import Group2 from "../image/Group2.svg";
import Group3 from "../image/Group3.svg";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import CustomButton from "../Reusable/CustomButton";
import axios from "axios";
import { API_URL } from "../apiConfig";
import userStore from "../store/user.zustand";
import authMsgStore from "../store/auth.zustand";
import {Helmet} from "react-helmet";

const Login = () => {
  const [visible, setVisible] = useState();
  const [rememberMe, setRememberMe] = useState(false);
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const navigate = useNavigate();

  const [login, setLogin] = useState({
    email: "",
    password: "",
  });

  const [errMsg, setErrMsg] = useState({
    email: "",
    password: "",
    isEmailValid: true,
    isPasswordValid: true,
  });

  const handleLogin = async () => {
    try {
      setLoading(true);
      const res = await axios.post(`${API_URL}/api/v1/users/login`, {
        email: login.email,
        password: login.password,
        rememberMe: rememberMe,
      });

      setLoading(false);
      // navigate("/dashboard");

      if (!res?.data?.success) {
        toast.error(res?.data?.message);
        return;
      }

      if (res?.data?.success) {
        // call me query

        // const meResponse = await axios.get(`${API_URL}/api/v1/users/me`, {
        //   withCredentials: true,
        // });

        // authMsgStore
        //   .getState()
        //   .setAuthMsg({ authMsg: meResponse?.data?.message });
        // setLoading(false);

        navigate("/dashboard");
        // if (meResponse?.data?.success) {
        //   userStore
        //     .getState()
        //     .setUser({ user: meResponse?.data?.data, isAuthenticated: true });


        //   return;
        // }
        setLoading(false);
      }

      return;
    } catch (err) {
      // authMsgStore
      //   .getState()
      //   .setAuthMsg({ authMsg: err?.response?.data?.message });
      // userStore.getState().setUser({ isAuthenticated: false });
      // console.log(err?.response?.data?.message);
      toast.error(err?.response?.data?.message, {
        autoClose: 2500,
      });
      setLoading(false);
      return;
    }
  };

  const reachGoogle = () => {
    window.location.href = `${API_URL}/api/v1/users/auth/google`;
  };

  const handleEmailChange = (e) => {
    const email = e.target.value;
    setLogin((prev) => ({ ...prev, email }));

    // Validate email
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailRegex.test(email)) {
      setErrMsg((prev) => ({ ...prev, email: "", isEmailValid: true }));
    } else {
      setErrMsg((prev) => ({
        ...prev,
        email: "Please enter a valid email.",
        isEmailValid: false,
      }));
    }
  };

  const handlePasswordChange = (e) => {
    const password = e.target.value;
    setLogin((prev) => ({ ...prev, password }));

    // Validate password
    const passwordRegex =
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^a-zA-Z0-9]).{8,}$/;
    if (passwordRegex.test(password)) {
      setErrMsg((prev) => ({ ...prev, password: "", isPasswordValid: true }));
    } else {
      setErrMsg((prev) => ({
        ...prev,
        password: "Please enter a valid password.",
        isPasswordValid: false,
      }));
    }
  };

  useEffect(() => {
    const loginUser = async () => {
      if (!token) return; 

      try {

        // call clear cookie api

        await axios.put(`${API_URL}/api/v1/users/clear-cookie`)

        const res = await axios.put(`${API_URL}/api/v1/admin/verify-impersonation`, { token });

        if (!res?.data?.success) {
          toast.error(res?.data?.message);
          setLoading(false);
          return;
        }

        navigate("/dashboard");
        return

      } catch (error) {
        console.log(error);
        toast.error(error?.response?.data?.message || "An error occurred", {
          autoClose: 2500,
        });
      } finally {
        setLoading(false); 
      }
    };

    loginUser();
  }, [token]); 


  return (
    <div className="grid h-screen grid-cols-2 gap-0 max-md:grid-cols-1">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Login - ResumeBeen | Access Your Professional Resume Builder</title>
            <link rel="canonical" href="https://resumebeen.com/login" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/login"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Login - ResumeBeen | Access Your Professional Resume Builder"></meta>
            <meta property="og:title" content="Login - ResumeBeen | Access Your Professional Resume Builder"></meta>


            <meta name="description" content="Log in to your ResumeBeen account to access powerful resume-building tools, personalized templates, and career-enhancing features. Create and manage professional resumes and cover letters with ease."></meta>
            <meta property="og:description" content="Log in to your ResumeBeen account to access powerful resume-building tools, personalized templates, and career-enhancing features. Create and manage professional resumes and cover letters with ease."></meta>

            <meta name="keywords" content="ResumeBeen login, resume builder login, user login, account access, sign in to ResumeBeen, resume builder platform, professional resume tools, secure login, resume creation account"></meta>
            <meta property="og:keywords" content="ResumeBeen login, resume builder login, user login, account access, sign in to ResumeBeen, resume builder platform, professional resume tools, secure login, resume creation account"></meta>

        </Helmet>      
      </>


      <div className="relative w-full h-full bg-conic-gradient max-md:hidden">
        <div
          className="flex gap-2.5 self-start p-6"
          onClick={() => navigate("/")}
        >
          <img
            loading="lazy"
            alt="H"
            src={H}
            className="shrink-1 aspect-[0.75] w-[29px]"
          />
          <img
            loading="lazy"
            src={hirebeen}
            alt="Hirebeen"
            className="shrink-0 my-auto max-w-full aspect-[5.56] w-[146px]"
          />
        </div>
        <div className="absolute top-20 left-6 text-5xl font-bold text-white leading-[70px] max-md:top-10 max-md:left-4 max-md:text-4xl max-md:leading-[68px]">
          Build your successful resume instantly using AI
        </div>
        <div className="absolute bottom-0 left-0 flex justify-between w-full p-6 max-md:hidden">
          <img loading="lazy" alt="Resume" src={Group2} className="w-72" />
          <img loading="lazy" alt="Resume" src={Group3} className="w-72" />
        </div>
      </div>

      <div className="flex flex-col items-center justify-center p-4 rounded">
        <div className="space-y-2">

        
        <div>
          <div className="text-4xl font-semibold leading-10 tracking-tighter text-gray-900 max-md:max-w-full">
            Log in
          </div>
          <div className="mt-3 text-slate-600 max-md:max-w-full">
            Welcome back! Please enter your details.
          </div>
        </div>

        <div className="flex flex-col justify-start p-2 space-y-2 ">
        <div
            onClick={reachGoogle}
            className="w-full flex gap-3 items-center justify-center py-2.5 hover:cursor-pointer px-6 font-semibold bg-white rounded-lg border border-gray-300 border-solid shadow-sm text-slate-700 max-md:flex-wrap max-md:px-5"
          >
            <img
              loading="lazy"
              src={google}
              className="w-6 shrink-0 aspect-square"
              alt="Google"
            />
            <div>Login With Google</div>
          </div>
          {/* <br /> */}

          <div className="w-full">
            <div className="flex flex-col gap-y-4">
              {/* Email */}
              <div>
                <label for="email" className="mb-2 text-base font-medium ">
                  Email
                </label>
                <input
                  type="email"
                  id="email"
                  required
                  className={`w-full px-3 py-1 text-sm border rounded-md outline-none ${
                    !errMsg?.email ? "" : "border-red-500"
                  }`}
                  name="email"
                  placeholder="name@example.com"
                  value={login.email}
                  onChange={handleEmailChange}
                />
                <p className="mt-2 text-xs text-red-500">
                  {errMsg?.email && <span className="">{errMsg?.email}</span>}
                </p>
              </div>

              {/* Password */}
              <div>
                <label htmlFor="password" className="mb-2 font-medium">
                  Password
                </label>
                <div className="flex flex-wrap w-full px-3 py-1 border rounded-md">
                  <input
                    type={visible ? "text" : "password"}
                    id="password"
                    required
                    className={`flex-grow text-sm outline-none ${
                      !errMsg?.password ? "" : "border-red-500"
                    }`}
                    name="password"
                    placeholder="password ..."
                    value={login.password}
                    onChange={handlePasswordChange}
                  />
                  <div
                    onClick={() => setVisible(!visible)}
                    className="float-left cursor-pointer"
                  >
                    {visible ? (
                      <img src={view} alt="Hide" className="w-4 h-4" />
                    ) : (
                      <img src={hide} alt="View" className="w-4 h-4" />
                    )}
                  </div>
                  {/* {alertMessage && (
                <p className="text-red-500 text-xs mt-[-20px]">
                  {alertMessage}
                </p>
              )} */}
                </div>
                <p className="mt-2 text-xs text-red-500">
                  {errMsg?.password && (
                    <span className="">{errMsg?.password}</span>
                  )}
                </p>
              </div>
            </div>

            <br />
            <div className="flex gap-x-7">
              <div className="flex">
                <input
                  type="checkbox"
                  name=""
                  htmlFor="checkbox"
                  id="CheckBox"
                  className="mr-2 hover:cursor-pointer"
                  checked={rememberMe}
                  onChange={(e) => setRememberMe(e.target.checked)}
                />
                <span
                  id="checkbox"
                  className="hover:cursor-pointer"
                  onClick={() => setRememberMe((e) => !e)}
                >
                  Remember for 30 days
                </span>
              </div>

              <div>
                <button className="text-purple-500 float-end">
                  <Link to={"../forgot-password/"}>Forgot password</Link>
                </button>
              </div>
            </div>

            <div className="flex w-full">
              <CustomButton
                // disabled={disableLoginBtn()}
                onClick={handleLogin}
                loading={loading}
                className="py-1 mt-4"
              >
                Login
              </CustomButton>
            </div>
          </div>
          <p className="text-base">
            Don't have any account ?{" "}
            <Link className="font-semibold text-violet-700" to={"../signup/"}>
              Signup
            </Link>
          </p>
        </div>
        </div>
      </div>
    </div>
  );
};

export default Login;

