import { create } from "zustand";

const useGlobalStore = create((set) => ({
  selectedModule: "Dashboard",
  setSelectedModule: (moduleSelected) =>
    set({ selectedModule: moduleSelected }),
  isModalOpen: false,
  setModalOpen: (open) => set({ isModalOpen: open }),
  modalData: null,
  setModalData: (data) => set({ modalData: data }),
  isSidebarExpanded: true,
  setisSidebarExpanded: (open) => set({ isSidebarExpanded: open }),
  toastData: null,
  setToastData: (data) => set({ toastData: data }),
}));

export default useGlobalStore;
