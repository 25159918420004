// lib/utils.ts
import clsx, { ClassValue } from "clsx";
import { twMerge } from "tailwind-merge";

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs));
}
export function formatMonthYear(dateString) {
  if (dateString) {
    const date = new Date(dateString);

    // Get the month (0-indexed, so we add 1)
    let month:number | string = date.getUTCMonth() + 1;

    // Format the month as two digits
    month = month < 10 ? `0${month}` : `${month}`;

    // Get the year
    const year = date.getUTCFullYear();

    return `${month} ${year}`;
  }
}

export const convertToISODate = (monthYear) => {
  if(monthYear){
    const [year, month] = monthYear.split("-");
    
    const date = new Date(Date.UTC(year, month - 1, 1));
    
    return date.toISOString();
  }
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const phoneNumberRegex =
  /^\+?(\d{1,3})?[-. ]?(\(?\d{1,4}\)?)?[-. ]?\d{3}[-. ]?\d{3,4}[-. ]?\d{4}$/;


