import React, { useEffect, useState } from "react";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
import axios from "axios";
import { API_URL } from "../apiConfig.js";
import {Helmet} from "react-helmet";


function Privacypage() {
  const [pp, setPP] = useState("");

  useEffect(() => {
    window.scrollTo(0,0)
  },[])

  
  useEffect(() => {
    const getTc = async () => {
      try {
        const pp = await axios.get(`${API_URL}/api/v1/pp/pp`);
        setPP(pp?.data?.data?.content)
      } catch (error) {
        console.error("Error fetching TC:", error);
      }
    };

    getTc();
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Privacy Policy - ResumeBeen | Your Data Security and Privacy Matters</title>
            <link rel="canonical" href="https://resumebeen.com/privacy" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/privacy"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Privacy Policy - ResumeBeen | Your Data Security and Privacy Matters"></meta>
            <meta property="og:title" content="Privacy Policy - ResumeBeen | Your Data Security and Privacy Matters"></meta>


            <meta name="description" content="Read ResumeBeen's Privacy Policy to understand how we collect, use, and protect your personal data. We are committed to safeguarding your privacy while providing you with the best resume-building tools and services."></meta>
            <meta property="og:description" content="Read ResumeBeen's Privacy Policy to understand how we collect, use, and protect your personal data. We are committed to safeguarding your privacy while providing you with the best resume-building tools and services."></meta>

            <meta name="keywords" content="ResumeBeen privacy policy, data protection, personal information security, user privacy, privacy terms, resume builder privacy, how we use data, secure data handling, privacy and security, ResumeBeen data collection"></meta>
            <meta property="og:keywords" content="ResumeBeen privacy policy, data protection, personal information security, user privacy, privacy terms, resume builder privacy, how we use data, secure data handling, privacy and security, ResumeBeen data collection"></meta>

        </Helmet>      
      </>


      <Navbar />
      <div>
        <div>
          <div className="mx-12 my-10">
          <div className="" dangerouslySetInnerHTML={{ __html: pp }} />
          </div>
          <Footer />
        </div>
      </div>
    </div>
  );
}

export default Privacypage;
