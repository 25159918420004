import React, { useEffect, useState } from "react";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
import axios from "axios";
import { API_URL } from "../apiConfig.js";
import {Helmet} from "react-helmet";


const TermsAndConditions = () => {
  const [tc, setTC] = useState("");

  useEffect(() => {
    window.scrollTo(0,0)
  },[])


  useEffect(() => {
    const getTc = async () => {
      try {
        const tc = await axios.get(`${API_URL}/api/v1/tc/tc`);
        setTC(tc?.data?.data?.content);
      } catch (error) {
        console.error("Error fetching TC:", error);
      }
    };

    getTc();
  }, []);


  return (
    <div className="flex flex-col min-h-screen bg-white">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Terms and Conditions - ResumeBeen | Our Guidelines and User Agreement</title>
            <link rel="canonical" href="https://resumebeen.com/terms" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/terms"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Terms and Conditions - ResumeBeen | Our Guidelines and User Agreement"></meta>
            <meta property="og:title" content="Terms and Conditions - ResumeBeen | Our Guidelines and User Agreement"></meta>


            <meta name="description" content="Review the Terms and Conditions of ResumeBeen to understand the guidelines and rules for using our resume-building platform. By using our services, you agree to our terms of service and user responsibilities."></meta>
            <meta property="og:description" content="Review the Terms and Conditions of ResumeBeen to understand the guidelines and rules for using our resume-building platform. By using our services, you agree to our terms of service and user responsibilities."></meta>

            <meta name="keywords" content="ResumeBeen terms and conditions, user agreement, privacy policy, website terms, service terms, resume builder terms, platform usage guidelines, user responsibilities, terms of service"></meta>
            <meta property="og:keywords" content="ResumeBeen terms and conditions, user agreement, privacy policy, website terms, service terms, resume builder terms, platform usage guidelines, user responsibilities, terms of service"></meta>

        </Helmet>      
      </>


      <Navbar />

      <div>
        <div>
          <div className="mx-12 my-10">
          <div className="" dangerouslySetInnerHTML={{ __html: tc }} />
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default TermsAndConditions;
