import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { FaRegEdit, FaRegEye } from "react-icons/fa";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import RoopTable from "../../common/customTableR/table";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";
import { API_URL } from "../../../apiConfig";

const User = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();
  const [impersonationConfirmModal, setImpersonationConfirmModal] =
    useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectUser, setSelectUser] = useState("");
  const [users, setUsers] = useState([]);
  const [userName, setUserName] = useState("");
  const [updateUser, setUpdateUser] = useState({
    email: "",
    tokens: null,
    projectToken: null,
    profileToken: null,
    workToken: null,
    resumeToken: null,
    userId: "",
    showModal: false,
  });
  const [count, setCount] = useState(0)

  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  const [page, setPage] = useState(1)

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/v1/admin/users?page=${page}`, {
          withCredentials: true,
        });

        let count = 1;
        setUsers(
          response?.data?.data.map((el) => {
            // Map through the user data
            return {
              count: count++,
              _id: el?._id,
              firstName: el?.firstName, // User first name
              lastName: el?.lastName, // User last name
              email: el?.email, // User email
              profilePic: el?.profilePic ?? "", // User profile picture
              phone: el?.phone ?? null, // User phone
              userStatus: el?.userStatus, // User status
              numberOfResetPassword: el?.numberOfResetPassword, // Number of reset password attempts
              tokenDetails: el?.tokenDetails ?? {
                tokens: 0,
                createResume: 0,
                profile: 0,
                project: 0,
                workExperience: 0,
              }, // Token details
              lastLoggedIn: formatDate(el?.lastLoggedIn) ?? null, // Last logged-in time
              lastLoggedOut: formatDate(el?.lastLoggedOut) ?? null, // Last logged-out time
              updatedAt: formatDate(el?.updatedAt) ?? null, // Last updated time
              createdAt: formatDate(el?.createdAt) ?? null, // Creation time
            };
          })
        );
      } catch (error) {
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [setLoading,count,page]);

  const handleImpersonationModalClose = () => {
    setImpersonationConfirmModal(false);
    setSelectUser("");
  };

  const handleDeleteClick = (id, userName) => {
    setImpersonationConfirmModal(true);
    setSelectUser(id);
    setUserName(userName);
  };

  const handleImpersonationConfirm = async () => {
    if (selectUser === "") {
      setToastData({
        type: "error",
        message: "Something went wrong, please try again later!",
      });
      return;
    }
    try {
      setBtnLoading(true);

      setLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/admin/login-as-user`,
        { email: selectUser },
        { withCredentials: true }
      );
      setLoading(false);

      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        return;
      }

      window.open(response?.data?.data, "_blank"); // Opens in a new tab/window

      handleImpersonationModalClose();
    } catch (error) {
      setLoading(false);
      const errorJson = JSON.parse(JSON.stringify(error));
      if (
        errorJson &&
        errorJson.response &&
        errorJson.response.errors &&
        errorJson.response.errors[0].message
      ) {
        setToastData({
          type: "error",
          message: errorJson.response.errors[0].message
            .toString()
            .replace("Error: ", ""),
        });
      } else {
        setToastData({
          type: "error",
          message: error.toString(),
        });
      }
    } finally {
      setBtnLoading(false);
    }
  };

  const editUserInfo = (rowData) => {
    const user = users?.filter((el) => el?._id === rowData?._id);
    setUpdateUser((prev) => ({
      ...prev,
      showModal:true,
        tokens: user[0]?.tokenDetails?.tokens,
      projectToken: user[0]?.tokenDetails?.project,
      profileToken: user[0]?.tokenDetails?.profile,
      workToken: user[0]?.tokenDetails?.workExperience,
      resumeToken: user[0]?.tokenDetails?.createResume,
      userId: user[0]?._id,
    }))
  }
  
  const renderActions = (rowData) => (
    <div className="flex items-center justify-center space-x-3">
      <div>
        <FaRegEdit
          className="text-red-500 cursor-pointer"
          onClick={() => {
            // editResume(rowData);
            editUserInfo(rowData)
          }}
        />
      </div>

      <div>
        <FaRegEye
          className="text-green-500 cursor-pointer"
          onClick={() => {
            handleDeleteClick(
              rowData.email,
              `${rowData?.firstName || ""} ${rowData?.lastName || ""}`
            );
          }}
        />
      </div>
    </div>
  );

  const headings = [
    { dataKey: "count", title: "Count" },
    { dataKey: "email", title: "Email Address" },
    { dataKey: "firstName", title: "First Name" },
    { dataKey: "lastName", title: "Last Name" },
    { dataKey: "userStatus", title: "User Status" },
    { dataKey: "profilePic", title: "Profile Picture" },
    { dataKey: "phone", title: "Phone Number" },
    { dataKey: "numberOfResetPassword", title: "Reset Password Count" },
    { dataKey: "tokenDetails.tokens", title: "Total Tokens" },
    { dataKey: "tokenDetails.createResume", title: "Resume Creation Tokens" },
    { dataKey: "tokenDetails.profile", title: "Profile Tokens" },
    { dataKey: "tokenDetails.project", title: "Project Tokens" },
    { dataKey: "tokenDetails.workExperience", title: "Work Experience Tokens" },
    { dataKey: "lastLoggedIn", title: "Last Logged In" },
    { dataKey: "lastLoggedOut", title: "Last Logged Out" },
    { dataKey: "createdAt", title: "Created At" },
    { dataKey: "updatedAt", title: "Updated At" },
    { title: "Actions", dataKey: "id", render: renderActions },
  ];

  const handleUpdateUser = (e) => {
    const { name, value } = e.target;
    setUpdateUser((prev) => ({
      ...prev,
      [name]: value,
    }));
  };
  const handleUpdateUserInfo = async (e) => {
    e.preventDefault();
    try {
      if(!updateUser?.userId){
        setToastData({
          type: "error",
          message: "User ID is required!",
        });
        return;
      }

      const respose = await axios.post(`${API_URL}/api/v1/admin/edit-userInfo`,
        {
          email:updateUser?.email,
          tokens:updateUser?.tokens,
          projectToken:updateUser?.projectToken,
          profileToken:updateUser?.profileToken,
          workToken:updateUser?.workToken,
          resumeToken:updateUser?.resumeToken,
          userId:updateUser?.userId,
        },{withCredentials:true})

        if(!respose?.data?.success){
          setToastData({
            type: "error",
            message: respose?.data?.message,
          });
          return;
        }

        setUpdateUser((prev) => ({
          email: "",
          tokens: null,
          projectToken: null,
          profileToken: null,
          workToken: null,
          resumeToken: null,
          userId: "",
          showModal: false,
        }));
        setToastData({
          type: "success",
          message: "User info updated successfully!",
        });
        
        setCount((prev) => prev + 1)

    } catch (error) {
      console.log(error);
      setToastData({
        type: "error",
        message: "Something went wrong, please try again later!",
      });
    }
  };

  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={isLoading}
        data={users}
        itemsPerPage={10}
        headings={headings}
        hovered
        setPage={setPage}
      />

      <ReusableModal
        isOpen={impersonationConfirmModal}
        onClose={handleImpersonationModalClose}
        title="Are you sure ?"
        comments={`You are about to impersonate this user (${userName}). All actions will be performed as the selected user.`}
      >
        <div className="flex justify-end space-x-4">
          <CButton
            loading={btnLoading}
            variant={ButtonType.Primary}
            onClick={handleImpersonationConfirm}
          >
            Yes
          </CButton>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={updateUser?.showModal}
        onClose={() => {
          setUpdateUser({
            email: "",
            tokens: null,
            projectToken: null,
            profileToken: null,
            workToken: null,
            resumeToken: null,
            userId: "",
            showModal: false,
          });
        }}
        title="Update User Information"
      >
        <div className="max-h-[80vh] overflow-y-auto">
          {" "}
          {/* Set a max height and enable scrolling */}
          <form onSubmit={handleUpdateUserInfo} className="space-y-4">
            {/* Plan Name */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                User Email
              </label>
              <input
                type="email"
                name="email"
                value={updateUser?.email}
                onChange={handleUpdateUser}
                className="w-full p-2 border rounded"
                placeholder="Enter user new email address"
              />
            </div>
            {/* Tokens */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Tokens
              </label>
              <input
                type="number"
                name="tokens"
                value={updateUser?.tokens}
                onChange={handleUpdateUser}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Project Token */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Project Tokens
              </label>
              <input
                type="number"
                name="projectToken"
                value={updateUser?.projectToken}
                onChange={handleUpdateUser}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Profile Token */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Profile Token
              </label>
              <input
                type="number"
                name="profileToken"
                value={updateUser?.profileToken}
                onChange={handleUpdateUser}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Work Token */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Work Token
              </label>
              <input
                type="number"
                name="workToken"
                value={updateUser?.workToken}
                onChange={handleUpdateUser}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Resume Token */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Resume Token
              </label>
              <input
                type="number"
                name="resumeToken"
                value={updateUser?.resumeToken}
                onChange={handleUpdateUser}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <CButton variant={ButtonType.Primary} type="submit">
                Submit
              </CButton>
            </div>
          </form>
        </div>
      </ReusableModal>
    </div>
  );
};

export default User;
