import React from "react";

import Alert from "../Component/alert";
import { useEffect } from "react";
import { verify, getUser, googleLogin } from "../reducer/Actions";
import { useLocation } from "react-router-dom";
import queryString from "query-string";

const Layout = (props) => {
    let location = useLocation();
    useEffect (() => {
        const values = queryString.parse(location.search);
        const code = values.code;
        if ( code ) {
            props.googleLogin( code );
        } else {
            // props.verify();
            // props.getUser();
        }
    }, [location]);
    return (
        <div>
            {props.message? <Alert message={props.message}/>: null}
            {props.children}
        </div>
    )
}

export default Layout