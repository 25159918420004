import { AnimatePresence, motion } from "framer-motion";
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import { fadeIn } from "../../../utils/utils";


const ReusableModal = ({
  isOpen,
  onClose,
  children,
  width = "md",
  title,
  comments,
}) => {
  useEffect(() => {
    const handleOutsideClick = (event) => {
      if ((event.target).classList.contains("modal-overlay")) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("click", handleOutsideClick);
    } else {
      document.removeEventListener("click", handleOutsideClick);
    }

    return () => document.removeEventListener("click", handleOutsideClick);
  }, [isOpen, onClose]);

  const widthClasses = {
    xs: "w-1/5",
    sm: "w-1/4",
    md: "w-1/3",
    ml: "w-1/2",
    lg: "w-3/4",
    xl: "w-full",
  };

  return ReactDOM.createPortal(
    <AnimatePresence>
      {isOpen && (
        <motion.div
          className="fixed inset-0 z-50 flex items-center justify-center bg-black modal-overlay bg-opacity-60"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div
            variants={fadeIn("up", "tween", 0, 0.3)}
            initial="hidden"
            animate="show"
            exit="hidden"
            className={`rounded shadow-lg bg-white ${widthClasses[width]} z-10`}
            onClick={(e) => e.stopPropagation()}
          >
            <div className="px-4 py-2">
              <div className="flex items-start justify-between py-2 rounded-t">
                <div className="flex items-center space-x-4">
                  <h2 className="text-2xl font-bold text-black">{title}</h2>
                </div>
                <button
                  type="button"
                  className="inline-flex items-center justify-center w-8 h-8 text-sm text-black bg-transparent rounded-lg hover:bg-gray-200 hover:text-gray-900 dark:hover:bg-primary dark:hover:text-white"
                  onClick={onClose}
                >
                  <svg
                    className="w-3 h-3"
                    aria-hidden="true"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 14 14"
                  >
                    <path
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      d="m1 1 6 6m0 0 6 6M7 7l6-6M7 7l-6 6"
                    />
                  </svg>
                  <span className="sr-only">Close modal</span>
                </button>
              </div>
              {comments && <p className="mb-4 text-gray-500">{comments}</p>}
              {children}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>,
    document.body
  );
};

export default ReusableModal;
