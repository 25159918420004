import {create} from 'zustand';


const userProfileIntialState  = {
      _id: "",
      firstName: "",
      lastName: null,
      email: "",
      profilePic: "",
      dob:"",
      gender:"",
      address:"",
      city:"",
      state:"",
      country:"",
      pincode:null,
      designation:"",
      organization:"",
      orgnizationCity:"",
      orgnizationWorkMode:"",
      noticePeriod:"",
      totalExperience:"",
      phone: null,
      userStatus: "",
      password: "",
      updatedAt: "",
      createdAt: ""
  };

const userProfileStore = create((set,get) =>({
    userProfile: userProfileIntialState,
    setUserProfile: (newUser) => set(state => ({...state, userProfile: newUser })),
    clearUser: () => set(state => ({...state, userProfile: userProfileIntialState })),
}))

export default userProfileStore;