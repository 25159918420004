import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import axios from 'axios';
import userStore from '../store/user.zustand';
import { API_URL } from './../apiConfig';
import { toast } from 'react-toastify';
import useTokenConfigStore from '../store/token.zustand';

const ProtectedRoute = ({ element }) => {
  const [loading, setLoading] = useState(true);
  // const [authenticated, setAuthenticated] = useState(false);

  // const setUser = userStore((state) => state.setUser);
  const {setUser,setAuthenticated,isAuthenticated} = userStore();
  const {setTokenConfig} = useTokenConfigStore()

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/users/me`, {
          withCredentials: true,
        });

        if (response?.data?.success) {
          setUser(response.data.data);
          setAuthenticated(true);
        } else {
          setAuthenticated(false);
          // navigate("/login")
          // toast.error(response?.data?.message,{autoClose:2500})
        }
      } catch (error) {
        // toast.error(error?.response?.data?.message,{autoClose:2500})
        setAuthenticated(false);
        // navigate("/login")
      } finally {
        setLoading(false);
      }
    };

    const tokens = async () => {
      try {
        const response = await axios.get(`${API_URL}/api/v1/config/u-token`, {
          withCredentials: true,
        });

        if (response?.data?.success) {
          setTokenConfig(response.data.data);
        } else {
          // toast.error(response?.data?.message,{autoClose:2500})
        }
      } catch (error) {
        // toast.error(error?.response?.data?.message,{autoClose:2500})
      } finally {
        setLoading(false);
      }
    }

    fetchUser();
    tokens()
  }, []);

  if (loading) {
    // Show a loading indicator while fetching the user data
    return 
  }

  if (!isAuthenticated) {
    // If the user is not authenticated, redirect to the login page
    // return
    return <Navigate to="/login" replace />;
  }

  // If the user is authenticated, render the provided element
  return element;
};

export default ProtectedRoute;
