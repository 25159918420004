import { create } from "zustand";

const adminInitialState = {
  name: "",
  role: "",
  _id: "",
};

const useAuthStore = create((set) => ({
  admin: adminInitialState,
  setAdmin: (newAdmin) => set(() => ({ admin: newAdmin })),
  isAuthenticated: false,
  setAuthenticated: (adminAuth) => set(() => ({ isAuthenticated: adminAuth })),
}));

export default useAuthStore;
