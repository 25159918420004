import { useEffect, useRef, useState } from "react";
import axios from "axios";
import { FaArrowAltCircleLeft, FaArrowAltCircleRight } from "react-icons/fa";
import { PDFExport } from "@progress/kendo-react-pdf";
import Template1 from "./Template1";
import Template2 from "./Template2";
import Template3 from "./Template3";
import Template4 from "./Template4";
import { API_URL } from "../apiConfig";
import aiResumeStore from "../store/aiResumes.zustand";
import { useNavigate } from 'react-router-dom';

const ResumeComponent = () => {
  const pdfRefs = useRef([]);
  const slidePanelRef = useRef(null); // Reference for the slide panel

  // Pagination state
  const [page, setPage] = useState(1);
  const [totalResumes, setTotalResumes] = useState(0); // Total number of resumes
  const zustandAIResumes = aiResumeStore((state) => state.resumes);
  const navigate = useNavigate()

  // Fetching resumes
  const fetchAIResumes = async () => {
    try {
      const resumesResponse = await axios.get(
        `${API_URL}/api/v1/generate-summary/active-resumes`,
        {
          params: { page },
          withCredentials: true,
        }
      );
      if (resumesResponse?.data?.success) {
        aiResumeStore.getState().setResumes(resumesResponse?.data?.data);
        setTotalResumes(resumesResponse?.data?.total); // Assuming API returns total count
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAIResumes();
  }, [page]);

  const handleNext = () => {
    if (slidePanelRef.current) {
      slidePanelRef.current.scrollBy({
        left: slidePanelRef.current.clientWidth, // Scroll by panel width
        behavior: "smooth",
      });
    }
  };

  const handlePrev = () => {
    if (slidePanelRef.current) {
      slidePanelRef.current.scrollBy({
        left: -slidePanelRef.current.clientWidth, // Scroll backwards by panel width
        behavior: "smooth",
      });
    }
  };

  const renderTemplate = (formData,selectedTemplate) => {
    switch (selectedTemplate) {
      case "template1":
        return (
          <Template1 formData={formData} />
        );
      case "template2":
        return (
          <Template2 formData={formData} />
        );
      case "template3":
        return (
          <Template3 formData={formData} />
        );
      case "template4":
        return (
          <Template4 formData={formData} />
        );
      default:
        return (
          <Template1 formData={formData} />
        );
    }
  };


  return (
    <div className="p-5">
      <div className="flex items-center justify-between">
        <div className="cursor-pointer" onClick={handlePrev}>
          <FaArrowAltCircleLeft size={32}/>
        </div>
        <div
          className="flex items-center w-full overflow-x-scroll bg-transparent scroll-snap-x mandatory scroll-smooth"
          ref={slidePanelRef}
        >
          {/* Map resumes */}
          {(zustandAIResumes ?? []).length > 0 &&
            zustandAIResumes.filter((el) => Array.isArray(el?.tags) && el?.tags.some((tag) => tag.toLowerCase() === "home")).map((item, index) => {
              const formData = {
                resumeId: item?.resumeId || "",
                resumeTitle: item?.resumeTitle || "Untitled",
                eduFlag: item?.eduFlag ?? true,
                workFlag: item?.workFlag ?? true,
                achFlag: item?.achFlag ?? true,
                addFlag: item?.addFlag ?? true,
                skillFlag: item?.skillsFlag ?? true,
                proFlag: item?.proFlag ?? true,
                Details: item?.personalDetails
                  ? [
                      {
                        fullName: item?.personalDetails?.fullName || "",
                        email: item?.personalDetails?.email || "",
                        phone: item?.personalDetails?.phone || "",
                        address: item?.personalDetails?.address || "",
                        website: item?.personalDetails?.website || "",
                        linkedin: item?.personalDetails?.linkedin || "",
                        github: item?.personalDetails?.github || "",
                        about: item?.personalDetails?.summary || "",
                      },
                    ]
                  : [],
                education: item?.education || [],
                experience:
                  item?.experience?.map((exp) => ({
                    jobRole: exp?.jobRole || "",
                    companyName: exp?.companyName || "",
                    companyLocation: exp?.companyLocation || "",
                    fromDate: exp?.fromDate || "",
                    toDate: exp?.toDate || "",
                    jobDescription: exp?.responsibilities,
                  })) || [],
                skills:
                  item?.skills?.map((skill) => ({
                    title: skill?.title || "",
                    skillList: skill?.skillList?.join(", ") || "",
                  })) || [],
                achivawards:
                  item?.achivawards?.map((achi) => ({
                    achivawards: achi || "",
                  })) || [],
                additionalInfo:
                  item?.additionalInfo?.map((info) => ({
                    addInfo: info?.addInfo || "",
                  })) || [],
                projects:
                  item?.projects?.map((proj) => ({
                    title: proj?.title || "",
                    stack: proj?.stack?.join(", ") || "",
                    desc: proj?.desc ? proj?.desc.toString() : "",
                  })) || [],
                profileImage: "",
                templateKey: item?.templateKey,
              };

              return (
                <div
                  key={index}
                  className="snap-start flex-none mx-2 rounded-sm w-64 h-80 sm:w-80 sm:h-96 lg:w-[455px] lg:h-[564px]"
                >
                  <div 
                  className="relative h-full mx-2 overflow-hidden text-center bg-white hover:cursor-pointer rounded-2xl hover:shadow-xl"
                  onClick={() => navigate("/dashboard")}
                  >
                    <PDFExport
                      ref={(el) => (pdfRefs.current[index] = el)}
                      paperSize="A4"
                      className="h-0.5"
                      fileName={`${item.resumeTitle || "Untitled"}`}
                    >
                      <div className="scale-[0.3] text-left origin-top-left w-[1000px]">
                        <div className="scale-[1.5] origin-top-left">
                          {item.templateKey === "template1" && (
                            <Template1 formData={formData} />
                          )}
                          {item.templateKey === "template2" && (
                            <Template2 formData={formData} />
                          )}
                          {item.templateKey === "template3" && (
                            <Template3 formData={formData} />
                          )}
                          {item.templateKey === "template4" && (
                            <Template4 formData={formData} />
                          )}
                        </div>
                      </div>
                      {/* {renderTemplate(formData,item.templateKey)} */}
                    </PDFExport>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="cursor-pointer" onClick={handleNext}>
          <FaArrowAltCircleRight size={32} />
        </div>
      </div>
{/* 
      <div className="flex justify-between mt-4">
        <button 
          onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
          disabled={page === 1}
          className="px-4 py-2 text-white bg-blue-500 rounded disabled:opacity-50"
        >
          Previous
        </button>
        <span>
          Page {page} of {Math.ceil(totalResumes / 3)} 
        </span>
        <button 
          onClick={() => setPage((prev) => prev + 1)}
          disabled={page * 3 >= totalResumes}
          className="px-4 py-2 text-white bg-blue-500 rounded disabled:opacity-50"
        >
          Next
        </button>
      </div> */}
    </div>
  );
};

export default ResumeComponent;
