import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from "../image/icons/delsolid.svg";
// import { Tooltip } from 'react-tooltip';
import AlertComponent from './AlertComponent';

const DynamicAchivAward = ({ inputs, setInputs,setDataSaved }) => {
  // Function to handle change of any input field within the education entries
  const handleChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index][event.target.name] = event.target.value;
    setInputs(updatedInputs);
    setDataSaved(false);
  };

  // Function to add a new education entry
  const handleAddAchivaward = () => {
    const newInput = { achivawards: '' };
    setInputs([...inputs, newInput]);
  };

  const [showAlert, setShowAlert] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(null);

  // Function to remove an education entry
  const handleRemoveAchivaward = (index) => {
    setIndexToRemove(index);
    setShowAlert(true);
  };

  const cancelhandleRemoveAchivaward = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(indexToRemove, 1);
    setInputs(updatedInputs);
    setShowAlert(false);
  };

  const confirmhandleRemoveAchivaward = (index) => {
    setShowAlert(false);
  };

  // State to manage accordion behavior
  const [accordionIndex, setAccordionIndex] = useState(null);

  // Function to toggle accordion
  const toggleAccordion = (index) => {
    if (accordionIndex === index) {
      setAccordionIndex(null);
    } else {
      setAccordionIndex(index);
    }
  };

  return (
    <div>
      {inputs.map((input, index) => (
        <div key={index} onClick={() => toggleAccordion(index)} className='flex flex-row mx-2 my-4'>
         
          <input
                  type="text"
                  name="achivawards"
                  value={input.achivawards}
                  onChange={(event) => handleChange(index, event)}
                  placeholder="Your Achievements"
                  className="w-full p-2 border rounded-xl"
                />
            {inputs.length > 1 && (
              <button onClick={() => handleRemoveAchivaward(index)} className="ml-3 text-white">
              <DeleteIcon  className='w-5 h-5 hover:fill-red-600' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" data-tooltip-place="right-end" />              </button>
            )}
            <AlertComponent
            show={showAlert}
            onConfirm={cancelhandleRemoveAchivaward}
            onCancel={confirmhandleRemoveAchivaward}
          />
          </div>
         
               
      ))}
      <div onClick={handleAddAchivaward} className="flex justify-center p-2 m-3 text-lg font-semibold text-white delay-700 bg-orange-500 cursor-pointer rounded-xl">
        + Add Achievements
      </div>
    </div>
  );
};

export default DynamicAchivAward;
