import React, { useState } from "react";
import { API_URL } from "../apiConfig.js";
import { useNavigate } from "react-router-dom";
import spinner from "../image/spinner.svg";
import Navbar from "../Component/navbar";
// import Globe from "../Component/magicui/globe.tsx";
import Footer from "../Component/footer.jsx";
import axios from "axios";
import { toast } from "react-toastify";
import userStore from "../store/user.zustand.js";

const CreateResumeForJD = () => {
  // const zustandUser = userStore((state) => state.user);
  const {setUser,user, setAuthenticated} = userStore()

  const [jobPosition, setJobPosition] = useState("");

  const [jobDescription, setJobDescription] = useState("");

  const [uploadedResume, setUploadedResume] = useState("");

  
  
  // const [responseJson, setResponseJson] = useState(null);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [buttonDisabled, setButtonDisabled] = useState(false);
  const navigate = useNavigate();
  const [reuseResumeLoading, setReuseResumeLoading] = useState(false);
 

  const fetchUser = async () => {
    try {
      const response = await axios.get(`${API_URL}/api/v1/users/me`, {
        withCredentials: true,
      });

      if (response?.data?.success) {
        setUser(response?.data?.data)
        setAuthenticated(true);
        // userStore.getState().setUser({ user: response?.data?.data,isAuthenticated:true });
      } else {
        if (
          response?.data?.message === "Unauthorized" ||
          response?.data?.message === "User not found"
        ) {
          // redirect to login
          navigate("/login", { replace: true });
        }
        toast.error(response?.data?.message, {
          autoClose: 2500,
        });
        return;
      }
      return;
    } catch (error) {
      // userStore.getState().setUser({ isAuthenticated:false });
      setAuthenticated(false);
      toast.error(error?.response?.data?.message, {
        autoClose: 2500,
      });
      navigate("/login", { replace: true });
      console.log(error);
      return;
    }
  };

  // useEffect(() => {
  //   fetchUser();
  // }, []);

  // Check authentication status on component mount
  // useEffect(() => {
  //   const authToken = localStorage.getItem("access");
  //   if (!authToken) {
  //     // If authentication token not found, navigate to login page
  //     navigate("/login");
  //   }
  // }, []);

  // const handleButtonClick = async (e) => {
  //   e.preventDefault();
  //   if (!jobPosition.trim()) {
  //     setErrorMessage("Please provide input data.");
  //     return;
  //   }
  //   setLoading(true);
  //   setButtonDisabled(true);

  //   try {
  //     const authToken = localStorage.getItem("access");

  //     const aiResumeResponse = await axios.post(
  //       `${API_URL}/api/v1/generate-summary/create-resume`,
  //       {
  //         jobPosition: jobPosition,
  //       },
  //       {
  //         withCredentials: true,
  //       }
  //     );

  //     if (!aiResumeResponse?.data?.sucesss) {
  //       toast.error(aiResumeResponse?.data?.message, {
  //         autoClose: 2500,
  //       });
  //       return;
  //     }

  //     navigate(`/template`, {
  //       state: {
  //         outputData: {
  //           ...zustandUser?.user,
  //           resumeId: aiResumeResponse?.data?.data,
  //         },
  //       },
  //     });
  //     setErrorMessage("");
  //     return;
  //   } catch (error) {
  //     setResponseJson(null);
  //     setErrorMessage("Error occurred while fetching data");
  //     console.error("Error:", error);
  //   } finally {
  //     setLoading(false);
  //     setButtonDisabled(false);
  //   }
  // };

  // const handleResumeUpload = (file) => {
  //   if (file) {
  //     setUploadedResume(file);

  //     if (!uploadedResume) {
  //       toast.error("Please select a file to upload", { autoClose: 2500 });
  //       return;
  //     }


  //   }
  // };
  
  const handleButtonClick = async (e) => {
    try {
      e.preventDefault();

      if (!jobPosition.trim()) {
        setErrorMessage("Please provide input data.");
        return;
      }
      if (!jobDescription.trim()) {
        setErrorMessage("Please provide input data.");
        return;
      }

      if (!uploadedResume.trim()) {
        setErrorMessage("Please upload a resume.");
        return;
      }

      setLoading(true);
      setButtonDisabled(true);
      
      const aiResumeResponse = await axios.post(
        `${API_URL}/api/v1/generate-summary/create-resume-forjd`,
        {
          jobPosition: jobPosition,
          jobDescription: jobDescription,
          uploadedResume: uploadedResume,
        },
        {
          withCredentials: true,
        }
      );

      if (!aiResumeResponse?.data?.success) {
        toast.error(aiResumeResponse?.data?.message, {
          autoClose: 2500,
        });
        return;
      }

      navigate(`/Template`, {
        state: {
          outputData: {
            ...user,
            resumeId: aiResumeResponse?.data?.data,
          },
        },
      });
      fetchUser()
      setErrorMessage("");
      setButtonDisabled(false);
    } catch (error) {
      console.log(error)
      // setResponseJson(null);
      setErrorMessage("Error occurred while fetching data");
      setLoading(false);
      setButtonDisabled(false);
    }
  };

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <Navbar />
        <div className="flex flex-col flex-grow mx-20 my-8 max-md:mx-2">
              {/* Header for Job Position */}
              <div className="flex flex-col flex-grow mx-20 my-8 max-md:mx-2">
              {/* Header for Job Position */}
              <div className="my-4 font-bold text-4xl max-md:text-4xl max-md:my-2 max-md:text-center">
                Help Me Create A Resume For Designation
              </div>

              {/* Job Position Input */}
              <div className="flex gap-5 py-2 pr-3.5 pl-9 border-2 border-black border-solid max-w-[940px] rounded-[10px] max-md:flex-wrap max-md:pl-5 max-md:gap-0">
                <input
                  className="flex-auto block w-full px-3 py-1 text-xl font-semibold text-gray-700 focus:outline-none max-md:w-full max-md:text-lg"
                  placeholder="Software Developer"
                  name="jobPosition"
                  value={jobPosition}
                  onChange={(e) => setJobPosition(e.target.value)}
                  required
                />
              </div>


              {/* Upload Resume Section */}
              <div className="my-4 font-bold text-4xl max-md:text-4xl max-md:my-2 max-md:text-center">
                Add Resume
              </div>

              {/* Job Description Textarea with Button Aligned to Bottom-Right */}
              <div className="flex flex-col gap-2 py-1 px-3 border-2 border-black border-solid max-w-[940px] rounded-[10px] relative max-md:pl-5 max-md:gap-0">
                <textarea
                  className="flex-auto block w-full px-3 py-2 h-[calc(100vw/4*3)] max-h-[300px] resize-y text-lg text-gray-700 focus:outline-none max-md:w-full max-md:text-md"
                  placeholder="Add Resume"
                  name="uploadedResume"
                  value={uploadedResume}
                  onChange={(e) => setUploadedResume(e.target.value)}
                  required
                />
              </div>


              {/* Header for Job Description */}
              <div className="my-4 font-bold text-4xl max-md:text-4xl max-md:my-2 max-md:text-center">
                Job Description
              </div>

              {/* Job Description Textarea with Button Aligned to Bottom-Right */}
              <div className="flex flex-col gap-2 py-1 px-3 border-2 border-black border-solid max-w-[940px] rounded-[10px] relative max-md:pl-5 max-md:gap-0">
                <textarea
                  className="flex-auto block w-full px-3 py-2 h-[calc(100vw/4*3)] max-h-[300px] resize-y text-lg text-gray-700 focus:outline-none max-md:w-full max-md:text-md"
                  placeholder="Job Description"
                  name="jobDescription"
                  value={jobDescription}
                  onChange={(e) => setJobDescription(e.target.value)}
                  required
                />

                {/* Button Aligned at Bottom Right */}
                <div className="flex justify-end mt-2">
                  <div
                    onClick={handleButtonClick}
                    disabled={buttonDisabled}
                    className="flex justify-center items-center px-6 bg-white rounded-full border border-black border-solid h-[100px] stroke-[1px] w-[100px] max-md:px-2 max-md:w-[50px] max-md:h-[50px]"
                  >
                    {loading ? (
                      <img
                        loading="lazy"
                        src={spinner}
                        alt="spinner"
                        className="w-12 h-12 animate-spin max-md:w-6 max-md:h-6"
                      />
                    ) : (
                      <img
                        loading="lazy"
                        src="https://cdn.builder.io/api/v1/image/assets/TEMP/395eff768bf5008660a50c05ecbf846f9939e43ba67ae2e3117c057669e9f3dc?apiKey=e64c88474a2c4290b8b66f50b4a39c22&"
                        alt="Software Developer Badge Icon"
                        className="aspect-square w-[50px] max-md:w-[25px]"
                      />
                    )}
                  </div>
                </div>
              </div>
            </div>
          {errorMessage && (
          <div className="text-red-500 max-md:text-xs">{errorMessage}</div>
        )}
      </div>

      <Footer />
    </div>
  );
};

// const mapStateToProps = (state) => {
//   return {
//     isAuthenticated: state.AuthReducer.isAuthenticated,
//   };
// };

export default CreateResumeForJD
