import React, { useEffect } from 'react';
import './Template3.css';
// import html2pdf from 'html2pdf.js';

const Template3 = ({ formData }) => {
    useEffect(() => {
        const updateHeight = () => {
            const container = document.querySelector('.template-container');
            const containerWidth = container.offsetWidth;
            const containerHeight = containerWidth * 1.41421; // A4 paper width-to-height ratio
            container.style.minHeight = `${containerHeight}px`;

        };

        const onClick =() =>{
            const container = document.querySelector(".template-container");
            container.scrollIntoView(".template-container", {behavior: 'smooth', block: 'start'});
      
          }
      
        // Call updateHeight initially and add event listener for window resize
        updateHeight();
        window.addEventListener('resize', updateHeight);

        // Cleanup function to remove event listener
        return () => {
            window.removeEventListener('resize', updateHeight);
        };
    }, []);
    return (
        <div className="template-container">
            <div className="text-center">
                {formData.Details && formData?.Details.map((detail, index) => (
                    <div key={index} className="profile-container">
                        {detail.profileImage && <img src={detail.profileImage} alt="Profile" className="profile-image" />}
                        <h2 className="profile-name">{detail.fullName}</h2>
                        <p>{detail.email}{detail.phone && ` | ${detail.phone}`}{detail.address && ` | ${detail.address}`}</p>
                        <p>{detail.website}{detail.linkedin && ` | ${detail.linkedin}`}{detail.github && ` | ${detail.github}`}</p>
                        <p dangerouslySetInnerHTML={{ __html: detail.about }} />
                    </div>
                ))}
            </div>
            {/* <p className="watermark">PREVIEW</p> */}
            {formData.eduFlag &&
                <div className="section">
                    {/* {formData.education.length !== 0 ?( */}
                    <h3 className="section-title3">Education</h3>
                    {/* ):(null)} */}
                    {formData?.education && formData?.education.map((edu, index) => (
                        <div key={index} className="section-content">
                            <p className="bold-text">{edu.degree} - {edu.college} - {edu.location}</p>
                            <p>{edu.fromDate} - {edu.toDate}</p>
                        </div>
                    ))}
                </div>
            }
            {formData.workFlag &&
                <div className="section">
                    {/* {formData.experience.length !== 0 ?( */}
                        <h2 className="section-title3">Work Experience</h2>
                        {/* ):(null)} */}
                    {formData?.experience && formData?.experience.map((exp, index) => (
                        <div key={index} className="section-content">
                            <p className="bold-text">{exp.jobRole} at {exp.companyName}</p>
                            <p>{exp.companyLocation}</p>
                            <p>{exp.fromDate} - {exp.toDate}</p>
                            <p dangerouslySetInnerHTML={{ __html: exp.jobDescription }} />
                        </div>
                    ))}
                </div>
            }
            {formData.skillFlag &&
                <div className="section">
                    {/* {formData.skills.length !== 0 ?( */}
                        <h2 className="section-title3">Skills</h2>
                        {/* ):(null)} */}
                    {formData?.skills && formData?.skills.map((skill, index) => (
                        <div key={index} className="section-content">
                            <p><span className="bold-text">{skill.title}</span> - {skill.skillList}</p>
                        </div>
                    ))}
                </div>
            }
            {formData.proFlag &&
                <div className="section">
                    {/* {formData.projects.length !== 0 ?(  */}
                        <h2 className="section-title3">Project Developed Or Handled</h2>
                        {/* ):(null)} */}
                    {formData?.projects && formData?.projects.map((prodit, index) => (
                        <div key={index} className="section-content">
                            <p className="bold-text">{prodit.title} {(prodit.title && prodit.stack) ? "-":""} {prodit.stack}</p>
                            <p dangerouslySetInnerHTML={{ __html: prodit.desc }} />
                        </div>
                    ))}
                </div>
            }
            {formData.achFlag &&
                <div className="section">
                    {/* {formData.achivawards.length !== 0 ?( */}
                        <h2 className="section-title3">Achievements & Awards</h2>
                        {/* ):(null)} */}
                    {formData?.achivawards && formData?.achivawards.map((ach, index) => (
                        <div key={index} className="section-content">
                            <p>{ach.achivawards}</p>
                        </div>
                    ))}
                </div>
            }
            {formData.addFlag &&
                <div className="section">
                    <h2 className="section-title3">Additional Information</h2>
                    {formData?.additionalInfo && formData?.additionalInfo.map((addinfo, index) => (
                        <div key={index} className="section-content">
                            <p>{addinfo.addInfo}</p>
                        </div>
                    ))}
                </div>
            }
        </div>
    );
};

export default Template3;
