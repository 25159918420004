import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import ResumeBuilder from "./ResumeBuilder";
import { API_URL } from "../apiConfig";
import docLoading from "../image/doc_loading.gif";
import axios from "axios";
import { toast } from "react-toastify";

const Template = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [selectedTemplate, setSelectedTemplate] = useState(null);
  const [resumeData, setResumeData] = useState();
  if (!location.state.resumeId)
    location.state.resumeId = location.state
      ? location.state.outputData.resumeId
      : null;
  const resumeId = location.state ? location.state.resumeId : null;
  // const resume_Id = location.state ? location.state.outputData.resumeId : null;
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const checkResumePresent = async () => {

      if (resumeId) {
        try {
          const respose = await axios.get(
            `${API_URL}/api/v1/resume/get-resume`,
            {
              params: {
                resumeId: resumeId,
              },
              withCredentials: true,
            }
          );
          if (!respose?.data?.success) {
            toast.error(respose?.data?.message, {
              autoClose: 2500,
            });
            navigate("/login", { replace: true });
            return;
          }
          setSelectedTemplate(respose?.data?.data?.templateKey)
          setResumeData({
            ...location.state.outputData,
            ...respose?.data?.data,
          });
          setIsLoading(false);
          return;
        } catch (error) {
          // if error throw the user to login where user cannot go to past page again refresh the page
          navigate("/login", { replace: true });
          toast.error("Resume not found", {
            autoClose: 2500,
          });
          setIsLoading(false);
          return;
        }
      } else {
        setIsLoading(false);
      }
    };
    checkResumePresent();
  }, []);

  //[resumeData, resumeId]

  // if (!resumeData) {
  //   return <div>Loading...</div>;
  // }

  // useEffect(() => {
  //   if(!localStorage.getItem("access")){
  //     navigate("/login", { replace: true });
  //   }
  // },[])

  return (
    <div>
      {!isLoading ? (
        <ResumeBuilder
          template={selectedTemplate}
          fetchDatas={{ outputData: resumeData }}
        />
      ) : (
        <div className="flex flex-wrap justify-center items-center h-[70vh]">
          <img
            src={docLoading}
            alt="Loading..."
            className="w-[150px] h-[150px]"
          />
        </div>
      )}
    </div>
  );
};


export default Template
