import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import RoopTable from "../../common/customTableR/table";
import { API_URL } from "../../../apiConfig";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";
import { FaRegEdit } from "react-icons/fa";

const ContactUs = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();
  const [selectUser, setSelectUser] = useState({
    id: "",
    email: "",
    userName:"",
    message:""
  });
  const [counter, setCounter] = useState(0);

  const [contacts, setContacts] = useState([]);

  const [showModal, setShowModal] = useState(false);
  const [message, setMessage] = useState("");

  const [page, setPage] = useState(1)


  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    handleSendMessageToUser();
  };

  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/contact-us/contacts?page=${page}`,
          {
            withCredentials: true,
          }
        );
        let count = 0;
        // Map the fetched payment details properly to the users' state
        setContacts(
          response?.data?.data?.map((el) => {
            return {
              count: count++,
              id: el?._id,
              userName: el?.userName,
              email: el?.email,
              phone: el?.phone,
              message: el?.message,
              status: `${el?.status}`,
              response: el?.response,
              replierName: el?.replierName,
              updatedAt: formatDate(el?.updatedAt) ?? null,
              createdAt: formatDate(el?.createdAt) ?? null,
            };
          })
        );
        // setContacts([{}]);
      } catch (error) {
        console.error("Error while fetching token details", error);
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [counter, setLoading,page]);

  const handleSendMessageToUser = async () => {
    try {
      //   contacts cannot be -ve

      if (!selectUser.id || !selectUser.email) {
        setToastData({
          type: "error",
          message: "Please select a user.",
        });
        return;
      }

      if (!message) {
        setToastData({
          type: "error",
          message: "Please enter a message.",
        });
        return;
      }

      setLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/contact-us/reply`,
        {
          message: message,
          email: selectUser?.email,
          id: selectUser?.id,
        },
        {
          withCredentials: true,
        }
      );

      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        setLoading(false);
        return;
      }

      setLoading(false);
      setToastData({
        type: "success",
        message: response?.data?.message,
      });
      setShowModal(false);
      setCounter((prev) => prev + 1);
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  const handleOpenUserResponse = async (id, email,userName,message,response) => {
    try {
      if (!email || !id) {
        setToastData({
          type: "error",
          message: "User email or id is required",
        });
        return;
      }

      // check plan present or not
      if (!contacts?.find((el) => el?.id === id)) {
        setToastData({
          type: "error",
          message: "Contacts not found",
        });
        return;
      }
      setSelectUser({
        id,
        email,
        userName,
        message,
      });
      setShowModal(true);
        setMessage(response)
    } catch (error) {
      console.log(error);
    }
  };

  const renderActions = (rowData) => (
    <div className="flex items-center justify-center space-x-3">
      <FaRegEdit
        className="text-red-500 cursor-pointer"
        onClick={() => {
          handleOpenUserResponse(rowData?.id, rowData?.email,rowData?.userName,rowData?.message,rowData?.response);
        }}
      />
    </div>
  );

  const headings = [
    { dataKey: "userName", title: "Name" },
    { dataKey: "email", title: "Email" },
    { dataKey: "phone", title: "Phone" },
    { dataKey: "message", title: "Message" },
    { dataKey: "status", title: "Status" },
    { dataKey: "response", title: "Response" },
    { dataKey: "replierName", title: "Replier Name" },
    { dataKey: "updatedAt", title: "Updated At" },
    { dataKey: "createdAt", title: "Created At" },
    { title: "Actions", dataKey: "id", render: renderActions },
  ];
  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={isLoading}
        data={contacts}
        itemsPerPage={5}
        headings={headings}
        hovered
        setPage={setPage}
      />

      <ReusableModal
        isOpen={showModal}
        onClose={() => {
          setShowModal(false);
          setSelectUser({
            id: "",
            email: "",
            userName:"",
            message:""
          })
          setMessage("")
        }}
        title="Reply to User Message"
      >
        <div className="max-h-[80vh] overflow-y-auto">
          {/* Form for sending the reply */}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* Display the user's name */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                User Name
              </label>
              <input
                type="text"
                name="userName"
                value={selectUser?.userName}
                readOnly
                className="w-full p-2 bg-gray-100 border rounded"
              />
            </div>

            {/* Original Message from User */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                User's Message
              </label>
              <textarea
                name="userMessage"
                value={selectUser?.message}
                readOnly
                className="w-full p-2 bg-gray-100 border rounded"
                rows={4}
              />
            </div>

            {/* Response from Team Member */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Your Response
              </label>
              <textarea
                name="responseMessage"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                className="w-full p-2 border rounded"
                rows={6}
                placeholder="Type your response here..."
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <CButton variant={ButtonType.Primary} type="submit">
                Send Reply
              </CButton>
            </div>
          </form>
        </div>
      </ReusableModal>
    </div>
  );
};

export default ContactUs;
