import React, { useCallback, useEffect, useState } from "react";
import axios from "axios";
import useGlobalStore from "../../../store/global";
import useGlobalLoaderStore from "../../../store/loader";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import RoopTable from "../../common/customTableR/table";
import { API_URL } from "../../../apiConfig";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";
import { FaRegEdit } from "react-icons/fa";

const TokensConfig = () => {
  const { setToastData } = useGlobalStore();
  const { isLoading, setLoading } = useGlobalLoaderStore();
  const [selectTokenConfig, setSelectedTokenConfigId] = useState("");
  const [counter, setCounter] = useState(0);

  const [tokens, setToken] = useState([]);

  const [btnLoading, setBtnLoading] = useState(false);

  const [formData, setFormData] = useState({
    createAIResume: 0,
    profile: 0,
    project: 0,
    workExperience: 0,
  });

  const [updateTokenConfig, setUpdateTokenConfig] = useState({
    createAIResume: 0,
    profile: 0,
    project: 0,
    workExperience: 0,
  });

  const [showTokenModal, setShowCreateTokenModal] = useState(false);
  const [showTokenConfigModal, setShowUpdateTokenConfigModal] = useState(false);

  // Handle form submission
  const handleSubmit = (e) => {
    e.preventDefault();
    handleCreateTokenConfig();
  };

  const handleSubmitUpdateTokenConfig = async () => {
    try {
      if (
        formData?.createAIResume < 0 ||
        formData?.profile < 0 ||
        formData?.project < 0 ||
        formData?.workExperience < 0
      ) {
        setToastData({
          type: "error",
          message: "Tokens should be greater than zero",
        });
        return;
      }

      setBtnLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/config/update-token`,
        {
          ...updateTokenConfig,
          id: selectTokenConfig,
        },
        { withCredentials: true }
      );
      if (response?.data?.success) {
        setToastData({
          type: "success",
          message: "Tokens Config updated successfully",
        });
        setShowUpdateTokenConfigModal(false);
        setCounter((prev) => prev + 1);
        setUpdateTokenConfig({
          createAIResume: 0,
          project: 0,
          workExperience: 0,
          profile: 0,
        });
        setSelectedTokenConfigId("");
        setBtnLoading(false)
        return;
      } else {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        setBtnLoading(false)
        return;
      }
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  // Handle form submission
  const handleUpdateTokenConfig = (e) => {
    e.preventDefault();
    handleSubmitUpdateTokenConfig();
  };

  // Handle input changes
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleUpdateTokenConfigChange = (e) => {
    const { name, value } = e.target;
    setUpdateTokenConfig((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  useEffect(() => {
    const fetchUsers = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/v1/config/token`, {
          withCredentials: true,
        });
        // Map the fetched payment details properly to the users' state
        setToken([
          {
            id:response?.data?.data?.id,
            createAIResume: response?.data?.data?.createAIResume || 0,
            profile: response?.data?.data?.profile || 0,
            project: response?.data?.data?.project || 0,
            workExperience: response?.data?.data?.workExperience || 0,
            updatedBy: response?.data?.data?.updatedBy || "",
            createdBy: response?.data?.data?.createdBy || "",
            updatedAt: formatDate(response?.data?.data?.updatedAt) ?? null,
            createdAt: formatDate(response?.data?.data?.createdAt) ?? null,
          },
        ]);
        setUpdateTokenConfig({
          createAIResume: response?.data?.data?.createAIResume || 0,
          project: response?.data?.data?.project || 0,
          workExperience: response?.data?.data?.workExperience || 0,
          profile: response?.data?.data?.profile || 0,
        });
      } catch (error) {
        console.error("Error while fetching token details", error);
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, [counter, setLoading]);

  const handleCreateTokenConfig = async () => {
    try {
      //   tokens cannot be -ve

      if (
        formData?.createAIResume < 0 ||
        formData?.profile < 0 ||
        formData?.project < 0 ||
        formData?.workExperience < 0
      ) {
        setToastData({
          type: "error",
          message: "Tokens should be greater than zero",
        });
        return;
      }

      setLoading(true);
      const response = await axios.post(
        `${API_URL}/api/v1/config/create-token`,
        formData,
        {
          withCredentials: true,
        }
      );

      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        setLoading(false);
        return;
      }

      setLoading(false);
      setToastData({
        type: "success",
        message: response?.data?.message,
      });
      setShowCreateTokenModal(false);
      setCounter((prev) => prev + 1);
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  const handleEditPlan = async (id) => {
    try {
      if (!id) {
        setToastData({
          type: "error",
          message: "Token config ID is required",
        });
        return;
      }

      // check plan present or not
      if (!tokens?.find((el) => el?.id === id)) {
        setToastData({
          type: "error",
          message: "Token config not found",
        });
        return;
      }
      setSelectedTokenConfigId(id);
      setShowUpdateTokenConfigModal(true);
    } catch (error) {
      console.log(error);
    }
  };

  const renderActions = (rowData) => (
    <div className="flex items-center justify-center space-x-3">
      <FaRegEdit
        className="text-red-500 cursor-pointer"
        onClick={() => {
          handleEditPlan(rowData?.id);
        }}
      />
    </div>
  );

  const headings = [
    { dataKey: "createAIResume", title: "Create AI Resume" },
    { dataKey: "project", title: "Project Summary" },
    { dataKey: "workExperience", title: "Work Experience Summary" },
    { dataKey: "profile", title: "Profile Summary" },
    { dataKey: "createdAt", title: "Created At" },
    { dataKey: "updatedAt", title: "Updated At" },
    { dataKey: "createdBy", title: "Created By" },
    { dataKey: "updatedBy", title: "Updated By" },
    { title: "Actions", dataKey: "id", render: renderActions },
  ];

  const mainActions = [
    {
      label: "Create AI Token Config",
      onClick: () => setShowCreateTokenModal(true),
    },
  ];

  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={isLoading}
        data={tokens}
        itemsPerPage={5}
        headings={headings}
        hovered
        mainActions={mainActions}
      />

      <ReusableModal
        isOpen={showTokenModal}
        onClose={() => {
          setShowCreateTokenModal(false);
        }}
        title="Create AI Token Config"
      >
        <div className="max-h-[80vh] overflow-y-auto">
          {" "}
          {/* Set a max height and enable scrolling */}
          <form onSubmit={handleSubmit} className="space-y-4">
            {/* createAIResume */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Create AI Resume
              </label>
              <input
                type="number"
                name="createAIResume"
                value={formData.createAIResume}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Profile */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Profile Summary
              </label>
              <input
                type="number"
                name="profile"
                value={formData.profile}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Project */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Project Summary
              </label>
              <input
                type="number"
                name="project"
                value={formData.project}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Work Experience */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Work Experience Summary
              </label>
              <input
                type="number"
                name="workExperience"
                value={formData.workExperience}
                onChange={handleChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <CButton variant={ButtonType.Primary} type="submit">
                Submit
              </CButton>
            </div>
          </form>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={showTokenConfigModal}
        onClose={() => {
          setShowUpdateTokenConfigModal(false);
          setUpdateTokenConfig({
            createAIResume: 0,
            project: 0,
            workExperience: 0,
            profile: 0,
          });
        }}
        title="Update AI Token Config"
      >
        <div className="max-h-[80vh] overflow-y-auto">
          {" "}
          {/* Set a max height and enable scrolling */}
          <form onSubmit={handleUpdateTokenConfig} className="space-y-4">
            {/* createAIResume */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Create AI Resume
              </label>
              <input
                type="number"
                name="createAIResume"
                value={updateTokenConfig.createAIResume}
                onChange={handleUpdateTokenConfigChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Profile */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Profile Summary
              </label>
              <input
                type="number"
                name="profile"
                value={updateTokenConfig.profile}
                onChange={handleUpdateTokenConfigChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Project */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Project Summary
              </label>
              <input
                type="number"
                name="project"
                value={updateTokenConfig.project}
                onChange={handleUpdateTokenConfigChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Work Experience */}
            <div>
              <label className="block mb-2 text-sm font-medium text-gray-700">
                Work Experience Summary
              </label>
              <input
                type="number"
                name="workExperience"
                value={updateTokenConfig.workExperience}
                onChange={handleUpdateTokenConfigChange}
                className="w-full p-2 border rounded"
              />
            </div>

            {/* Submit Button */}
            <div className="flex justify-end">
              <CButton
                variant={ButtonType.Primary}
                type="submit"
                loading={btnLoading}
              >
                Submit
              </CButton>
            </div>
          </form>
        </div>
      </ReusableModal>
    </div>
  );
};

export default TokensConfig;
