import React, { useState, useEffect } from "react";
import { closeAlert } from "../reducer/Actions";

const Alert = (props) => {
    // const [classAlert, setClassAlert] = useState("alert");

    // // Close the alert after 2 seconds
    // useEffect(() => {
    //     const timeoutId = setTimeout(() => {
    //         setClassAlert("alert d-none");
    //         props.closeAlert();
    //     }, 2000);

    //     // Clean up the timeout
    //     return () => clearTimeout(timeoutId);
    // }, [props]);

    // const handleClose = () => {
    //     setClassAlert("alert d-none");
    //     props.closeAlert();
    // };

    return (
        <></>
        // <div className={classAlert}>
        //     <div className="d-flex ">
        //         <div className="toast-body">{props.message}</div>
        //         <button className="m-auto btn-close me-2" type="button" onClick={handleClose}></button>
        //     </div>
        // </div>
    );
};

export default Alert
