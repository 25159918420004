import React from "react";
import { FaSpinner } from "react-icons/fa";
import { ButtonType } from "./buttonType";

const buttonStyles = {
  [ButtonType.Confirm]: "admin-btn btn-confirmation",
  [ButtonType.Icon]:
    "flex items-center justify-center h-10 px-3 py-2 m-1 text-white text-sm transition-colors duration-300 transform bg-primary rounded-full hover:bg-white hover:text-primary focus:outline-none focus:bg-blue-400 md:w-auto w-10",
  [ButtonType.Outlined]: "admin-btn btn-outlined",
  [ButtonType.Primary]: "admin-btn btn-primary !py-2.5",
  [ButtonType.Text]:
    "text-primary text-sm transition-colors duration-300 transform hover:text-blue-600 focus:outline-none",
  [ButtonType.Warning]: "admin-btn btn-warning",
};

const CButton = ({
  variant,
  onClick,
  disabled,
  loading,
  className,
  children,
  type,
}) => {
  return (
    <button
      type={type}
      onClick={onClick}
      disabled={disabled || loading}
      className={`${buttonStyles[variant]} ${className} ${
        loading ? "flex items-center justify-center" : ""
      } ${loading ? "opacity-50 cursor-not-allowed" : ""} ${
        disabled ? "pointer-events-none" : ""
      }`}
    >
      {loading ? (
        <FaSpinner className="w-5 h-5 text-white animate-spin" />
      ) : (
        children
      )}
    </button>
  );
};

export default CButton;
