import React, { useState, useEffect } from "react";
import { Menu } from "@headlessui/react";
import {
  HiDotsVertical,
  HiChevronLeft,
  HiChevronRight
} from "react-icons/hi";

import noDataImage from "../../../image/svg/noData.svg"
import { FaSpinner } from "react-icons/fa";
import CButton from "../button/button";
import { ButtonType } from "../button/buttonType";
import { CSVLink } from "react-csv";


const RoopTable = ({
  data = [],
  itemsPerPage,
  actions = [],
  mainActions = [],
  headings,
  striped = false,
  bordered = false,
  hovered = false,
  filterable = false,
  loading = false,
  setPage,
  csvExport,
  csvFileName = "data.csv",
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [showFilterModal, setShowFilterModal] = useState(false);
  const [currentFilterColumn, setCurrentFilterColumn] = useState("");
  const [currentOperator, setCurrentOperator] = useState("contains");
  const [currentFilterValue, setCurrentFilterValue] = useState("");

  const [filterColumn, setFilterColumn] = useState("");
  const [operator, setOperator] = useState("contains");
  const [filterValue, setFilterValue] = useState("");
  const [options, setOptions] = useState([]);
  const [isFilterApplied, setIsFilterApplied] = useState(false);
  const [showExportModal, setShowExportModal] = useState(false);


  useEffect(() => {
    const columnOptions = headings.map((heading) => ({
      value: heading.dataKey,
      label: heading.title,
    }));
    setOptions(columnOptions);
  }, [headings]);

  useEffect(() => {
    setCurrentPage(1);
    setIsFilterApplied(!!filterColumn && !!filterValue);
  }, [filterColumn, filterValue, searchTerm]);

  useEffect(() => {
    setCurrentFilterColumn(filterColumn);
    setCurrentOperator(operator);
    setCurrentFilterValue(filterValue);
  }, [filterColumn, filterValue, operator, showFilterModal]);

  const csvHeaders = headings.map((heading) => ({
    label: heading.title,
    key: heading.dataKey,
  }));

  const handleExportClick = () => {
      document.getElementById("csvLink")?.click();
  };

  const filteredMembers = data
    ? data
        .filter((member) =>
          Object.values(member).some((val) =>
            String(val).toLowerCase().includes(searchTerm.toLowerCase())
          )
        )
    : []; 

  const totalPages = Math.ceil(filteredMembers.length / itemsPerPage);
  const displayedData = filteredMembers.slice(
    (currentPage - 1) * itemsPerPage,
    currentPage * itemsPerPage
  );

  const getNestedValue = (obj, path) => {
    return path.split(".").reduce((value, key) => value?.[key] ?? "", obj);
  };

  const rowClasses = (index) => {
    let classes = "";
    if (striped && index % 2 === 0) classes += "bg-slate-900 ";
    if (bordered) classes += "border ";
    if (hovered) classes += "hover:bg-primary hover:bg-opacity-10 ";
    return classes.trim();
  };


  const truncateString = (str, maxLength) => {
    if (str.length > maxLength) {
      return str.substring(0, maxLength) + "...";
    }
    return str;
  };

  return (
    <div className={`rounded-lg p-4 bg-white text-black`}>
      <div
        className={`flex items-center mb-4 ${
          data.length < 1 ? "justify-end" : "justify-between"
        }`}
      >
        {data.length > 0 && (
          <div className="flex">
            <input
              type="text"
              placeholder="Search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              className="bg-[#F1F1F1] text-sm rounded-lg block p-2.5 placeholder-gray-400 text-black w-96"
            />
          </div>
        )}

        <div className="flex">
          {mainActions.map((action, index) => (
            <CButton
              key={index}
              className="mr-2"
              variant={ButtonType.Primary}
              onClick={action.onClick}
            >
              {action.label}
            </CButton>
          ))}
          {data.length > 0 && csvExport && (
            <div className="flex space-x-2">
              <CButton variant={ButtonType.Primary} onClick={handleExportClick}>
                Export to CSV
              </CButton>
              <CSVLink
                id="csvLink"
                data={data}
                headers={csvHeaders}
                filename={csvFileName}
                className="hidden"
              />
            </div>
          )}
        </div>
      </div>
      {data.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-64">
          {loading ? (
            <FaSpinner className="w-10 h-10 text-5xl animate-spin text-primary" />
          ) : (
            <div className="flex flex-col items-center justify-center ">
              <img
                src={noDataImage}
                alt="No Data Found"
                className="mb-4 h-36"
              />
              <p className="text-lg font-semibold">No data to display.</p>
            </div>
          )}
        </div>
      ) : (
        <>
          <div className="overflow-x-auto">
            <table className="min-w-full overflow-hidden bg-transparent rounded-lg">
              <thead className="text-black bg-white text-nowrap ">
                <tr>
                  {headings.map((heading, index) => (
                    <th
                      key={index}
                      className="px-4 py-2 first:rounded-tl-lg last:rounded-tr-lg"
                    >
                      {heading.title}
                    </th>
                  ))}
                  {actions.length > 0 && (
                    <th className="px-4 py-2 first:rounded-tl-lg last:rounded-tr-lg">
                      Actions
                    </th>
                  )}
                </tr>
              </thead>
              <tbody>
                {displayedData.map((row, rowIndex) => (
                  <tr key={rowIndex} className={rowClasses(rowIndex)}>
                    {headings.map((heading, colIndex) => (
                      <td
                        key={colIndex}
                        className="px-4 py-2 text-sm text-center first:rounded-tl-lg last:rounded-tr-lg text-nowrap"
                      >
                        {heading.render
                          ? heading.render(row)
                          : truncateString(
                              getNestedValue(row, heading.dataKey),
                              30
                            )}
                      </td>
                    ))}
                    {actions.length > 0 && (
                      <td className="px-4 py-2 text-center">
                        <Menu as="div" className="inline-block ">
                          <Menu.Button className="flex items-center space-x-2">
                            <HiDotsVertical className="w-5 h-5 text-black" />
                          </Menu.Button>
                          <Menu.Items className="absolute right-0 z-20 w-48 mt-2 bg-white border border-gray-200 divide-y divide-gray-100 rounded-md shadow-lg focus:outline-none">
                            {actions.map((action, actionIndex) => (
                              <Menu.Item key={actionIndex}>
                                {({ active }) => (
                                  <button
                                    onClick={() => action.onClick(row)}
                                    className={`${
                                      active
                                        ? "bg-primary text-white"
                                        : "text-black"
                                    } flex w-full items-center px-4 py-2 text-sm`}
                                  >
                                    {action.label}
                                  </button>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Menu>
                      </td>
                    )}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {data.length > 0 && (
            <div className="flex items-center justify-between mt-4">
              <button
                onClick={() => setCurrentPage((prev) => Math.max(prev - 1, 1))}
                disabled={currentPage === 1}
                className={`flex items-center space-x-2 text-sm ${
                  currentPage === 1 ? "opacity-0 " : ""
                }`}
              >
                <HiChevronLeft />
                <span>Previous</span>
              </button>
              <span className="text-sm">
                Page {currentPage} of {totalPages}
              </span>
              <button
                onClick={() =>{
                  setCurrentPage((prev) => Math.min(prev + 1, totalPages))
                  setPage((prev) => prev + 1)
                }
                }
                disabled={currentPage === totalPages}
                className={`flex items-center space-x-2 text-sm ${
                  currentPage === totalPages ? "opacity-0 " : ""
                }`}
              >
                <span>Next</span>
                <HiChevronRight />
              </button>
            </div>
          )}
        </>
      )}
    </div>
  );
};

export default RoopTable;
