import React, { useEffect, useState } from "react";
import Navbar from "../Component/navbar.jsx";
import Footer from "../Component/footer.jsx";
import "./Contactus.css";
import Alert from "@mui/material/Alert";
import { FaSpinner } from "react-icons/fa";
import {
  FaFacebookSquare,
  FaInstagramSquare,
  FaLinkedin,
  FaTwitterSquare,
} from "react-icons/fa";
import { toast } from "react-toastify";
import axios from "axios";
import { API_URL } from "../apiConfig.js";
import { emailRegex, phoneNumberRegex } from "../lib/utils.ts";
import {Helmet} from "react-helmet";

const Contactus = () => {
  const [status, setStatus] = useState(false);

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    description: "",
    loader: false,
  });

  const handleFocus = (e) => {
    const parent = e.target.parentNode;
    parent.classList.add("focus");
  };

  const handleBlur = (e) => {
    const parent = e.target.parentNode;
    if (e.target.value === "") {
      parent.classList.remove("focus");
    }
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleContactUs = async () => {
    try {
      const { name, email, phone, description } = formData;

      if (!name || !email || !phone || !description) {
        toast.error("All fields are required!", { autoClose: 2500 });
        return;
      }

      if (!emailRegex.test(email)) {
        toast.error("Please enter a valid email address!", { autoClose: 2500 });
        return;
      }

      if (!phoneNumberRegex.test(phone)) {
        toast.error("Please enter a valid phone number!", { autoClose: 2500 });
        return;
      }

      setFormData((prev) => ({
        ...prev,
        loader: true,
      }));

      const response = await axios.post(
        `${API_URL}/api/v1/contact-us/contact`,
        {
          userName: name,
          email: email,
          phone: phone,
          message: description,
        }
      );

      setFormData((prev) => ({
        ...prev,
        loader: false,
      }));

      if (!response.data.success) {
        toast.error(response.data.message, { autoClose: 2500 });
        return
      } 
      setFormData({
        name: "",
        email: "",
        phone: "",
        description: "",
        loader: false,
      });
      setStatus(true);
      setTimeout(() => {
        setStatus(false);
      }, 10000);
      return;
    } catch (error) {
      setFormData((prev) => ({
        ...prev,
        loader: false,
      }));
      console.log(error);
      toast.error(error?.response?.data?.message, { autoClose: 2500 });
      return;
    }
  };

  function disableBtn() {
    return (
      formData.name === "" ||
      formData.email === "" ||
      formData.phone === "" ||
      formData.description === "" ||
      !phoneNumberRegex.test(formData.phone) ||
      !emailRegex.test(formData.email)
    );
  }

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="flex flex-col min-h-screen bg-white">
      <>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Contact Us - ResumeBeen | We're Here to Help You Succeed</title>
            <link rel="canonical" href="https://resumebeen.com/contact-us" />
            <meta property="og:site_name" content="ResumeBeen"></meta>
            <meta property="og:url" content="https://resumebeen.com/contact-us"></meta>
            <meta property="og:locale" content="en_EN"></meta>

            <meta name="twitter:card" content="summary_large_image"></meta>
            <meta name="twitter:site" content="@resumebeen"></meta>
            <meta name="twitter:creator" content="@resumebeen"></meta>

            <meta property="og:type" content="website"></meta>

            <meta name="title" content="Contact Us - ResumeBeen | We're Here to Help You Succeed"></meta>
            <meta property="og:title" content="Contact Us - ResumeBeen | We're Here to Help You Succeed"></meta>


            <meta name="description" content="Get in touch with the ResumeBeen team! Whether you have questions, need support, or want to learn more about our AI-driven resume-building tools, we’re here to help you on your path to career success."></meta>
            <meta property="og:description" content="Get in touch with the ResumeBeen team! Whether you have questions, need support, or want to learn more about our AI-driven resume-building tools, we’re here to help you on your path to career success."></meta>

            <meta name="keywords" content="ResumeBeen contact, contact ResumeBeen, customer support, resume builder support, resume help, career support, resume questions, ResumeBeen team, resume assistance, resume builder inquiries, AI resume tool support"></meta>
            <meta property="og:keywords" content="ResumeBeen contact, contact ResumeBeen, customer support, resume builder support, resume help, career support, resume questions, ResumeBeen team, resume assistance, resume builder inquiries, AI resume tool support"></meta>

        </Helmet>      
      </>

      <Navbar />
      <div className="container">
        <span className="big-circle"></span>
        <img src="img/shape.png" className="square" alt="" />
        <div className="form">
          <div className="contact-info">
            <h3 className="title">Contact Us</h3>
            <p className="text">
              If you have any questions, comments or requests please email us at
              <span className="text-blue-600"> support@techbeen.co</span>
              <br />
              Alternatively you can submit your query via this form and we will
              get back to you as soon as we can.
            </p>

            <div className="info">
              <div className="from">
                <i className="fas fa-map-marker-alt"></i>
                <h3 className="title">Address: </h3>
                <p className="text">
                  TECHBEEN IT SOLUTIONS PRIVATE LIMITED
                  <br />
                  H-213, Sector 63, NOIDA, Gautam Buddha Nagar, Uttar Pradesh,
                  India 201301
                  <br /> Mumbai Office: Vidya Vihar East, Mumbai-400077
                </p>
              </div>
              <div className="information">
                <i className="fas fa-envelope"></i> &nbsp;&nbsp;
                <p> </p>
              </div>
            </div>

            <div className="social-media">
              <p>Connect with us :</p>
              <div className="social-icons">
                <a href="https://www.facebook.com/hirebeen/">
                  <FaFacebookSquare size="3x" />
                </a>
                <a href="https://www.instagram.com/hirebeenai/">
                  <FaInstagramSquare size="2x" />
                </a>
                <a href="https://www.linkedin.com/company/hirebeen/">
                  <FaLinkedin size="2x" />
                </a>
                <a href="https://x.com/hirebeenai/">
                  <FaTwitterSquare size="2x" />
                </a>
              </div>
            </div>
          </div>

          <div className="contact-form">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <div className="m-8">
              {status ? (
                <Alert severity="success">
                  Thank you for contacting us! Our team will reach out to you
                  soon!
                </Alert>
              ) : (
                <></>
              )}

              <h3 className="title">Contact us</h3>
              <div className="input-container">
                <input
                  type="text"
                  name="name"
                  className="input"
                  value={formData.name}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <label>User Name</label>
                <span>User Name</span>
              </div>
              <div className="input-container">
                <input
                  type="email"
                  name="email"
                  className="input"
                  value={formData.email}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <label>Email</label>
                <span>Email</span>
              </div>
              <div className="input-container">
                <input
                  type="tel"
                  name="phone"
                  maxLength="15"
                  className="input"
                  value={formData.phone}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                />
                <label>Phone</label>
                <span>Phone</span>
              </div>
              <div className="input-container textarea">
                <textarea
                  name="description"
                  className="input"
                  value={formData.description}
                  onChange={handleChange}
                  onFocus={handleFocus}
                  onBlur={handleBlur}
                ></textarea>
                <label>Message</label>
                <span>Message</span>
              </div>
              <button
                type="submit"
                className={`w-full px-6 py-2 text-sm leading-none transition duration-300 bg-white border-2 rounded outline-none cursor-pointer flex justify-center items-center text-teal-500 border-gray-100 hover:bg-transparent
                }`}
                onClick={handleContactUs}
                // disabled={disableBtn()}
              >
                {formData.loader ? (
                  <FaSpinner className="animate-spin" size="16" />
                ) : (
                  "Send"
                )}
              </button>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contactus;
