import React, { useState } from 'react';
import { ReactComponent as DeleteIcon } from "../image/icons/delsolid.svg";
import AlertComponent from './AlertComponent';

const DynamicSkillsInputs = ({ inputs, setInputs, setDataSaved }) => {
  const [currentSkill, setCurrentSkill] = useState({ value: "", index: null });
  const [showAlert, setShowAlert] = useState(false);
  const [indexToRemove, setIndexToRemove] = useState(null);
  const [accordionIndex, setAccordionIndex] = useState(0);

  const handleChange = (index, event) => {
    const updatedInputs = [...inputs];
    updatedInputs[index][event.target.name] = event.target.value;
    setInputs(updatedInputs);
    setDataSaved(false);
  };

  const handleSkillChange = (index, event) => {
    setCurrentSkill((prev) => ({ ...prev, value: event.target.value, index }));
  };

  const handleSkillKeyDown = (index, event) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      addSkill(index);
    }
  };

  const addSkill = (index) => {
    if (currentSkill.value.trim() !== '') {
      const updatedInputs = [...inputs];
      const skillList = updatedInputs[index].skillList.split(', ').filter(skill => skill !== '');
      
      // Check if skill already exists
      if (!skillList.includes(currentSkill.value.trim())) {
        skillList.push(currentSkill.value.trim());
        updatedInputs[index].skillList = skillList.join(', ');
        setInputs(updatedInputs);
      }

      // Clear input only if the skill was added
      setCurrentSkill({ value: "", index: null });
      
    }
  };

  const addSection = () => {
    setInputs([...inputs, { title: '', skillList: '' }]);
    setAccordionIndex(inputs.length);
    setCurrentSkill({ value: "", index: null });
  };

  const removeSection = (index) => {
    setIndexToRemove(index);
    setShowAlert(true);
    setDataSaved(false);

  };

  const confirmRemoveSection = (index) => {
    const updatedInputs = [...inputs];
    updatedInputs.splice(index, 1);
    setInputs(updatedInputs);
    setShowAlert(false);
    if (indexToRemove === accordionIndex) {
      setAccordionIndex(updatedInputs.length > 0 ? 0 : null);
    }
  };

  const cancelRemoveSection = () => {
    setShowAlert(false);
  };

  const removeSkill = (sectionIndex, skillToRemove) => {
    const updatedInputs = [...inputs];
    const skills = updatedInputs[sectionIndex].skillList.split(', ').filter(skill => skill !== '');
    const filteredSkills = skills.filter(skill => skill !== skillToRemove);
    updatedInputs[sectionIndex].skillList = filteredSkills.join(', ');
    setInputs(updatedInputs);
  };

  const toggleAccordion = (index) => {
    if (accordionIndex === index && inputs.length > 1) {
      setAccordionIndex(null);
    } else {
      setAccordionIndex(index);
    }
  };

  return (
    <div>
      {inputs?.length > 0 && inputs?.map((input, index) => (
        <div key={index} className="accordion-card cursor-pointer rounded-lg m-3 pl-3 pr-[30px] py-2 border-gray-300 bg-gray-100 border-1">
          <div onClick={() => toggleAccordion(index)} className='flex items-center justify-between text-lg text-gray-600'>
            {input.title === "" ? (`Skills ${index + 1}`) : (input.title.length > 20 ? `${input.title.slice(0, 20)}...` : input.title)}
            {inputs.length > 1 && (
              <button onClick={() => removeSection(index)} className="text-white ">
                <DeleteIcon className='w-5 h-5 hover:fill-red-600' data-tooltip-id="my-tooltip" data-tooltip-content="Delete" />
              </button>
            )}
          </div>
          <AlertComponent
            show={showAlert}
            onConfirm={confirmRemoveSection}
            onCancel={cancelRemoveSection}
          />
          {accordionIndex === index && (
            <div className='text-sm text-black'>
              <div className='flex flex-wrap'>
                <div className='w-full mb-2 md:w-1/2 md:pr-2'>
                  <label>Title</label><br />
                  <input
                    type="text"
                    name="title"
                    value={input.title}
                    onChange={(event) => handleChange(index, event)}
                    placeholder="Title"
                    className="w-full p-2 border rounded-xl"
                  />
                </div>
                <div className='w-full'>
                  <label>Skills</label><br />
                  <input
                    type="text"
                    name="skillList"
                    value={currentSkill.index === index ? currentSkill.value : ''}
                    onChange={(event) => handleSkillChange(index, event)}
                    onKeyDown={(event) => handleSkillKeyDown(index, event)}
                    placeholder="Type a skill and press Enter to add"
                    className="w-full p-2 border rounded-xl"
                  />
                </div>
              </div>
              <div className="flex flex-wrap gap-2 my-2">
                {input.skillList.split(', ').filter(skill => skill !== '').map((skill, skillIndex) => (
                  <div key={skillIndex} className="bg-[#1d6362] text-white border-[1px] rounded-lg border-[#121224] w-fit px-2 py-[6px]">
                    {skill}
                    <button onClick={() => removeSkill(index, skill)} className="ml-1 text-xs text-gray-100">&#x2715;</button>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      ))}
      <div onClick={addSection} className="flex justify-center p-2 m-3 text-lg font-semibold text-white delay-700 bg-orange-500 cursor-pointer rounded-xl">
        + Add Skills
      </div>
    </div>
  );
};

export default DynamicSkillsInputs;
