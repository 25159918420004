import {
  MdOutlineSpaceDashboard,
  MdAdminPanelSettings,
  MdOutlineRocketLaunch,
  MdOutlineAnalytics,
  MdOutlineMail,
} from "react-icons/md";
import { CiUser, CiShop, CiDatabase } from "react-icons/ci";
import Dashboard from "../../modules/dashboard";
import Admin from "../../modules/aam";
import User from "../../modules/uam";
import Plans from "../../modules/plan";
import PaymentDetails from "../../modules/payments";
import Resume from "../../modules/resume";
import TermsAndConditions from "../../modules/terms-conditions";
import PrivatePolicy from "../../modules/private-policy";
import TokensConfig from "../../modules/tokens";
import ContactUs from "../../modules/contact-us";
import Newsletter from "../../modules/newsletter";
import AIResume from "../../modules/ai-resumes";

export const modules = [
  {
    name: "Dashboard",
    roles: ["admin", "master", "normal"],
    icon: MdOutlineSpaceDashboard,
    component: Dashboard,
  },
  {
    name: "Admin Management",
    roles: ["admin", "normal", "master"],
    icon: MdAdminPanelSettings,
    component: Admin,
  },
  {
    name: "UAM",
    roles: ["admin", "normal", "master"],
    icon: CiUser,
    component: User,
  },
  {
    name: "Plan",
    roles: ["admin", "normal", "master"],
    icon: MdOutlineAnalytics,
    component: Plans,
  },
  {
    name: "Payments",
    roles: ["admin", "normal", "master"],
    icon: MdOutlineAnalytics,
    component: PaymentDetails,
  },
  {
    name: "Resumes",
    roles: ["admin", "normal", "master"],
    icon: CiDatabase,
    component: Resume,
  },
  {
    name: "Terms And Conditions",
    roles: ["admin", "normal", "master"],
    icon: CiShop,
    component: TermsAndConditions,
  },
  {
    name: "Privacy Policy",
    roles: ["admin", "normal", "master"],
    icon: CiShop,
    component: PrivatePolicy,
  },
  {
    name: "Tokens",
    roles: ["admin", "normal", "master"],
    icon: CiShop,
    component: TokensConfig,
  },
  {
    name: "Contact Us",
    roles: ["admin", "normal", "master"],
    icon: MdOutlineMail,
    component: ContactUs,
  },
  {
    name: "Newsletter",
    roles: ["admin", "normal", "master"],
    icon: MdOutlineRocketLaunch,
    component: Newsletter,
  },
  {
    name: "AI Resumes",
    roles: ["admin", "normal", "master"],
    icon: MdOutlineRocketLaunch,
    component: AIResume,
  }
];
