import React, { useCallback, useEffect, useState } from "react";
import Select from "react-select";
import { useForm, Controller } from "react-hook-form";
// import { FaTrash, FaEdit, FaShieldAlt } from "react-icons/fa";
// import Switch from "react-switch";
// import Loading from "@/components/common/Loader/Loader";
// import CustomSwitch from "@/components/common/customSwitch/customSwitch";
// import useAuthStore from "@/store/auth";
import axios from "axios";
import RoopTable from "../../common/customTableR/table";
import useGlobalStore from "../../../store/global";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";
import { roleOptions } from "./interface";
import { API_URL } from "../../../apiConfig";

const Admin = () => {
  const [members, setMembers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const { setToastData } = useGlobalStore();
  const [counter, setCounter] = useState(0);
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm();

  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const response = await axios.get(
          `${API_URL}/api/v1/admin/admins`,
          { withCredentials: true }
        );
        if (response && response.data.success) {
          const formattedUsers = response.data.data.map(
            (user) => ({
              ...user,
              createdAt: formatDate(user?.createdAt),
              updatedAt: formatDate(user?.updatedAt),
            })
          );
          let count = 1;
          setMembers(
            formattedUsers.map((el) => ({
              id: el._id,
              count: count++,
              name: el.name,
              email: el.email,
              createdAt: el.createdAt,
              updatedAt: el.updatedAt,
              numberOfResetPassword: el.numberOfResetPassword,
              role: el.role,
              status: el.status,
            }))
          );
        }
      } catch (error) {
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [counter]);

  const handleAddAdmin = async (data) => {
    try {
      const input = {
        name: data.name,
        email: data.email,
        password: data.password,
        role: data.role,
      };
      if (!input.role?.value) {
        return;
      }

      const resposne = await axios.post(
        `${API_URL}/api/v1/admin/register`,
        {
          email: input?.email,
          name: input?.name,
          password: input?.password,
          role: input?.role?.value,
        },
        { withCredentials: true }
      );

      if (resposne.data.success) {
        setIsAddModalOpen(false);
        setCounter((prev) => prev + 1);
        setToastData({ message: "Admin added successfully", type: "success" });
      } else {
        throw new Error(resposne.data.message);
      }

      // setIsAddModalOpen(false);
      // setCounter((prev) => prev + 1);
      // setToastData({ message: "Admin added successfully", type: "success" });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    }
  };

  const mainActions = [
    {
      label: "Add Admin",
      onClick: () => setIsAddModalOpen(true),
    },
  ];

  const headings = [
    { title: "Count", dataKey: "count" },
    { title: "Name", dataKey: "name" },
    { title: "Email", dataKey: "email" },
    { title: "Role", dataKey: "role" },
    { title: "status", dataKey: "status" },
    { title: "Created At", dataKey: "createdAt" },
    { title: "Updated At", dataKey: "updatedAt" },

  ];

  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={loading}
        data={members}
        itemsPerPage={10}
        headings={headings}
        hovered
        mainActions={mainActions}
      />

      <ReusableModal
        title="Add New Admin"
        isOpen={isAddModalOpen}
        onClose={() => setIsAddModalOpen(false)}
        width="md"
      >
        <form onSubmit={handleSubmit(handleAddAdmin)}>
          <div className="mb-4">
            <label className="block text-black">Name</label>
            <input
              type="text"
              placeholder="Enter Name..."
              {...register("name", { required: "Name is required" })}
              className="admin-input input-primary"
            />
            {errors.name && (
              <p className="text-sm text-red-500">{errors.name.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-black">Email</label>
            <input
              placeholder="Enter Email..."
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
                  message: "Invalid email address",
                },
              })}
              className="admin-input input-primary"
            />
            {errors.email && (
              <p className="text-sm text-red-500">{errors.email.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-black">Password</label>
            <input
              type="password"
              placeholder="Enter Password..."
              {...register("password", {
                required: "Password is required",
                minLength: {
                  value: 6,
                  message: "Password must be at least 6 characters long",
                },
              })}
              className="admin-input input-primary"
            />
            {errors.password && (
              <p className="text-sm text-red-500">{errors.password.message}</p>
            )}
          </div>
          <div className="mb-4">
            <label className="block text-black">Role</label>
            <Controller
              name="role"
              control={control}
              rules={{ required: "Role is required" }}
              render={({ field }) => (
                <Select
                  {...field}
                  options={roleOptions}
                  className="w-full mt-1 text-sm text-left text-black rounded-lg focus:outline-none"
                  classNamePrefix="react-select"
                  placeholder="Select Role"
                />
              )}
            />
            {errors.role && (
              <p className="text-sm text-red-500">{errors.role.message}</p>
            )}
          </div>
          <div className="flex justify-end mt-4">
            <CButton variant={ButtonType.Primary}>Add Admin</CButton>
          </div>
        </form>
      </ReusableModal>
    </div>
  );
};

export default Admin;
