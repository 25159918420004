import React, { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import RoopTable from "../../common/customTableR/table";
import useGlobalStore from "../../../store/global";
import { extractErrorMessage, padTwoDigits } from "../../../utils/utils";
import ReusableModal from "../../common/modal/modal";
import CButton from "../../common/button/button";
import { ButtonType } from "../../common/button/buttonType";
import { API_URL } from "../../../apiConfig";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import CustomSwitch from "../../common/customSwitch/customSwitch";
import { FaRegEdit } from "react-icons/fa";

const editorConfiguration = {
  toolbar: {
    items: [
      "heading",
      "bold",
      "italic",
      "underline",
      "strikethrough",
      "blockQuote",
      "link",
      "imageUpload",
      "alignment",
      "|",
      "bulletedList",
      "numberedList",
      "|",
      "fontFamily",
      "fontSize",
      "fontColor",
      "fontBackgroundColor",
      "insertTable",
      "undo",
      "redo",
    ],
  },
  fontFamily: {
    options: [
      'default',
      'Arial, Helvetica, sans-serif',
      'Courier New, Courier, monospace',
      'Georgia, serif',
      'Lucida Sans Unicode, Lucida Grande, sans-serif',
      'Tahoma, Geneva, sans-serif',
      'Times New Roman, Times, serif',
      'Trebuchet MS, Helvetica, sans-serif',
      'Verdana, Geneva, sans-serif'
    ],
    supportAllValues: true
  },
  fontSize: {
    options: [
      'tiny',
      'small',
      'default',
      'big',
      'huge',
      '12px', // Add specific sizes like 12px, 16px
      '14px',
      '18px',
      '24px',
      '36px',
      '48px',
    ],
    supportAllValues: true,  // Allows custom font sizes
  },
  fontColor: {
    columns: 5,
    documentColors: 10,
  },
  fontBackgroundColor: {
    columns: 5,
    documentColors: 10,
  },
  image: {
    toolbar: ['imageTextAlternative', 'imageStyle:full', 'imageStyle:side']
  },
  table: {
    contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells']
  },
};



const TermsAndConditions = () => {
  const [tcs, setTCS] = useState([]);
  const [loading, setLoading] = useState(true);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isUpdateModalOpen, setIsUpdateModalOpen] = useState(false);
  const { setToastData } = useGlobalStore();
  const [counter, setCounter] = useState(0);
  const [content, setContent] = useState("");
  const [updateTcContent, setUpdateTcContent] = useState("");
  const {
    formState: { errors },
    control,
  } = useForm();
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [btnLoading, setBtnLoading] = useState(false);
  const [tcId, setTCId] = useState("");

  const formatDate = useCallback((dateString) => {
    if (dateString) {
      const date = new Date(dateString);
      const formattedDate = `${padTwoDigits(
        date.getMonth() + 1
      )}/${padTwoDigits(date.getDate())}/${date.getFullYear()} ${padTwoDigits(
        date.getHours()
      )}:${padTwoDigits(date.getMinutes())}`;
      return formattedDate;
    }
  }, []);

  useEffect(() => {
    const fetch = async () => {
      setLoading(true);
      try {
        const response = await axios.get(`${API_URL}/api/v1/tc/tcs`, {
          withCredentials: true,
        });
        if (response && response.data.success) {
          const formattedTCS = response?.data?.data.map((ele) => ({
            ...ele,
            createdAt: formatDate(ele?.createdAt),
            updatedAt: formatDate(ele?.updatedAt),
          }));
          let count = 1;
          setTCS(
            formattedTCS.map((el) => ({
              id: el?._id,
              count: count++,
              name: el?.name,
              content: el?.content,
              createdAt: el?.createdAt,
              updatedAt: el?.updatedAt,
              createdBy: el?.createdBy,
              updatedBy: el?.updatedBy,
              status: el?.status,
            }))
          );
        }
      } catch (error) {
        const errorMessage = extractErrorMessage(error);
        setToastData({
          type: "error",
          message: errorMessage,
        });
      } finally {
        setLoading(false);
      }
    };
    fetch();
  }, [counter]);

  const handleCreateTC = async () => {
    try {
      if (!content) {
        setToastData({ message: "Content is required.", type: "error" });
        return;
      }

      const response = await axios.post(
        `${API_URL}/api/v1/tc/create-tc`,
        {
          content: content,
        },
        { withCredentials: true }
      );

      if (response?.data?.success) {
        setToastData({ message: "TC created successfully.", type: "success" });
        setIsAddModalOpen(false);
        setCounter(counter + 1);
      } else {
        setToastData({ message: response?.data?.message, type: "error" });
        return;
      }
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({ type: "error", message: errorMessage });
    }
  };

  const handleUpdatTc = async () => {
    try {
      if (!updateTcContent) {
        setToastData({ message: "Content is required.", type: "error" });
        return;
      }

      if (!tcId) {
        setToastData({ message: "Content is required.", type: "error" });
        return;
      }


      const response = await axios.put(
        `${API_URL}/api/v1/tc/update-tc`,
        {
          content: updateTcContent,
          tcId:tcId
        },
        { withCredentials: true }
      );

      if (response?.data?.success) {
        setToastData({ message: "TC updated successfully.", type: "success" });
        setIsUpdateModalOpen(false);
        setTCId("")
        setCounter(counter + 1);
      } else {
        setToastData({ message: response?.data?.message, type: "error" });
        return;
      }
    } catch (error) {
      console.log(error);
      const errorMessage = extractErrorMessage(error);
      setToastData({ type: "error", message: errorMessage });
    }
  };

  const handleConfirmation = async () => {
    setShowConfirmationModal(false);
    try {
      if (!tcId) {
        setToastData({ message: "TC ID is required.", type: "error" });
        return;
      }
      setBtnLoading(true);
      const response = await axios.put(
        `${API_URL}/api/v1/tc/change-status`,
        { tcId: tcId },
        { withCredentials: true }
      );
      if (!response?.data?.success) {
        setToastData({
          type: "error",
          message: response?.data?.message,
        });
        return;
      }
      setCounter((prev) => prev + 1);
      setToastData({
        type: "success",
        message: "Status changed successfully",
      });
    } catch (error) {
      const errorMessage = extractErrorMessage(error);
      setToastData({
        type: "error",
        message: errorMessage,
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleCloseConfirmationModal = () => {
    setShowConfirmationModal(false);
    setTCId("");
  };

  const handleCloseModal = () => {
    setIsAddModalOpen(false);
    setContent("");
    setUpdateTcContent("")
    setIsUpdateModalOpen(false)
    setTCId("")
  };

  const handleToggleSwitch = (rowData) => {
    setShowConfirmationModal(true);
    setTCId(rowData?.id);
  };

  const renderSwitch = (rowData) => (
    <div>
      <CustomSwitch
        checked={rowData.status === "active" ? true : false}
        onChange={() => handleToggleSwitch(rowData)}
        label={`Toggle switch for ${rowData?.id}`}
      />
    </div>
  );

  const renderJobDescription = () => {
    return (
      <CKEditor
        editor={ClassicEditor}
        config={editorConfiguration}
        data={content}
        onChange={(event, editor) => setContent(editor.getData())}
      />
    );
  };

  const mainActions = [
    {
      label: "Add TC",
      onClick: () => setIsAddModalOpen(true),
    },
  ];

  const editUserInfo = (rowData) => {
    const tc = tcs?.filter((el) => el?.id === rowData?.id);
    setUpdateTcContent(tc[0]?.content)
    setIsUpdateModalOpen(true)
    setTCId(rowData?.id)
  }
  
  
  const renderActions = (rowData) => (
    <div className="flex items-center justify-center space-x-3">
      <div>
        <FaRegEdit
          className="text-red-500 cursor-pointer"
          onClick={() => {
            // editResume(rowData);
            editUserInfo(rowData)
          }}
        />
      </div>
    </div>
  );

  const headings = [
    { title: "Count", dataKey: "count" },
    { title: "Name", dataKey: "name" },
    { title: "Content", dataKey: "content" },
    { title: "Toggle Status", dataKey: "status", render: renderSwitch },
    { title: "Created By", dataKey: "createdBy" },
    { title: "Updated By", dataKey: "updatedBy" },
    { title: "Created At", dataKey: "createdAt" },
    { title: "Updated At", dataKey: "updatedAt" },
    { title: "Actions", dataKey: "id", render: renderActions },
  ];

  return (
    <div className="w-full px-2 mx-auto">
      <RoopTable
        loading={loading}
        data={tcs}
        itemsPerPage={10}
        headings={headings}
        hovered
        mainActions={mainActions}
      />
      <ReusableModal
        title="Add New TC"
        isOpen={isAddModalOpen}
        onClose={handleCloseModal}
        width="ml"
      >
        <div className="h-[300px] overflow-y-auto">
          {" "}
          {/* Add wrapper with height and overflow */}
          <CKEditor
            editor={ClassicEditor}
            config={editorConfiguration}
            data={content}
            onChange={(event, editor) => {
          const data = editor.getData();
          setContent(data); // Set content in HTML format
        }}          
          
        />
        </div>
        <div className="flex justify-end mt-4">
          <CButton onClick={handleCreateTC} variant={ButtonType.Primary}>
            Create TC
          </CButton>
        </div>
      </ReusableModal>

      <ReusableModal
        title="Update  TC"
        isOpen={isUpdateModalOpen}
        onClose={handleCloseModal}
        width="ml"
      >
        <div className="h-[300px] overflow-y-auto">
          {" "}
          <CKEditor
            editor={ClassicEditor}
            config={editorConfiguration}
            data={updateTcContent}
            onChange={(event, editor) => {
          const data = editor.getData();
          setUpdateTcContent(data); 
        }}          
          
        />
        </div>
        <div className="flex justify-end mt-4">
          <CButton onClick={handleUpdatTc} variant={ButtonType.Primary}>
            Update TC
          </CButton>
        </div>
      </ReusableModal>

      <ReusableModal
        isOpen={showConfirmationModal}
        onClose={handleCloseConfirmationModal}
        title="Are you sure ?"
        comments="By clicking yes the status of selected plan will change."
      >
        <div className="flex justify-end space-x-4">
          <CButton
            loading={btnLoading}
            variant={ButtonType.Primary}
            onClick={handleConfirmation}
          >
            Yes
          </CButton>
        </div>
      </ReusableModal>
    </div>
  );
};

export default TermsAndConditions;
